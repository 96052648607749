// react
import React, { Fragment, useState } from "react";
// store
import { planningService } from "../../../../store/services/ServicePlanning";
import { LOADING_TIME } from "../../../../store/config";
import { isEmpty } from "../../../../store/services/helpers/validation";
// components
import { Button, Form, Grid, Header, Icon, Modal } from "semantic-ui-react";
// others
import moment from "moment";

moment.locale("sk");

// component
function PlanningDeleteEventForm({ deleteEventInfo, newMessage }) {
    // state
    const [isProcessing, setIsProcessing] = useState(false);
    const [deleteReason, setDeleteReason] = useState("");

    /** component handlers **/
    const handleOnChangeDeleteReason = (e, data) => {
        setDeleteReason(data.value);
    };

    // submit form
    const handleSubmit = async (event) => {
        event.preventDefault();

        // dataset
        const data = {
            reason: deleteReason,
        };

        setIsProcessing(true);

        if (await planningService.cancelEvent(data, deleteEventInfo.id)) {
            setTimeout(function () {
                setIsProcessing(false);
                newMessage("Udalosť bola úspešne zrušená!");
            }, LOADING_TIME);
        } else {
            setIsProcessing(false);
            newMessage("Zrušenie udalosti prebehlo neúspešne!");
        }
    };

    // template
    return (
        <Fragment>
            <Modal.Content>
                <Grid centered>
                    <Grid.Column mobile={16} tablet={10} computer={10}>
                        <Form id="cancel-event-form" onSubmit={handleSubmit}>
                            <Form.TextArea
                                required
                                label="Dôvod zrušenia"
                                placeholder="Dôvod zrušenia udalosti ..."
                                value={deleteReason}
                                onChange={handleOnChangeDeleteReason}
                            />
                        </Form>
                    </Grid.Column>

                    <Grid.Column mobile={16} tablet={6} computer={6}>
                        <Header as="h4">O AKTIVITE</Header>

                        <Header as="h5">
                            <Icon name="futbol outline" />
                            Názov aktivity
                        </Header>
                        <Header.Subheader>
                            {deleteEventInfo.activity.name}
                        </Header.Subheader>
                        <Header as="h5">
                            <Icon name="clock" />
                            Čas aktivity
                        </Header>
                        <Header.Subheader>
                            {`${moment(deleteEventInfo.starts).format(
                                "LT"
                            )} - ${moment(deleteEventInfo.starts)
                                .add(
                                    deleteEventInfo.activity.duration,
                                    "minutes"
                                )
                                .format("LT")}`}
                        </Header.Subheader>
                        <Header as="h5">
                            <Icon name="map marker alternate" />
                            Miesto konania aktivity
                        </Header>
                        <Header.Subheader>
                            {deleteEventInfo.address.name}
                        </Header.Subheader>
                    </Grid.Column>
                </Grid>
            </Modal.Content>

            <Modal.Actions>
                <Button
                    color="pink"
                    content="Zrušiť udalosť"
                    loading={isProcessing}
                    disabled={isProcessing || isEmpty(deleteReason)}
                    type="submit"
                    form="cancel-event-form"
                />
            </Modal.Actions>
        </Fragment>
    );
}

export default PlanningDeleteEventForm;
