import moment from "moment";
import "moment/min/locales.min";

moment.locale("sk");

const searchReducer = (
    config = {
        location: {
            latitude: 48.7321245,
            longitude: 21.2422126,
        },
        distance: 2600,
        date: moment().format("YYYY-MM-DD"),
        time: moment().format("HH:mm"),
        country: "SK",
        activities: [],
        locationSetManually: false,
    },
    action
) => {
    switch (action.type) {
        case "SET_SEARCH_CONFIG":
            return action.config;
        default:
            return config;
    }
};

export default searchReducer;
