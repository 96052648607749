// react
import React, { Fragment, useEffect, useState } from "react";
// store
import { companyService } from "../../store/services/ServiceCompany";
import { isEmptyErrorList } from "../../store/services/helpers/validation";
import { LOADING_TIME } from "../../store/config";
// components
import ButtonSubmit from "../../components/buttons/ButtonSubmit";
import TutorialButtonBack from "./TutorialButtonBack";
import Loading from "../../components/others/Loading";
import { Form, Grid } from "semantic-ui-react";

// component
function TutorialBankForm({ nextTutorial, backTutorial }) {
    // state
    const [isLoading, setIsLoading] = useState(false);
    const [isProcessing, setIsProcessing] = useState(false);
    const [isEditForm, setIsEditForm] = useState(false);
    const [accountId, setAccountId] = useState(1);

    // form attributes
    const [name, setName] = useState("");
    const [iban, setIban] = useState("");
    const [swift, setSwift] = useState("");
    const [bankDetail, setBankDetail] = useState("");

    // fetch data
    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);
            const data = await companyService.getCompanyInformation();

            // set state
            if (data[0].bank_account) {
                setName(data[0].bank_account.name);
                setIban(data[0].bank_account.iban);
                setSwift(data[0].bank_account.swift_code);
                setBankDetail(data[0].bank_account.bank_detail);
                setAccountId(data[0].bank_account.id);
                setIsEditForm(true);
            }

            setTimeout(function () {
                setIsLoading(false);
            }, LOADING_TIME);
        };

        fetchData();
    }, []);

    /** component functions **/

    const handleSubmit = async () => {
        setIsProcessing(true);
        const data = {
            name: name,
            iban: iban,
            swift_code: swift,
            bank_detail: bankDetail,
            default: true,
        };

        if (isEditForm) {
            // call api to update bank account
            const result = await companyService.updateBankAccount(
                data,
                accountId
            );
            if (result.status === 200) {
                setIsProcessing(false);
                nextTutorial();
            }
        } else {
            // call api to create bank account
            const result = await companyService.createBankAccount(data);
            if (result.status === 201) {
                setIsProcessing(false);
                nextTutorial();
            }
        }

        setIsProcessing(false);
    };

    // template
    return (
        <Fragment>
            {isLoading ? (
                <Loading />
            ) : (
                <Grid>
                    <Grid.Column mobile={16} tablet={16} computer={16}>
                        <Form onSubmit={handleSubmit}>
                            <Form.Field widths="equal">
                                <Form.Group>
                                    <Form.Input
                                        autoFocus
                                        required
                                        width={8}
                                        label="Názov účtu"
                                        placeholder="Názov účtu"
                                        value={name}
                                        onChange={(e) =>
                                            setName(e.target.value)
                                        }
                                    />
                                    <Form.Input
                                        required
                                        width={8}
                                        label="Swift"
                                        placeholder="Swift"
                                        value={swift}
                                        onChange={(e) =>
                                            setSwift(e.target.value)
                                        }
                                    />
                                </Form.Group>
                            </Form.Field>
                            <Form.Field widths="equal">
                                <Form.Input
                                    required
                                    width={16}
                                    label="IBAN účtu"
                                    placeholder="IBAN účtu"
                                    value={iban}
                                    onChange={(e) => setIban(e.target.value)}
                                />
                            </Form.Field>
                            <Form.Field widths="equal">
                                <Form.Group>
                                    <Form.Input
                                        width={16}
                                        required
                                        label="Názov banky"
                                        placeholder="Názov banky"
                                        value={bankDetail}
                                        onChange={(e) =>
                                            setBankDetail(e.target.value)
                                        }
                                    />
                                </Form.Group>
                            </Form.Field>

                            <Form.Group>
                                <TutorialButtonBack
                                    disabled={isProcessing}
                                    onClick={backTutorial}
                                />

                                <ButtonSubmit
                                    loading={isProcessing}
                                    disabled={
                                        isProcessing ||
                                        !isEmptyErrorList([
                                            name,
                                            iban,
                                            swift,
                                            bankDetail,
                                        ])
                                    }
                                    text="Pokračovať"
                                />
                            </Form.Group>
                        </Form>
                    </Grid.Column>
                </Grid>
            )}
        </Fragment>
    );
}

export default TutorialBankForm;
