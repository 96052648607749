// react
import React, { Fragment, useState } from "react";
// store
import { planningService } from "../../../../store/services/ServicePlanning";
import { LOADING_TIME } from "../../../../store/config";
import {
    isDateError,
    isTimeError,
    isEmpty,
} from "../../../../store/services/helpers/validation";
// components
import { Button, Form, Grid, Header, Icon, Modal } from "semantic-ui-react";
import { DateInput, TimeInput } from "semantic-ui-calendar-react";
// others
import moment from "moment";

moment.locale("sk");

// component
function PlanningEditEventForm({ editEventInfo, newMessage }) {
    // state
    const [isProcessing, setIsProcessing] = useState(false);
    const [eventStart] = useState(editEventInfo.starts);
    const [date, setDate] = useState(
        moment(editEventInfo.starts).format("YYYY-MM-DD")
    );
    const [timeStart, setTimeStart] = useState(moment(eventStart).format("LT"));
    const [timeEnd, setTimeEnd] = useState(
        moment(eventStart)
            .add(editEventInfo.activity.duration, "minutes")
            .format("LT")
    );
    const [note, setNote] = useState(editEventInfo.note);
    const [eventPlace, setEventPlace] = useState(editEventInfo.address.id);
    const placesOptions = [];
    const [isDiscounted, setIsDiscounted] = useState(editEventInfo.is_discounted);
    // eslint-disable-next-line
    const [isHighlighted, setIsHighlighted] = useState(editEventInfo.is_highlighted);

    // create options for select input from fetched data
    const setSelectOptions = (fetchedData, options) => {
        for (let i = 0; i < fetchedData.length; i++) {
            let option = {
                key: fetchedData[i].id,
                text: fetchedData[i].name,
                value: fetchedData[i].id,
            };
            options.push(option);
        }
    };

    // select input options - activity places
    setSelectOptions(editEventInfo.activity.addresses, placesOptions);

    /** component handlers **/

    const handleOnChangeDate = (e, data) => {
        setDate(data.value);
    };

    const handleOnChangeTimeStart = (e, data) => {
        setTimeStart(data.value);

        setTimeEnd(
            moment(data.value, "hh:mm")
                .locale("sk")
                .add(editEventInfo.activity.duration, "minutes")
                .format("LT")
        );
    };

    const handleOnChangeTimeEnd = (e) => {
        e.preventDefault();
    };

    const handleOnChangePlace = (e, data) => {
        setEventPlace(data.value);
    };

    const handleOnChangeNote = (e, data) => {
        setNote(data.value);
    };

    // submit form
    const handleSubmit = async (event) => {
        event.preventDefault();

        // dataset
        const data = {
            date: date,
            time_from: timeStart,
            address: eventPlace,
            note: note,
            is_discounted: isDiscounted,
            is_highlighted: isHighlighted,
        };

        setIsProcessing(true);

        let formError = false;

        // validate form fields
        if (isDateError(date) || isTimeError(moment(timeStart, "hh:mm").format("hh:mm"))) {
            formError = true;
        }

        if (!formError) {
            if (await planningService.editEvent(data, editEventInfo.id)) {
                setTimeout(function () {
                    setIsProcessing(false);
                    newMessage("Udalosť bola úspešne upravená!");
                }, LOADING_TIME);
            } else {
                setIsProcessing(false);
                newMessage("Upravenie udalosti prebehlo neúspešne!");
            }
        }

        setIsProcessing(false);
    };

    //template
    return (
        <Fragment>
            <Modal.Content>
                <Grid centered>
                    <Grid.Column mobile={16} tablet={10} computer={10}>
                        <Form id="edit-event-form" onSubmit={handleSubmit}>
                            <Form.Group>
                                <DateInput
                                    width={16}
                                    required
                                    localization="sk"
                                    label="Dátum konania aktivity"
                                    dateFormat="YYYY-MM-DD"
                                    value={date}
                                    onChange={handleOnChangeDate}
                                    minDate={moment()}
                                    iconPosition="right"
                                    animation="none"
                                    closable
                                    hideMobileKeyboard
                                    error={
                                        !isEmpty(date)
                                            ? isDateError(date)
                                            : false
                                    }
                                />
                            </Form.Group>
                            <Form.Group>
                                <TimeInput
                                    width={8}
                                    required
                                    label="Čas začiatku"
                                    timeFormat="24"
                                    value={moment(timeStart, "hh:mm").format("hh:mm")}
                                    onChange={handleOnChangeTimeStart}
                                    iconPosition="right"
                                    animation="none"
                                    closable
                                    hideMobileKeyboard
                                    error={
                                        !isEmpty(timeStart)
                                            ? isTimeError(moment(timeStart, "hh:mm").format("hh:mm"))
                                            : false
                                    }
                                />
                                <TimeInput
                                    width={8}
                                    label="Čas ukončenia"
                                    timeFormat="24"
                                    value={moment(timeEnd, "hh:mm").format("hh:mm")}
                                    onChange={handleOnChangeTimeEnd}
                                    disabled
                                    iconPosition="right"
                                    animation="none"
                                    closable
                                    hideMobileKeyboard
                                />
                            </Form.Group>

                            <Form.Group>
                                <Form.Select
                                    width={16}
                                    label="Miesto udalosti"
                                    placeholder="Miesto udalosti"
                                    required
                                    options={placesOptions}
                                    onChange={handleOnChangePlace}
                                    value={eventPlace}
                                />
                            </Form.Group>

                            <Form.TextArea
                                label="Poznámka"
                                placeholder="Poznámka k aktivite ..."
                                value={note}
                                onChange={handleOnChangeNote}
                            />

                            <Form.Checkbox
                                label="Aplikovať zľavu na termín"
                                checked={isDiscounted}
                                onChange={() => setIsDiscounted(!isDiscounted)}
                            />
                        </Form>
                    </Grid.Column>

                    <Grid.Column mobile={16} tablet={6} computer={6}>
                        <Header as="h4">O AKTIVITE</Header>

                        <Header as="h5">
                            <Icon name="futbol outline" />
                            Názov aktivity
                        </Header>
                        <Header.Subheader>
                            {editEventInfo.activity.name}
                        </Header.Subheader>
                        <Header as="h5">
                            <Icon name="clock" />
                            Dĺžka aktivity
                        </Header>
                        <Header.Subheader>
                            {`${editEventInfo.activity.duration} minút`}
                        </Header.Subheader>
                    </Grid.Column>
                </Grid>
            </Modal.Content>

            <Modal.Actions>
                <Button
                    color="pink"
                    content="Uložiť"
                    loading={isProcessing}
                    disabled={
                        isProcessing || isEmpty(date) || isEmpty(timeStart)
                    }
                    type="submit"
                    form="edit-event-form"
                />
            </Modal.Actions>
        </Fragment>
    );
}

export default PlanningEditEventForm;
