import request from "umi-request";
import { API } from "../config";
import { APIRequest } from "./helpers/requests";

/*
 * Service functions
 */
export const searchService = {
    search,
    getActivityDetail,
    getActivity,
    getCompany,
    getAddress,
    getEvent,
    getEventByID,
    getBasketItems,
};

async function search(data) {
    let result;
    // create Async - Await request
    result = await request(API.SEARCH, {
        method: "post",
        data: data,
    })
        .then(function (response) {
            return response;
        })
        .catch(function (error) {
            return [];
        });

    return result;
}

async function getActivityDetail(id, params) {
    params = params || ""
    return await APIRequest({
        url: API.SEARCH + id + params,
        method: "GET",
        data: {},
        private: false,
    });
}

async function getActivity(id) {
    let data;
    // create Async - Await request
    data = await request(API.GET_SEARCH_ACTIVITY + id, {
        method: "get",
    })
        .then(function (response) {
            return response;
        })
        .catch(function (error) {
            return [];
        });

    return data;
}

async function getEvent(id) {
    let data;
    // create Async - Await request
    data = await request(API.GET_EVENT + id, {
        method: "get",
    })
        .then(function (response) {
            return response;
        })
        .catch(function (error) {
            return [];
        });

    return data;
}

async function getEventByID(id) {
    return await APIRequest({
        url: API.GET_EVENT + id,
        method: "GET",
        data: {},
        private: false,
    });
}

async function getCompany(id) {
    let data;
    // create Async - Await request
    data = await request(API.GET_SEARCH_COMPANY + id, {
        method: "get",
    })
        .then(function (response) {
            return response;
        })
        .catch(function (error) {
            return [];
        });

    return data;
}

async function getAddress(id) {
    let data;
    // create Async - Await request
    data = await request(API.GET_SEARCH_ADDRESS + id, {
        method: "get",
    })
        .then(function (response) {
            return response;
        })
        .catch(function (error) {
            return [];
        });

    return data;
}

async function getBasketItems(items) {
    return await APIRequest({
        url: API.BASKET,
        method: "POST",
        data: items,
        private: false,
    });
}
