// react
import React from "react";
// components
import { Grid } from "semantic-ui-react";

// component
function ProviderDetailInfo({ provider }) {
    // template
    return (
        <Grid.Row columns={2} divided>
            <Grid.Column>
                <h4>Meno poskytovateľa</h4>
                <p>
                    {provider.firstname} {provider.lastname}
                </p>
                <h4>Prezývka</h4>
                <p>{provider.nickname ? provider.nickname : "-"}</p>
                <h4>Email</h4>
                <p>{provider.user ? provider.user.email : "-"}</p>
                <h4>Tel. kontakt</h4>
                <p>{provider.phone_number ? provider.phone_number : "-"}</p>
            </Grid.Column>
            <Grid.Column>
                <h4>Typ poskytovateľa</h4>
                {provider.providerType === 1 ? (
                    <p>Súkromná osoba</p>
                ) : (
                    <p>Podnikateľ</p>
                )}

                {provider.providerType === 2 ? (
                    <>
                        <h4>Firma</h4>
                        <p>{provider.name ? provider.name : "-"}</p>

                        <h4>IČO: {provider.ico ? provider.ico : "-"}</h4>
                        <h4>DIČ: {provider.dic ? provider.dic : "-"}</h4>
                        <h4>
                            IC DPH: {provider.ic_dph ? provider.ic_dph : "-"}
                        </h4>
                    </>
                ) : (
                    ""
                )}
            </Grid.Column>
        </Grid.Row>
    );
}

export default ProviderDetailInfo;
