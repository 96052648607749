// react
import React from "react";
import { Link } from "react-router-dom";
// components
import { Form } from "semantic-ui-react";

// component
function RegisterCheckbox({ checkboxId, onChange, isCheckboxError }) {
    // template
    return (
        <Form.Checkbox
            id={checkboxId}
            required
            label={{
                children: (
                    <p>
                        {`Súhlasím s `}
                        <Link
                            to="/general-conditions"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            podmienkami používania
                        </Link>
                        {` a `}
                        <Link
                            to="/privacy"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            spracovaním osobných údajov
                        </Link>
                        {`, a bol som oboznámený so spracovaním osobných údajov`}
                    </p>
                ),
            }}
            onChange={onChange}
            error={
                isCheckboxError
                    ? {
                          content: "Toto pole je povinné",
                          pointing: "left",
                      }
                    : false
            }
        />
    );
}

export default RegisterCheckbox;
