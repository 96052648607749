import request from "umi-request";
import { API } from "../config";

// services
import { authService } from "./ServiceAuth";
import { APIRequest } from "./helpers/requests";

/*
 * Service functions
 */
export const registrationService = {
    getRegistrationRequests,
    approveRequest,
    denyRequest,
};

/* Registration Requests */
async function getRegistrationRequests(params) {
    return await APIRequest({
        url: API.REGISTRATION_REQUEST_GET + params,
        method: "GET",
        data: {},
        private: true,
    });
}

async function approveRequest(id) {
    let approved;

    let accessToken = await authService.getAccessToken();
    approved = await request(API.REGISTRATION_REQUEST_APPROVE, {
        method: "post",
        data: { id: id },
        headers: {
            Authorization: `Bearer ${accessToken}`,
        },
    })
        .then(function (response) {
            return true;
        })
        .catch(function (error) {
            return false;
        });

    return approved;
}

async function denyRequest(id) {
    let denied;

    let accessToken = await authService.getAccessToken();
    denied = await request(API.REGISTRATION_REQUEST_DENY, {
        method: "post",
        data: { id: id },
        headers: {
            Authorization: `Bearer ${accessToken}`,
        },
    })
        .then(function (response) {
            return true;
        })
        .catch(function (error) {
            return false;
        });

    return denied;
}
