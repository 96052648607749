// react
import React from "react";
// components
import { Header, Icon, Container, Segment } from "semantic-ui-react";

// component
function RegisterProviderActivation({ email }) {
    // template
    return (
        <Segment className="center-segment" textAlign="center">
            <Container text>
                <Header
                    as="h1"
                    style={{
                        marginTop: "0.5em",
                    }}
                    content="Žiadosť o registráciu odoslaná"
                />
                <Icon name="mail" size="huge" color="pink" circular />
                <Header
                    as="h2"
                    content="Žiadosť o registráciu poskytovateľa bola úspešne odoslaná."
                />
                <p>
                    Po posúdení žiadosti administrátorom zašleme správu na
                    adresu <i>{email}</i> s informáciou o ďalšom postupe k
                    aktivácii účtu.
                </p>
            </Container>
        </Segment>
    );
}

export default RegisterProviderActivation;
