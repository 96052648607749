// react
import React, { Fragment, useState } from "react";
// store
import { routes } from "../../store/routes";
// components
import PaymentModal from "./PaymentModal";
import BasketItem from "./BasketItem";
import { Grid, Button, Divider } from "semantic-ui-react";

// component
const BasketList = ({
    basket,
    basketItems,
    items,
    setItems,
    countTotal,
    removeProductFromBasket,
    setBasketEvents,
}) => {
    // state
    const [isPaymentModalOpen, setIsPaymentModalOpen] = useState(false);

    // template
    return (
        <Fragment>
            <Grid>
                {basketItems.map((item) => (
                    <BasketItem
                        key={item.id}
                        item={item}
                        items={items}
                        setItems={setItems}
                        removeProductFromBasket={removeProductFromBasket}
                    />
                ))}
            </Grid>

            <h4 style={{ width: "100%" }}>Počet položiek: {basketItems.length}</h4>

            <Divider />

            <Button
                className="button-pay-fluid"
                floated="left"
                style={{ marginBottom: "2rem" }}
                as="a"
                href={routes.SEARCH}
                size="large"
                color="green"
            >
                Pokračovať vo vyhľadávaní
            </Button>

            <PaymentModal
                open={isPaymentModalOpen}
                onClose={() => setIsPaymentModalOpen(false)}
                onOpen={() => setIsPaymentModalOpen(true)}
                items={items}
                setItems={setItems}
                trigger={
                    <Button
                        className="button-pay-order button-pay-fluid"
                        color="pink"
                        size="large"
                        style={{ marginBottom: "0.5rem" }}
                    >
                        Zaplatiť objednávku ({countTotal()} €)
                    </Button>
                }
                basket_items={basket}
                removeItem={removeProductFromBasket}
                setBasketEvents={setBasketEvents}
            />
        </Fragment>
    );
};

export default BasketList;
