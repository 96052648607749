// react
import React from "react";
import {
    Container,
    Grid,
    Header,
    Icon,
    Table,
    Divider,
} from "semantic-ui-react";

// component
function PrivacyText() {
    // template
    return (
        <Grid className="section">
            <Grid.Row columns={1} centered>
                <Header as="h2" style={{ color: "var(--primary)" }}>
                    ZÁSADY OCHRANY OSOBNÝCH ÚDAJOV
                </Header>
                <Icon name="angle down" size="big" color="pink" />
            </Grid.Row>

            <Grid.Row columns={1} centered width={10}>
                <Container text style={{ textAlign: "justify" }}>
                    <Header as="h3" style={{ textAlign: "center" }}>
                        ÚČTOVNÉ A DAŇOVÉ DOKLADY
                    </Header>
                    <Header as="h4" style={{ textAlign: "center" }}>
                        Účel spracúvania osobných údajov je evidencia účtovných
                        dokladov a agendy spojenej s jej spracovaním.
                    </Header>
                    <p>
                        <b>Okruh dotknutých osôb:</b> fyzické osoby – klienti,
                        zamestnanci
                    </p>
                    <p>
                        <b>Právny základ spracovania osobných údajov:</b> zákon
                        č. 431/2002 Z. z. o účtovníctve v znení neskorších
                        predpisov, zákon č. 222/2004 Z. z. o dani z pridanej
                        hodnoty v znení neskorších predpisov, zákon č. 40/1964
                        Zb. Občiansky zákonník v znení neskorších predpisov,
                        zákon č. 152/1994 Z. z. o sociálnom fonde a o zmene a
                        doplnení zákona č. 286/1992 Zb. o daniach z príjmov v
                        znení neskorších predpisov, zákon č. 311/2001 Z. z.
                        Zákonník práce v znení neskorších predpisov
                    </p>
                    <p>
                        <b>Kategórie príjemcov:</b> sociálna poisťovňa,
                        zdravotné poisťovne, daňový úrad a subjekty, ktorým
                        osobitný predpis zveruje právomoc rozhodovať o právach a
                        povinnostiach fyzických osôb: súdy, orgány činné v
                        trestnom konaní a sprostredkovatelia: Monika Gáborová
                        ELI-GM, 06601 Humenné, Košická 24, 43647260
                    </p>
                    <p>
                        <b>
                            Prenos osobných údajov do tretích krajín sa
                            nerealizuje.
                        </b>
                    </p>
                    <p>
                        <b>Lehoty na vymazanie osobných údajov:</b>
                    </p>
                    <Table celled>
                        <Table.Body>
                            <Table.Row>
                                <Table.Cell width={6}>
                                    Účtovné doklady
                                </Table.Cell>
                                <Table.Cell width={6}>10 rokov</Table.Cell>
                            </Table.Row>
                        </Table.Body>
                    </Table>
                    <p>
                        <b>
                            Automatizované rozhodovanie vrátane profilovania sa
                            neuskutočňuje.
                        </b>
                    </p>
                    <p>
                        Z dôvodu dodržiavania zásady minimalizácie sú všetky
                        Vami poskytnuté osobné údaje nevyhnutnou zákonnou
                        požiadavkou pre naplnenie účelu ich spracúvania.
                    </p>

                    <Header as="h3" style={{ textAlign: "center" }}>
                        POŠTA
                    </Header>
                    <Header as="h4" style={{ textAlign: "center" }}>
                        Účel spracúvania osobných údajov je evidencia došlej a
                        odoslanej pošty.
                    </Header>

                    <p>
                        <b>Okruh dotknutých osôb:</b> fyzické osoby – adresáti.
                    </p>
                    <p>
                        <b>Zoznam osobných údajov:</b> meno, priezvisko, titul,
                        adresa, názov organizácie, pracovné zaradenie, e-mailová
                        adresa, predmet a obsah pošty
                    </p>
                    <p>
                        <b>Právny základ spracovania osobných údajov:</b> zákon
                        č. 395/2002 Z.z. o archívoch a registratúrach a o
                        doplnení niektorých zákonov v znení neskorších predpisov
                    </p>
                    <p>
                        <b>Kategórie príjemcov:</b> subjekty, ktorým osobitný
                        predpis zveruje právomoc rozhodovať o právach a
                        povinnostiach fyzických osôb: súdy, orgány činné v
                        trestnom konaní a sprostredkovatelia: WebSupport s. r.
                        o., Karadžičova 7608/12 Bratislava - mestská časť
                        Ružinov 821 08, 36421928
                    </p>
                    <p>
                        <b>
                            Prenos osobných údajov do tretích krajín sa
                            nerealizuje.
                        </b>
                    </p>
                    <p>
                        <b>Lehoty na vymazanie osobných údajov:</b>
                    </p>
                    <Table celled>
                        <Table.Body>
                            <Table.Row>
                                <Table.Cell width={6}>
                                    Bežná korešpondencia
                                </Table.Cell>
                                <Table.Cell width={6}>3 roky</Table.Cell>
                            </Table.Row>
                        </Table.Body>
                    </Table>
                    <p>
                        <b>
                            Automatizované rozhodovanie vrátane profilovania sa
                            neuskutočňuje.
                        </b>
                    </p>
                    <p>
                        Z dôvodu dodržiavania zásady minimalizácie sú všetky
                        Vami poskytnuté osobné údaje nevyhnutnou zákonnou
                        požiadavkou pre naplnenie účelu ich spracúvania.
                    </p>

                    <Header as="h3" style={{ textAlign: "center" }}>
                        MARKETING
                    </Header>
                    <Header as="h4" style={{ textAlign: "center" }}>
                        Účel spracúvania osobných údajov je zasielanie
                        marketingových ponúk, newslettrov a informácií o
                        produktoch alebo novinkách.
                    </Header>

                    <p>
                        <b>Okruh dotknutých osôb:</b> fyzické osoby
                    </p>
                    <p>
                        <b>Zoznam osobných údajov:</b> titul, meno a priezvisko,
                        bydlisko, telefónne číslo, e-mail
                    </p>
                    <p>
                        <b>Právny základ spracovania osobných údajov:</b> § 13
                        ods. 1 písm. a) zákona č. 18/2018 Z. z. o ochrane
                        osobných údajov a o zmene a doplnení niektorých zákonov
                    </p>
                    <p>
                        <b>Kategórie príjemcov:</b> subjekty, ktorým osobitný
                        predpis zveruje právomoc rozhodovať o právach a
                        povinnostiach fyzických osôb: súdy, orgány činné v
                        trestnom konaní a sprostredkovatelia: EPOX PLUS, s.r.o.,
                        Blatné Remety 43 072 44 Blatné Remety, 36202151
                    </p>
                    <p>
                        <b>
                            Prenos osobných údajov do tretích krajín sa
                            nerealizuje.
                        </b>
                    </p>
                    <p>
                        <b>Lehoty na vymazanie osobných údajov:</b>-
                    </p>
                    <p>
                        <b>
                            Automatizované rozhodovanie vrátane profilovania sa
                            neuskutočňuje.
                        </b>
                    </p>
                    <p>
                        Dotknutá osoba má právo kedykoľvek odvolať súhlas so
                        spracovaním osobných údajov, ktoré sa jej týkajú.
                        Odvolanie súhlasu nemá vplyv na zákonnosť spracúvania
                        osobných údajov založeného na súhlase pred jeho
                        odvolaním; pred poskytnutím súhlasu musí byť dotknutá
                        osoba o tejto skutočnosti informovaná. Dotknutá osoba
                        môže súhlas odvolať rovnakým spôsobom, akým súhlas
                        udelila.
                    </p>

                    <Header as="h3" style={{ textAlign: "center" }}>
                        FOTOGRAFIE
                    </Header>
                    <Header as="h4" style={{ textAlign: "center" }}>
                        Účel spracúvania osobných údajov je zverejňovanie
                        fotografií v aplikácii ActivityPointu.
                    </Header>

                    <p>
                        <b>Okruh dotknutých osôb:</b> klienti Activitypointu
                    </p>
                    <p>
                        <b>Zoznam osobných údajov:</b> titul, meno a priezvisko,
                        fotografia
                    </p>
                    <p>
                        <b>Právny základ spracovania osobných údajov:</b> súhlas
                        dotknutej osoby
                    </p>
                    <p>
                        <b>Kategórie príjemcov:</b> subjekty, ktorým osobitný
                        predpis zveruje právomoc rozhodovať o právach a
                        povinnostiach fyzických osôb: súdy, orgány činné v
                        trestnom konaní a sprostredkovatelia: EPOX PLUS, s.r.o.,
                        Blatné Remety 43 072 44 Blatné Remety, 36202151
                    </p>
                    <p>
                        <b>
                            Prenos osobných údajov do tretích krajín sa
                            nerealizuje.
                        </b>
                    </p>
                    <p>
                        <b>Lehoty na vymazanie osobných údajov:</b>
                    </p>
                    <Table celled>
                        <Table.Body>
                            <Table.Row>
                                <Table.Cell width={6}>fotografie</Table.Cell>
                                <Table.Cell width={6}>
                                    po dobu trvania súhlasu
                                </Table.Cell>
                            </Table.Row>
                        </Table.Body>
                    </Table>
                    <p>
                        <b>
                            Automatizované rozhodovanie vrátane profilovania sa
                            neuskutočňuje.
                        </b>
                    </p>
                    <p>
                        Dotknutá osoba má právo kedykoľvek odvolať súhlas so
                        spracovaním osobných údajov, ktoré sa jej týkajú.
                        Odvolanie súhlasu nemá vplyv na zákonnosť spracúvania
                        osobných údajov založeného na súhlase pred jeho
                        odvolaním; pred poskytnutím súhlasu musí byť dotknutá
                        osoba o tejto skutočnosti informovaná. Dotknutá osoba
                        môže súhlas odvolať rovnakým spôsobom, akým súhlas
                        udelila.
                    </p>

                    <Header as="h3" style={{ textAlign: "center" }}>
                        KONTAKTNÝ FORMULÁR
                    </Header>
                    <Header as="h4" style={{ textAlign: "center" }}>
                        Účel spracúvania osobných údajov je evidencia dotknutých
                        osôb pri zasielaní odpovedí na položenú otázku.
                    </Header>

                    <p>
                        <b>Okruh dotknutých osôb:</b> fyzické osoby
                    </p>
                    <p>
                        <b>Zoznam osobných údajov:</b> titul, meno a priezvisko,
                        e-mail, predmet a obsah správy
                    </p>
                    <p>
                        <b>Právny základ spracovania osobných údajov:</b> §13
                        ods. 1 písm. a) zákona č. 18/2018 Z. z. o ochrane
                        osobných údajov v znení neskorších predpisov
                    </p>
                    <p>
                        <b>Kategórie príjemcov:</b> subjekty, ktorým osobitný
                        predpis zveruje právomoc rozhodovať o právach a
                        povinnostiach fyzických osôb: súdy, orgány činné v
                        trestnom konaní a sprostredkovatelia: WebSupport s. r.
                        o., Karadžičova 7608/12 Bratislava - mestská časť
                        Ružinov 821 08, 36421928
                    </p>
                    <p>
                        <b>
                            Prenos osobných údajov do tretích krajín sa
                            nerealizuje.
                        </b>
                    </p>
                    <p>
                        <b>Lehoty na vymazanie osobných údajov:</b>
                    </p>
                    <Table celled>
                        <Table.Body>
                            <Table.Row>
                                <Table.Cell width={6}>
                                    kontaktný formulár
                                </Table.Cell>
                                <Table.Cell width={6}>
                                    maximálne 6 mesiacov (v prípade plnenia si
                                    zákonných povinností či právnych nárokov
                                    prevádzkovateľa podľa platnej legislatívy).
                                </Table.Cell>
                            </Table.Row>
                        </Table.Body>
                    </Table>
                    <p>
                        <b>
                            Automatizované rozhodovanie vrátane profilovania sa
                            neuskutočňuje.
                        </b>
                    </p>
                    <p>
                        Dotknutá osoba má právo kedykoľvek odvolať súhlas so
                        spracovaním osobných údajov, ktoré sa jej týkajú.
                        Odvolanie súhlasu nemá vplyv na zákonnosť spracúvania
                        osobných údajov založeného na súhlase pred jeho
                        odvolaním; pred poskytnutím súhlasu musí byť dotknutá
                        osoba o tejto skutočnosti informovaná. Dotknutá osoba
                        môže súhlas odvolať rovnakým spôsobom, akým súhlas
                        udelila.
                    </p>

                    <Header as="h3" style={{ textAlign: "center" }}>
                        REZERVAČNÝ SYSTÉM
                    </Header>
                    <Header as="h4" style={{ textAlign: "center" }}>
                        Účel spracúvania osobných údajov je evidencia spojená s
                        rezerváciou poskytujúcich služieb.
                    </Header>

                    <p>
                        <b>Okruh dotknutých osôb:</b> fyzické osoby - zákazníci
                    </p>
                    <p>
                        <b>Zoznam osobných údajov:</b> titul, meno a priezvisko,
                        bydlisko, telefónne číslo, e-mail, dátum a čas
                        rezervácie
                    </p>
                    <p>
                        <b>Právny základ spracovania osobných údajov:</b> súhlas
                        dotknutej osoby
                    </p>
                    <p>
                        <b>Kategórie príjemcov:</b> subjekty, ktorým osobitný
                        predpis zveruje právomoc rozhodovať o právach a
                        povinnostiach fyzických osôb: súdy, orgány činné v
                        trestnom konaní a sprostredkovatelia: EPOX PLUS, s.r.o.,
                        Blatné Remety 43 072 44 Blatné Remety, 36202151
                    </p>
                    <p>
                        <b>
                            Prenos osobných údajov do tretích krajín sa
                            nerealizuje.
                        </b>
                    </p>
                    <p>
                        <b>Lehoty na vymazanie osobných údajov:</b>
                    </p>
                    <Table celled>
                        <Table.Body>
                            <Table.Row>
                                <Table.Cell width={6}>
                                    rezervačný systém
                                </Table.Cell>
                                <Table.Cell width={6}>
                                    po dobu trvania registrácie
                                </Table.Cell>
                            </Table.Row>
                        </Table.Body>
                    </Table>
                    <p>
                        <b>
                            Automatizované rozhodovanie vrátane profilovania sa
                            neuskutočňuje.
                        </b>
                    </p>
                    <p>
                        Dotknutá osoba má právo kedykoľvek odvolať súhlas so
                        spracovaním osobných údajov, ktoré sa jej týkajú.
                        Odvolanie súhlasu nemá vplyv na zákonnosť spracúvania
                        osobných údajov založeného na súhlase pred jeho
                        odvolaním; pred poskytnutím súhlasu musí byť dotknutá
                        osoba o tejto skutočnosti informovaná. Dotknutá osoba
                        môže súhlas odvolať rovnakým spôsobom, akým súhlas
                        udelila.
                    </p>

                    <Header as="h3" style={{ textAlign: "center" }}>
                        SPOTREBITEĽSKÁ SÚŤAŽ
                    </Header>
                    <Header as="h4" style={{ textAlign: "center" }}>
                        Účel spracúvania osobných údajov je organizovanie
                        spotrebiteľských súťaží.
                    </Header>

                    <p>
                        <b>Okruh dotknutých osôb:</b> fyzické osoby – zákazníci
                        / klienti
                    </p>
                    <p>
                        <b>Zoznam osobných údajov:</b> titul, meno a priezvisko,
                        bydlisko, telefónne číslo, e-mail, číslo OP, výhra
                    </p>
                    <p>
                        <b>Právny základ spracovania osobných údajov:</b> § 13
                        ods. 1 písm. a) zákona č. 18/2018 Z. z. o ochrane
                        osobných údajov a o zmene a doplnení niektorých zákonov
                    </p>
                    <p>
                        <b>Kategórie príjemcov:</b> subjekty, ktorým osobitný
                        predpis zveruje právomoc rozhodovať o právach a
                        povinnostiach fyzických osôb: súdy, orgány činné v
                        trestnom konaní a sprostredkovatelia: EPOX PLUS, s.r.o.,
                        Blatné Remety 43 072 44 Blatné Remety, 36202151
                    </p>
                    <p>
                        <b>
                            Prenos osobných údajov do tretích krajín sa
                            nerealizuje.
                        </b>
                    </p>
                    <p>
                        <b>Lehoty na vymazanie osobných údajov:</b>
                    </p>
                    <Table celled>
                        <Table.Body>
                            <Table.Row>
                                <Table.Cell width={6}>
                                    osobné údaje súťažiacich
                                </Table.Cell>
                                <Table.Cell width={6}>
                                    po dobu trvania účelu
                                </Table.Cell>
                            </Table.Row>
                        </Table.Body>
                    </Table>
                    <p>
                        <b>
                            Automatizované rozhodovanie vrátane profilovania sa
                            neuskutočňuje.
                        </b>
                    </p>
                    <p>
                        Dotknutá osoba má právo kedykoľvek odvolať súhlas so
                        spracovaním osobných údajov, ktoré sa jej týkajú.
                        Odvolanie súhlasu nemá vplyv na zákonnosť spracúvania
                        osobných údajov založeného na súhlase pred jeho
                        odvolaním; pred poskytnutím súhlasu musí byť dotknutá
                        osoba o tejto skutočnosti informovaná. Dotknutá osoba
                        môže súhlas odvolať rovnakým spôsobom, akým súhlas
                        udelila.
                    </p>

                    <Header as="h3" style={{ textAlign: "center" }}>
                        VERNOSTNÝ PROGRAM
                    </Header>
                    <Header as="h4" style={{ textAlign: "center" }}>
                        Účel spracúvania osobných údajov je poskytovanie zliav,
                        bonusov a vernostného programu.
                    </Header>

                    <p>
                        <b>Okruh dotknutých osôb:</b> fyzické osoby – zákazníci
                        / klienti
                    </p>
                    <p>
                        <b>Zoznam osobných údajov:</b> titul, meno a priezvisko,
                        bydlisko, telefónne číslo, e-mail
                    </p>
                    <p>
                        <b>Právny základ spracovania osobných údajov:</b> § 13
                        ods. 1 písm. a) zákona č. 18/2018 Z. z. o ochrane
                        osobných údajov a o zmene a doplnení niektorých zákonov
                    </p>
                    <p>
                        <b>Kategórie príjemcov:</b> subjekty, ktorým osobitný
                        predpis zveruje právomoc rozhodovať o právach a
                        povinnostiach fyzických osôb: súdy, orgány činné v
                        trestnom konaní a sprostredkovatelia: EPOX PLUS, s.r.o.,
                        Blatné Remety 43 072 44 Blatné Remety, 36202151
                    </p>
                    <p>
                        <b>
                            Prenos osobných údajov do tretích krajín sa
                            nerealizuje.
                        </b>
                    </p>
                    <p>
                        <b>Lehoty na vymazanie osobných údajov:</b> -
                    </p>
                    <p>
                        <b>
                            Automatizované rozhodovanie vrátane profilovania sa
                            neuskutočňuje.
                        </b>
                    </p>
                    <p>
                        Dotknutá osoba má právo kedykoľvek odvolať súhlas so
                        spracovaním osobných údajov, ktoré sa jej týkajú.
                        Odvolanie súhlasu nemá vplyv na zákonnosť spracúvania
                        osobných údajov založeného na súhlase pred jeho
                        odvolaním; pred poskytnutím súhlasu musí byť dotknutá
                        osoba o tejto skutočnosti informovaná. Dotknutá osoba
                        môže súhlas odvolať rovnakým spôsobom, akým súhlas
                        udelila.
                    </p>

                    <p>
                        <b>
                            Dotknuté osoby, o ktorých sú spracúvané osobné údaje
                            pre konkrétne vymedzené účely, si môžu uplatniť
                            nasledovné práva:
                        </b>
                    </p>
                    <p>
                        Právo požadovať prístup k svojim osobným údajom - Právo
                        na opravu osobných údajov - Právo na vymazanie osobných
                        údajov - Právo na obmedzenie spracúvania osobných údajov
                        - Právo namietať proti spracúvaniu osobných údajov -
                        Právo na prenos svojich osobných údajov - Právo podať
                        sťažnosť dozornému orgánu, t.j. Úradu na ochranu
                        osobných údajov SR.
                    </p>
                    <p>
                        Uvedené práva dotknutej osoby sú bližšie špecifikované v
                        článkoch 15 až 21 Nariadenia. Dotknutá osoba si uvedené
                        práva uplatňuje v súlade s Nariadením a ďalšími
                        príslušnými právnymi predpismi. Voči prevádzkovateľovi
                        si dotknutá osoba môže svoje práva uplatniť
                        prostredníctvom písomnej žiadosti alebo elektronickými
                        prostriedkami. V prípade, že dotknutá osoba požiada o
                        ústne poskytnutie informácií, informácie sa môžu takto
                        poskytnúť za predpokladu, že dotknutá osoba preukázala
                        svoju totožnosť.
                    </p>

                    <p>
                        <b>AB & Partners, s.r.o.</b> prijala všetky primerané
                        personálne, organizačné a technické opatrenia za účelom
                        maximálnej ochrany Vašich osobných údajov s cieľom v čo
                        najväčšej miere znížiť riziko ich zneužitia. V zmysle
                        našej povinnosti vyplývajúcej z článku 34 Nariadenia Vám
                        ako dotknutým osobám oznamujeme, že ak nastane situácia,
                        že ako prevádzkovateľ porušíme ochranu Vašich osobných
                        údajov spôsobom, ktorý pravdepodobne povedie k vysokému
                        riziku pre práva a slobody fyzických osôb, bez
                        zbytočného odkladu Vám túto skutočnosť oznámime.
                    </p>
                    <p>
                        Právne predpisy a s nimi súvisiace spôsoby spracovávania
                        Vašich osobných údajov sa môžu meniť. Ak sa tieto zásady
                        rozhodneme aktualizovať, umiestnime zmeny na našej
                        webstránke a budeme Vás o týchto zmenách informovať. V
                        prípadoch, kedy má dôjsť k zásadnejšej zmene týchto
                        zásad, alebo v prípade, kedy nám tak uloží zákon, budeme
                        Vás informovať vopred. Žiadame Vás, aby ste si tieto
                        zásady starostlivo prečítali a pri ďalšej komunikácii s
                        nami, resp. používaní našej webstránky tieto zásady
                        pravidelne kontrolovali.
                    </p>

                    <p>
                        Ak máte akúkoľvek otázku ohľadne spracúvania Vašich
                        osobných údajov, vrátane uplatnenia vyššie uvedených
                        práv, môžete sa obrátiť na našu kontaktnú osobu: emailom
                        na info@activitypoint.xyz. Všetky vaše podnety a sťažnosti riadne
                        preveríme.
                    </p>

                    <p>
                        Ak nie ste spokojný s našou odpoveďou, alebo sa
                        domnievate, že vaše osobné údaje spracúvame
                        nespravodlivo alebo nezákonne, môžete podať sťažnosť na
                        dozorný orgán, ktorým je Úrad na ochranu osobných údajov
                        Slovenskej republiky, https://dataprotection.gov.sk,
                        Hraničná 12, 820 07 Bratislava 27; tel. číslo: +421 /2/
                        3231 3214; E-mail: statny.dozor@pdp.gov.sk.
                    </p>

                    <Divider />

                    <p>
                        <b>Prevádzkovateľ:</b> AB & Partners, s.r.o., Námestie
                        slobody 4, 066 01 Humenné, IČO: 50711946
                    </p>
                </Container>
            </Grid.Row>
        </Grid>
    );
}

export default PrivacyText;
