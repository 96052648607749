import React, { Fragment } from "react";
import { useSelector } from "react-redux";
// components
import HeaderTitle from "../../components/others/HeaderTitle";
import HomeScreenStats from "./admin/stats/views/HomeScreenStats";

// component
function Welcome() {
    const user = useSelector((state) => state.user);

    // template
    return (
        <Fragment>
            <HeaderTitle title={ user.role === "Admin" ? "Administratíva - prehľad" : "Užívateľské rozhranie" } />
            <section>
                { user.role === "Admin" && <HomeScreenStats/> }
            </section>
        </Fragment>
    );
}

export default Welcome;
