// react
import React from "react";
// components
import PlanningDeleteEventForm from "./PlanningDeleteEventForm";
import { Modal } from "semantic-ui-react";
// packages
import moment from "moment";

moment.locale("sk");

// component
function PlanningDeleteEvent({
    modalDeleteEventOpen,
    handleModalDeleteEventClose,
    deleteEventInfo,
    changeModalMessageText,
    changeModalMessageUpdate,
}) {
    const newMessage = (message) => {
        changeModalMessageText(message);
        changeModalMessageUpdate(true);
        handleModalDeleteEventClose();
    };

    //template
    return (
        <Modal
            closeIcon
            open={modalDeleteEventOpen}
            onClose={handleModalDeleteEventClose}
            closeOnDimmerClick={false}
        >
            <Modal.Header>Zrušiť plánovanú udalosť</Modal.Header>

            <PlanningDeleteEventForm
                deleteEventInfo={deleteEventInfo}
                newMessage={newMessage}
            />
        </Modal>
    );
}

export default PlanningDeleteEvent;
