import React, { Fragment, useEffect, useState } from 'react';
// store
import { futureEventService } from '../../../../store/services/ServiceFutureEvents';
// components
import FutureEvent from './FutureEvent';
import { Grid } from 'semantic-ui-react';
import FutureEventForm from './FutureEventForm';
import SuperModal from '../../../../components/modals/SuperModal';
import Loading from '../../../../components/others/Loading';
import HeaderTitle from '../../../../components/others/HeaderTitle';
import ButtonCard from '../../../../components/buttons/ButtonCard';

const FutureEvents = () => {
    const [isLoading, setIsLoading] = useState(true)
    const [data, setData] = useState([])

    useEffect( () => {
        async function fetchData(){
            const result = await futureEventService.getFutureEvents()
            if(result.status === 200){
                setData(result.response)
            }
            setIsLoading(false)
        }

        fetchData()
    }, [])

    const onCreate = (newItem) => {
        setData([...data, newItem])
    }

    const onUpdate = (updatedItem) => {
        setData(prevState => prevState.filter(item => {
            if( item.id === updatedItem.id ){
                item.activity = updatedItem.activity
                item.address = updatedItem.address
                item.starts = updatedItem.starts
                item.ends = updatedItem.ends
                item.expiration = updatedItem.expiration
                item.activity_display.name = updatedItem.activity_display.name
                item.address_display = updatedItem.address_display
            }

            return item;
        }));
    }

    return (
        <Fragment>
            <HeaderTitle title="Budúce termíny" />
            <section>
                {isLoading ? (
                    <Loading />
                ) : (
                    <Grid>
                        <Grid.Row stretched>
                            {data.map((item, index) => (
                                <Grid.Column
                                    key={index}
                                    mobile={16}
                                    tablet={8}
                                    computer={4}
                                >
                                    <SuperModal
                                        header="Upraviť budúci termín"
                                        trigger={
                                            <FutureEvent data={item}/>
                                        }
                                        content={
                                            <FutureEventForm 
                                                onCreate={onCreate}
                                                onUpdate={onUpdate} 
                                                data={item}
                                            />
                                        }
                                    />
                                </Grid.Column>
                            ))}
                            <Grid.Column
                                mobile={16}
                                tablet={8}
                                computer={4}
                            >
                                <SuperModal
                                    header="Vytvoriť budúci termín"
                                    trigger={
                                        <ButtonCard text="Vytvoriť budúci termín"/>
                                    }
                                    content={
                                        <FutureEventForm 
                                            onCreate={onCreate}
                                            onUpdate={onUpdate} 
                                            data={{}}
                                        />
                                    }
                                />
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                )}
            </section>
        </Fragment>
    );
};

export default FutureEvents;