// react
import React from "react";
// components
import { Dimmer, Loader } from "semantic-ui-react";

// component
function DimmerLoader({ active }) {
    // template
    return (
        <Dimmer active={active} inverted>
            <Loader inverted />
        </Dimmer>
    );
}

export default DimmerLoader;
