// react
import React from "react";
// components
import { Button, Dropdown, Icon, List } from "semantic-ui-react";

// component
function ProfileSocialNetworkItem({
    socialNetwork,
    socialNetworkCount,
    isProcessing,
    socialNetworks,
    moveOrderDown,
    moveOrderUp,
    handleModalFormOpen,
    confirmDelete,
}) {
    // template
    return (
        <List.Item key={socialNetwork.id} className="list-item-custom-styles">
            <List.Content floated="right">
                <div
                    className="social-network-order"
                    style={{
                        verticalAlign: "bottom",
                    }}
                >
                    <Button
                        icon
                        attached="left"
                        disabled={isProcessing || socialNetworkCount < 1}
                        onClick={() => moveOrderUp(socialNetworkCount)}
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                        }}
                    >
                        <Icon name="angle up" />
                    </Button>
                    <Button
                        icon
                        attached="right"
                        disabled={
                            isProcessing ||
                            socialNetworkCount >= socialNetworks.length - 1
                        }
                        onClick={() => moveOrderDown(socialNetworkCount)}
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                        }}
                    >
                        <Icon name="angle down" />
                    </Button>
                </div>
            </List.Content>

            <List.Icon
                name={socialNetwork.icon}
                size="big"
                style={{ verticalAlign: "middle", marginTop: "0.5rem" }}
            />

            <List.Content style={{ paddingLeft: "2.5rem" }}>
                <List.Header>
                    <Dropdown floating basic text={socialNetwork.name}>
                        <Dropdown.Menu>
                            <Dropdown.Item
                                icon="edit"
                                text="Upraviť"
                                onClick={() =>
                                    handleModalFormOpen(
                                        true,
                                        socialNetworkCount
                                    )
                                }
                            />
                            <Dropdown.Item
                                icon="angle up"
                                text="Presunúť vyššie"
                                disabled={
                                    isProcessing || socialNetworkCount < 1
                                }
                                onClick={() => moveOrderUp(socialNetworkCount)}
                            />
                            <Dropdown.Item
                                icon="angle down"
                                text="Presunúť nižšie"
                                disabled={
                                    isProcessing ||
                                    socialNetworkCount >=
                                        socialNetworks.length - 1
                                }
                                onClick={() =>
                                    moveOrderDown(socialNetworkCount)
                                }
                            />
                            <Dropdown.Item
                                icon="trash"
                                text="Vymazať"
                                onClick={() => confirmDelete(socialNetwork.id)}
                            />
                        </Dropdown.Menu>
                    </Dropdown>
                </List.Header>
                <List.Description style={{ marginTop: "0.3rem" }}>
                    <Button
                        as="a"
                        href={socialNetwork.link}
                        target="_blank"
                        content={socialNetwork.link}
                        labelPosition="left"
                        basic
                    />
                </List.Description>
            </List.Content>
        </List.Item>
    );
}

export default ProfileSocialNetworkItem;
