// react
import React from "react";
// components
import { Card, Header, Icon } from "semantic-ui-react";

// component
function RegisterTypeCard({ header, icon, text }) {
    // template
    return (
        <Card
            fluid
            centered
            className="registration-account-type"
            style={{ marginTop: "0.5rem" }}
        >
            <Card.Content style={{ textAlign: "center" }}>
                <Card.Description>
                    <Icon.Group size="huge">
                        <Icon name="user add" color="black" circular />
                        <Icon name={icon} corner="top left" color="pink" />
                    </Icon.Group>
                    <Header as="h2" style={{ marginBottom: "0.5rem" }}>
                        {header}
                    </Header>
                    <Header as="h5" style={{ marginTop: "0.5rem" }}>
                        {text}
                    </Header>
                </Card.Description>
            </Card.Content>
        </Card>
    );
}

export default RegisterTypeCard;
