// react
import React, { Fragment } from "react";
// components
import { Header, Image, Rating } from "semantic-ui-react";

// component
function ActivityDetailHeader({ activityName, addressName }) {
    // template
    return (
        <Fragment>
            <Image src="/images/general/image_placeholder_cover.jpg" />
            <div
                style={{
                    position: "absolute",
                    bottom: "2rem",
                    left: "5rem",
                }}
            >
                <Header
                    as="h1"
                    inverted
                    style={{
                        margin: "0.3rem 0rem",
                    }}
                >
                    {activityName}
                </Header>
                <Header
                    as="h4"
                    inverted
                    style={{
                        margin: "0.5rem 0rem",
                    }}
                >
                    {addressName}
                </Header>
                <Rating defaultRating={3} maxRating={5} icon="star" disabled />
            </div>
        </Fragment>
    );
}

export default ActivityDetailHeader;
