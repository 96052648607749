// react
import React, { useState, Fragment, useEffect } from "react";
// store
import { companyService } from "../../../store/services/ServiceCompany";
import { LOADING_TIME } from "../../../store/config";
import { isEmpty } from "../../../store/services/helpers/validation";
// components
import ButtonSubmit from "../../../components/buttons/ButtonSubmit";
import ModalResponse from "../../../components/modals/ModalResponse";
import { Form, Grid, Header } from "semantic-ui-react";

// component
function ProfileNickname() {
    // state
    const [nickname, setNickname] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [isProcessing, setIsProcessing] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);
    const [modalIsError, setModalIsError] = useState(false);
    const [modalMessage, setModalMessage] = useState("");

    // fetch data - profile nickname
    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);
            const data = await companyService.getCompanyInformation();
            // set nickname to state
            if (data[0]) {
                setNickname(data[0].nickname);
            }

            setTimeout(function () {
                setIsLoading(false);
            }, LOADING_TIME);
        };

        fetchData();
    }, []);

    /** component functions **/

    const openModal = (message, error) => {
        setModalMessage(message);
        setModalIsError(error);
        setIsProcessing(false);
        handleModalOpen();
    };

    const handleModalOpen = () => {
        setModalOpen(true);
    };

    const handleModalClose = () => {
        setModalOpen(false);
    };

    // submit form
    const handleSubmit = async (event) => {
        event.preventDefault();
        setIsProcessing(true);

        const updatedNickname = {
            nickname: nickname,
        };

        if (await companyService.updateCompanyInformation(updatedNickname)) {
            openModal("Aktualizácia prezývky prebehla úspešne.", false);
        } else {
            openModal("Počas aktualizácie prezývky došlo k chybe!", true);
        }
    };

    // template
    return (
        <Fragment>
            <Grid>
                <Grid.Column computer={8} tablet={8} mobile={16}>
                    <Header as="h3">NASTAVENIE PREZÝVKY</Header>
                    <Form loading={isLoading} onSubmit={handleSubmit}>
                        <Form.Field>
                            <Form.Input
                                required
                                autoFocus
                                fluid
                                label="Prezývka"
                                placeholder="Prezývka"
                                value={nickname}
                                onChange={(e) => setNickname(e.target.value)}
                            />
                        </Form.Field>

                        <ButtonSubmit
                            loading={isProcessing}
                            disabled={isProcessing || isEmpty(nickname)}
                            text="Uložiť"
                        />
                    </Form>
                </Grid.Column>
            </Grid>

            <ModalResponse
                open={modalOpen}
                onClose={handleModalClose}
                icon={modalIsError ? "warning circle" : "checkmark"}
                header={
                    modalIsError
                        ? "Aktualizácia neúspešná"
                        : "Aktualizácia úspešná"
                }
                message={modalMessage}
                onClick={handleModalClose}
            />
        </Fragment>
    );
}

export default ProfileNickname;
