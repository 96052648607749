// react
import React, { useEffect } from "react";
// components
import Navbar from "../../components/general/Navbar";
import Footer from "../../components/general/Footer";
import Banner from "../../components/general/Banner";
import PrivacyText from "./PrivacyText";
import { GAPageView, initGA } from "../../index";

// component
function Privacy() {
    // Google analytics
    useEffect(() => {
        initGA();
        GAPageView();
    }, []);

    // template
    return (
        <div className="website">
            <Navbar linkBack="/search" />
            <Banner src="/images/public/contact/privacy.png" position="bottom"/>
            <PrivacyText />
            <Footer />
        </div>
    );
}

export default Privacy;
