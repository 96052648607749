import React, { useState  } from 'react';
// store 
import { activityService } from '../../../../store/services/ServiceActivity';
import { utcToLocal } from "../../../../store/services/helpers/dates";
import moment from "moment";
// components 
import { Header, Divider, Form, Icon, Grid } from 'semantic-ui-react';
import ButtonSubmit from '../../../../components/buttons/ButtonSubmit';
import QrReader from 'react-qr-reader'

const VerificationForm = ({ onVerification, onClose }) => {
    const [code, setCode] = useState("")
    // eslint-disable-next-line
    const [product, setProduct] = useState(null)
    // const formRef = useRef(null);
    
    // eslint-disable-next-line
    const [isProcessing, setIsProcessing] = useState(false)
    const [qrReader, setQrReader] = useState(false)

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsProcessing()
        const request = await activityService.verifyActivity({
            code: code
        })

        if( request.status === 200 ){
            setProduct(request.response)
            if (onVerification) onVerification(request.status)
            setCode("")
        } else if( request.status === 204 ) {
            alert("Kód už bol overovaný, nie je možné vykonať opakovanú verifikáciu")
        } else {
            alert("Kód sa nepodarilo overiť!")
        }
    }

    const handleQRScanner = (data) => {
        setProduct(null)
        setCode(data)

        // formRef.current.submit();
    }

    const previewStyle = {
        height: 240,
        width: 320,
    }

    return (
        <div>
            <Header textAlign="center" as="h3" content="Overiť aktivitu"/>
            <Divider/>

            <Form onSubmit={(e) => handleSubmit(e)}>
                { qrReader &&
                    <Grid centered style={{ marginBottom: "5rem", marginTop: "1rem" }}>
                        <QrReader
                            delay={100}
                            style={previewStyle}
                            onError={ (error) => alert(error) }
                            onScan={handleQRScanner}
                            facingMode="environment"
                        />
                    </Grid>
                }
                <Form.Input
                    fluid
                    label="Verifikačný kód"
                    placeholder="vložte verifikačný kód"
                    icon={<Icon onClick={ () => setQrReader(!qrReader) } name='qrcode' circular link />}
                    value={ code }
                    onChange={ (e) => {
                        setCode(e.target.value)
                        setProduct(null)
                    } }
                />

                { product !== null && <div>
                    <Header textAlign="left" as="h3" content="Detail aktivity"/>
                    <Divider/>
                    Aktivita: <strong>{ product?.product_name }</strong> <br/>
                    Termín: <strong>{ moment(utcToLocal(product?.event?.starts)).format("DD.MM.YYYY HH:mm") }</strong> <br/>
                    Užívateľ: <strong>{ product?.owner?.firstname } { product?.owner?.lastname }</strong>

                    <Header textAlign="center" as="h2" content="Aktivita overená"/>
                </div> }
                <ButtonSubmit loading={isProcessing} disabled={isProcessing || code === ""} fluid text="Odoslať na overenie"/>
            </Form>
        </div>
    );
};

export default VerificationForm;