// react
import React from "react";
// components
import { Button, Grid, Icon, Table } from "semantic-ui-react";

// component
function ProviderDetailActivities({ provider }) {
    // template
    return (
        <Grid.Row>
            <Grid.Column>
                <Table celled striped>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>Názov</Table.HeaderCell>
                            <Table.HeaderCell collapsing textAlign="center">
                                Cena
                            </Table.HeaderCell>
                            <Table.HeaderCell collapsing textAlign="center">
                                Detail
                            </Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {provider.offers && provider.offers.length ? (
                            provider.offers.map((offer, id) => (
                                <Table.Row key={id}>
                                    <Table.Cell>{offer.name}</Table.Cell>
                                    <Table.Cell collapsing textAlign="center">
                                        {offer.price} €
                                    </Table.Cell>
                                    <Table.Cell collapsing textAlign="center">
                                        <Button
                                            as="a"
                                            href={"/activity/" + offer.id}
                                            target="_blank"
                                            color="green"
                                        >
                                            <Icon name="eye" /> Zobraziť detail
                                        </Button>
                                    </Table.Cell>
                                </Table.Row>
                            ))
                        ) : (
                            <Table.Row>
                                <Table.Cell textAlign="center" colSpan="3">
                                    Tento poskytovateľ nemá vytvorenú žiadnu
                                    ponúkanú aktivitu.
                                </Table.Cell>
                            </Table.Row>
                        )}
                    </Table.Body>
                </Table>
            </Grid.Column>
        </Grid.Row>
    );
}

export default ProviderDetailActivities;
