import request from "umi-request";
import { authService } from "../ServiceAuth";


/*
    Example USAGE:
    
    async function getUserProfile(){
        return await APIRequest({
            url: API_PATH, 
            method: 'GET',
            data: {},
            private: true // by default APIRequest is Private, if you want to use public use false value
        });
    }

    Response on Success:
    {
        status: 200, // or other success codes
        response: {...} // as data response object that could contain data or other type of information
    }

    Response on Failure:
    {
        status: 400, // or other failure codes
        response: {...} // as data response object, that could contain error messages
    }

*/

export async function APIRequest(config) {
    /*
        ** Function is responsible for calling and API using specific configuration: URL, Method and Data Object.
        ** Function will call private API when private configuration is set to TRUE (default: true)
        ** which means that we need to send API Access Token that is valid
    */
   
   let request_headers = {};
   
   if( config.private ){
        const accessToken = await authService.getAccessToken();
        request_headers = {
            Authorization: `Bearer ${accessToken}`,
            'Accept-Language': config.language || 'en'
        }
    } else {
        request_headers = {
            'Accept-Language': config.language || 'en'
        }
    }

    return await request(config.url, {
        method: config.method,
        data: config.data,
        getResponse: true,
        headers: request_headers,
    }).then((response) => {
        return {
            status: response.response.status,
            response: response.data 
        };
    }).catch((error) => {
        if( error.response ){
            return {
                status: error.response.status,
                response: error.data
            };
        }
    })
}

