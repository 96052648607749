import React from 'react';
// components
import { Card, Header, Icon } from 'semantic-ui-react';
// others
import moment from 'moment';

const FutureEvent = ({ data, ...rest }) => {
    return (
        <Card
            {...rest}
            fluid
            style={{
                marginTop: "1rem",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
            }}
        >
            <Card.Content style={{ textAlign: "center" }}>
                <Icon
                    circular
                    size="big"
                    color="pink"
                    name="tags"
                    style={{ width: "100%" }}
                />
                <Header as="h3" style={{ marginTop: "1rem" }}>
                    { data.activity_display.name }
                    <Header.Subheader>
                        <small>{ data.address_display }</small>
                    </Header.Subheader>
                </Header>
                <Header as="h5">
                    { moment(data.starts).format("DD.MM.YYYY") } - { moment(data.ends).format("DD.MM.YYYY") } <br/>
                    <Header.Subheader>
                        Expirácia: { moment(data.expiration).fromNow() } 
                    </Header.Subheader>
                </Header>
                <small>Kliknutím upravíš.</small>
            </Card.Content>
        </Card>
    );
};

export default FutureEvent;