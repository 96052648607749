import React from "react";
import { Link as RouteLink } from "react-router-dom";
import Footer from "../../components/general/Footer";
import { Image, Grid, Container } from "semantic-ui-react";

const APOthers = () => {
    return (
        <div className="website" style={{ fontFamily: "Metropolis Regular" }}>
            {/* <Navbar linkBack="/" /> */}
            <div className="navigation" style={{ marginBottom: "1rem" }}>
                <span className="navigation-logo">
                    <RouteLink to={"/"}>
                        <Image
                            className="navlogo"
                            src="/images/logos/logo_dark_version.png"
                            width="40%"
                        />
                    </RouteLink>
                </span>
            </div>

            <div className="banner-wrapper">
                <div
                    className="banner-container"
                    style={{
                        backgroundImage: `url(/images/public/contact/banner.jpg)`,
                        backgroundPosition: "bottom 40%",
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "cover",
                        height: "80vH",
                    }}
                />

                <div className="back" style={{ maxWidth: "45rem" }}>
                    {/*<div>
                        <Label
                            style={{
                                color: "white",
                                background: "rgb(255, 0, 0)",
                            }}
                        >
                            {" "}
                            #APwalkIn{" "}
                        </Label>
                    </div>*/}
                    <div
                        className="text"
                        style={{
                            paddingBottom: "0.2rem",
                            paddingTop: "0.5rem",
                        }}
                    >
                        <span
                            style={{
                                fontSize: "2rem",
                                fontFamily: "Metropolis Bold",
                            }}
                        >
                            Máš záujem o niečo iné ?
                        </span>
                    </div>
                    <p>
                        Si správne. <br />
                        Vitaj v pohybovej časti šitej na mieru.
                    </p>

                    <p>Čítaj ďalej.</p>
                </div>
            </div>

            <Container>
                <Grid className="section" relaxed stackable>
                    <Grid.Row columns={1} centered style={{ marginTop: "0" }}>
                        <Grid.Column style={{ textAlign: "left" }}>
                            {/* <Image src="/images/icons/AP_Map_Pointer_future_terms.svg" style={{ marginBottom: "1rem", width: "50px" }}/> */}
                            <div>
                                <div
                                    style={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "flex-start",
                                        fontSize: "1.3rem",
                                        fontWeight: "bold",
                                        position: "relative",
                                        bottom: "-2rem",
                                    }}
                                >
                                    <div
                                        style={{
                                            fontFamily: "Metropolis Bold",
                                        }}
                                    >
                                        Chceš zdravý office?
                                    </div>
                                    <Image
                                        style={{
                                            width: "110px",
                                            marginLeft: "1rem",
                                            top: "-1rem",
                                            position: "relative",
                                            transform: "rotate(65deg)",
                                        }}
                                        src="images/icons/arrow-pink.png"
                                    />
                                </div>
                                <p style={{ marginTop: "0.5rem" }}>
                                    Si zodpovedný zamestnávateľ? Nie je Ti
                                    pracovná motivácia Tvojho tímu ukradnutá?
                                    Rád svojim zamestnancom poskytneš benefit,
                                    na ktorý sa nezabúda? Náš tím motivátorov a
                                    trénerov prichádza, aby rozpovedal pravdivý
                                    príbeh o prínose pohybu do života a nabudil
                                    tak každého Janka či Elenku, ktorí sa
                                    presúvajú na miesto výkonu svojej práce
                                    výlučne iba výťahom. Traduje sa, že
                                    ktokoľvek zdravý si raz vyskúša edukáciu pod
                                    taktovkou ActivityPoint, pre toho slovo
                                    výťah prestáva existovať. Záujem o
                                    spoluprácu, recepty a otázky pošli na{" "}
                                    <a href="mailto:info@activitypoint.xyz">
                                        info@activitypoint.xyz
                                    </a>
                                </p>
                            </div>
                        </Grid.Column>
                        <Grid.Column
                            style={{ textAlign: "left", marginTop: "2rem" }}
                        >
                            {/* <Image src="/images/icons/AP_Map_Pointer_future_terms.svg" style={{ marginBottom: "1rem", width: "50px"}}/> */}
                            <div>
                                <div
                                    style={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "flex-start",
                                        fontSize: "1.3rem",
                                        fontWeight: "bold",
                                        position: "relative",
                                        bottom: "-2rem",
                                    }}
                                >
                                    <div
                                        style={{
                                            fontFamily: "Metropolis Bold",
                                        }}
                                    >
                                        Si rezort?
                                    </div>
                                    <Image
                                        style={{
                                            width: "110px",
                                            marginLeft: "1rem",
                                            top: "-1rem",
                                            position: "relative",
                                            transform: "rotate(65deg)",
                                        }}
                                        src="images/icons/arrow-pink.png"
                                    />
                                </div>

                                <p style={{ marginTop: "0.5rem" }}>
                                    Ako docieliš to, že o Tvojom rezorte vie aj
                                    turista, ktorý v tomto momente popíja kávu a
                                    rozhoduje sa, čo s načatým dňom? Ako to
                                    spravíš, keď je turista 10 km od Teba, v
                                    kaviarni, v cudzom meste? Necháš pred každú
                                    kaviareň umiestniť obrovský billboard? Ak by
                                    si turistu dostal k sebe do rezortu, už by
                                    si ho nepustil, že? Ako ho dostaneš do
                                    vnútra? Tieto a všetky ostatné dilemy
                                    vyriešime s Tebou, na mieru, špeciálne, v
                                    kategórii APrezort. <br />
                                    ActivityPoint na mape Tvoj rezort ZOBRAZÍ
                                    každému turistovi (aj neturistovi), ktorý
                                    dumá ako minúť svoj voľný čas (a ťažko
                                    zarobené peňeži). Nekončíme však pri
                                    zobrazovaní, ActivityPoint aj INFORMUJE a
                                    NAVIGUJE. Sme osobná asistentka turistu.
                                    Záujem o spoluprácu, či prípadné otázky nám
                                    vieš poslať na{" "}
                                    <a href="mailto:info@activitypoint.xyz">
                                        info@activitypoint.xyz
                                    </a>
                                </p>
                            </div>
                        </Grid.Column>
                        <Grid.Column
                            style={{ textAlign: "left", marginTop: "2rem" }}
                        >
                            {/* <Image src="/images/icons/AP_Map_Pointer_future_terms.svg" style={{ marginBottom: "1rem", width: "50px" }}/> */}
                            <div>
                                <div
                                    style={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "flex-start",
                                        fontSize: "1.3rem",
                                        fontWeight: "bold",
                                        position: "relative",
                                        bottom: "-2rem",
                                    }}
                                >
                                    <div
                                        style={{
                                            fontFamily: "Metropolis Bold",
                                        }}
                                    >
                                        Chceš digitálny vstup?
                                    </div>
                                    <Image
                                        style={{
                                            width: "110px",
                                            marginLeft: "1rem",
                                            top: "-1rem",
                                            position: "relative",
                                            transform: "rotate(65deg)",
                                        }}
                                        src="images/icons/arrow-pink.png"
                                    />
                                </div>

                                <p style={{ marginTop: "0.5rem" }}>
                                    Vieš, že existuje nástroj, ktorý pustí
                                    záujemcu na miesto akejkoľvek aktivity v
                                    ktoromkoľvek čase, či nečase, a to pod
                                    dohľadom bezpečnostného zabezpečovacieho
                                    systému? Ten nástroj existuje, volá sa
                                    digitálny vstup. <br />
                                    Primárne je určený pre samosprávy, či iné
                                    právnické osoby, vrátane komerčnej oblasti.
                                    ActivityPoint zabezpečí všetko potrebné s
                                    realizáciou digitálneho vstupu, od hardvéru
                                    až po ponúknutie (rozumej predaj) vstupu na
                                    miesto aktivity v rozhraní ActivityPoint. U
                                    nás to voláme hudba budúcnosti. A preto
                                    záujem o spoluprácu, či prípadné otázky nám
                                    vieš poslať na{" "}
                                    <a href="mailto:info@activitypoint.xyz">
                                        info@activitypoint.xyz
                                    </a>
                                </p>
                            </div>
                        </Grid.Column>
                        <Grid.Column
                            style={{ textAlign: "left", marginTop: "2rem" }}
                        >
                            {/* <Image src="/images/icons/AP_Map_Pointer_future_terms.svg" style={{ marginBottom: "1rem", width: "50px" }}/> */}
                            <div>
                                <div
                                    style={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "flex-start",
                                        fontSize: "1.3rem",
                                        fontWeight: "bold",
                                        position: "relative",
                                        bottom: "-2rem",
                                    }}
                                >
                                    <div
                                        style={{
                                            fontFamily: "Metropolis Bold",
                                        }}
                                    >
                                        Alebo ponuka šitá na mieru?
                                    </div>
                                    <Image
                                        style={{
                                            width: "110px",
                                            marginLeft: "1rem",
                                            top: "-1rem",
                                            position: "relative",
                                            transform: "rotate(65deg)",
                                        }}
                                        src="images/icons/arrow-pink.png"
                                    />
                                </div>

                                <p style={{ marginTop: "0.5rem" }}>
                                    V prípade, že máš záujem o pohybové aktivity
                                    ušité na mieru, poradenstvo v oblasti toho
                                    ako efektívne ponúkať tvojim klientom čo máš
                                    k dispozícii a v akej forme im to
                                    servírovať, vieš nám napísať na{" "}
                                    <a href="mailto:info@activitypoint.xyz">
                                        info@activitypoint.xyz
                                    </a>
                                </p>
                            </div>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Container>
            <Footer />
        </div>
    );
};

export default APOthers;
