// react
import React from "react";
// store
import { authService } from "../../store/services/ServiceAuth";
// components
import { Icon } from "semantic-ui-react";

// component
function DashboardLink(props) {
    let getOriginURI = window.location.href;
    let buildURI = window.location.origin + "/dashboard/" + props.address;

    // template
    return (
        <>
            {authService.useHasRole(props.role) || props.role === "All" ? (
                <button
                    className={
                        getOriginURI === buildURI
                            ? "menu-link active"
                            : "menu-link"
                    }
                    onClick={props.task}
                >
                    <Icon name={props.iconName} />
                    <span>{props.title}</span>{" "}
                    {getOriginURI === buildURI ? (
                        <img
                            src="/images/icons/icon_menu_item_active.png"
                            alt={props.title}
                        />
                    ) : (
                        ""
                    )}
                </button>
            ) : (
                ""
            )}
        </>
    );
}

export default DashboardLink;
