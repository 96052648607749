// react
import React from "react";
// store
// import { routes } from "../../store/routes";
// components
import { Header/*, Button*/ } from "semantic-ui-react";

// component
const EmptyBasket = () => {
    // template
    return (
        <Header as="h3" textAlign="center">
            Košík neobsahuje žiadne položky <br />
            {/* <br />
            <Button as="a" href={routes.SEARCH} size="huge" color="pink">
                Vyhľadaj aktivity v okolí
            </Button> */}
        </Header>
    );
};

export default EmptyBasket;
