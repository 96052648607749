import request from "umi-request";
import { API } from "../config";

// services
import { authService } from "./ServiceAuth";

/*
 * Service functions
 */
export const bundleService = {
    getBundles,
    getBundleById,
    saveBundle,
    editBundle,
    deleteBundle,
};

async function getBundles() {
    let data;
    // create Async - Await request to get all bundles
    let accessToken = await authService.getAccessToken();
    data = await request(API.GET_BUNDLES, {
        method: "get",
        headers: {
            Authorization: `Bearer ${accessToken}`,
        },
    })
        .then(function (response) {
            return response;
        })
        .catch(function (error) {
            return [];
        });

    return data;
}

async function getBundleById(id) {
    let data;
    // create Async - Await request to get all bundles
    let accessToken = await authService.getAccessToken();
    data = await request(API.GET_BUNDLES + "?id=" + id, {
        method: "get",
        headers: {
            Authorization: `Bearer ${accessToken}`,
        },
    })
        .then(function (response) {
            return response;
        })
        .catch(function (error) {
            return {};
        });

    return data;
}

async function saveBundle(data) {
    let saved;
    // create Async - Await request to get all bundles
    let accessToken = await authService.getAccessToken();
    saved = await request(API.CREATE_BUNDLE, {
        method: "post",
        data: data,
        headers: {
            Authorization: `Bearer ${accessToken}`,
        },
    })
        .then(function (response) {
            return true;
        })
        .catch(function (error) {
            return false;
        });

    return saved;
}

async function editBundle(data, id) {
    let updated;

    let accessToken = await authService.getAccessToken();
    updated = await request(API.EDIT_OR_DELETE_BUNDLE + id, {
        method: "put",
        data: data,
        headers: {
            Authorization: `Bearer ${accessToken}`,
        },
    })
        .then(function (response) {
            return true;
        })
        .catch(function (error) {
            return false;
        });

    return updated;
}

async function deleteBundle(id) {
    let deleted;

    let accessToken = await authService.getAccessToken();
    deleted = await request(API.EDIT_OR_DELETE_BUNDLE + id, {
        method: "delete",
        headers: {
            Authorization: `Bearer ${accessToken}`,
        },
    })
        .then(function (response) {
            return true;
        })
        .catch(function (error) {
            return false;
        });

    return deleted;
}
