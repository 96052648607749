// react
import React, { useState, useEffect } from "react";
// store
import { categoryService } from "../../../../store/services/ServiceCategory";
import { LOADING_TIME } from "../../../../store/config";
import { activeOptions } from "../../../../store/services/helpers/settings";
import { routes } from "../../../../store/routes";
// components
import CategoryTable from "./CategoryTable";
import ButtonAdd from "../../../../components/buttons/ButtonAdd";
import ButtonGroupSearch from "../../../../components/buttons/ButtonGroupSearch";
import AccordionColumn from "../../../../components/others/AccordionColumn";
import { Form, Grid, Segment, Header } from "semantic-ui-react";

// component
function CategorySearch() {
    // state
    const [data, setData] = useState([]);
    const [tableIsLoading, setTableIsLoading] = useState(false);
    const [status, setStatus] = useState("");

    // fetch data
    useEffect(() => {
        const fetchData = async () => {
            setTableIsLoading(true);
            const result = await categoryService.getCategories("");
            if (result.status === 200) {
                // setup data array for table
                let tableData = [];
                for (let i = 0; i < result.response.length; i++) {
                    tableData.push({
                        id: result.response[i].id,
                        name: result.response[i].name,
                        status: result.response[i].status,
                        tags: result.response[i].tags,
                    });
                }

                setData(tableData);
            }

            setTimeout(function () {
                setTableIsLoading(false);
            }, LOADING_TIME);
        };

        fetchData();
    }, []);

    // component functions
    const handleOnChangeStatus = (e, data) => {
        setStatus(data.value);
    };

    const resetForm = async () => {
        setTableIsLoading(true);
        setStatus("");

        const result = await categoryService.getCategories("");
        if (result.status === 200) {
            let tableData = [];
            for (let i = 0; i < result.response.length; i++) {
                tableData.push({
                    id: result.response[i].id,
                    name: result.response[i].name,
                    status: result.response[i].status,
                    tags: result.response[i].tags,
                });
            }

            setData(tableData);
        }

        setTimeout(function () {
            setTableIsLoading(false);
        }, LOADING_TIME);
    };

    const handleSubmit = async () => {
        setTableIsLoading(true);
        let params = "";
        if (status !== "") {
            params = `?status=${status}`;
        }

        // send request to get data based on parameters
        const result = await categoryService.getCategories(params);
        if (result.status === 200) {
            let tableData = [];
            for (let i = 0; i < result.response.length; i++) {
                tableData.push({
                    id: result.response[i].id,
                    name: result.response[i].name,
                    status: result.response[i].status,
                    tags: result.response[i].tags,
                });
            }

            setData(tableData);
        }

        setTimeout(function () {
            setTableIsLoading(false);
        }, LOADING_TIME);
    };

    // search form inputs
    const panels = [
        {
            key: "details",
            title: "Kritéria vyhľadávania",
            content: {
                content: (
                    <div>
                        <Form
                            id="form-search-categories"
                            onSubmit={handleSubmit}
                        >
                            <Form.Group widths="equal">
                                <Form.Select
                                    fluid
                                    label="Status"
                                    options={activeOptions}
                                    placeholder="Status"
                                    value={status}
                                    onChange={handleOnChangeStatus}
                                />
                            </Form.Group>
                        </Form>

                        <ButtonGroupSearch
                            form="form-search-categories"
                            resetDisabled={status === ""}
                            resetOnClick={resetForm}
                        />
                    </div>
                ),
            },
        },
    ];

    // template
    return (
        <Grid>
            <ButtonAdd link={routes.CATEGORIES_ADD} text="Pridať kategóriu" />
            <AccordionColumn panels={panels} />
            <Grid.Column mobile={16} tablet={16} computer={16}>
                <Segment>
                    <Header as="h5">Prehľad kategórií</Header>
                    <CategoryTable data={data} isLoading={tableIsLoading} />
                </Segment>
            </Grid.Column>
        </Grid>
    );
}

export default CategorySearch;
