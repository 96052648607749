import React from 'react';
import { Link } from 'react-router-dom';
// components
import { Card, Header, Icon } from 'semantic-ui-react';

const ButtonCard = ({text, to, ...rest }) => {

    // template
    return (
        <Card
            as={ to ? Link : null }
            to={ to ? to : null } 
            fluid
            style={{
                marginTop: "1rem",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "100%",
                cursor: "pointer"
            }}
            {...rest}
        >
            <Card.Content style={{ textAlign: "center", flexGrow: 0 }}>
                <Icon
                    circular
                    size="big"
                    name="plus"
                    style={{ width: "100%", color: "var(--primary)" }}
                />
                <Header as="h3" style={{ marginTop: "1rem" }}>
                    {text}
                </Header>
            </Card.Content>
        </Card>
    );

};

export default ButtonCard;