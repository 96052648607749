import React, { Fragment, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
// store
import { PAGINATION_LIMIT, API } from "../../../../store/config";
import { commonService } from "../../../../store/services/ServiceCommon";
import moment from "moment";
import { isBeforeCurrentTime } from "../../../../store/services/helpers/dates";
// components
import DimmerLoader from "../../../../components/others/DimmerLoader";
import SuperModal from "../../../../components/modals/SuperModal";
import { Table, Pagination, Button, Icon, Modal, Form, Divider, Header } from "semantic-ui-react";
// others
import QrCode from "react-qr-code";

// component
function OrderedEventsTable({ orders, setOrders, isLoading }) {
    const user = useSelector((state) => state.user);
    // eslint-disable-next-line
    const [ready, setReady] = useState(false)
    // eslint-disable-next-line
    const [tableData, setTableData] = useState(
        orders.slice(0, PAGINATION_LIMIT)
    );

    // set data to table
    useEffect(() => {
        setTableData(orders.slice(0, PAGINATION_LIMIT));
        setReady(true)
    }, [orders]);

    const handleChangePage = (event, data) => {
        setReady(false)
        setTableData(
            orders.slice(
                data.activePage * PAGINATION_LIMIT - PAGINATION_LIMIT,
                data.activePage * PAGINATION_LIMIT
            )
        );
        setReady(true)
    };

    const RefundForm = ({ order, onClose }) => {
        const [refunding, setRefunding] = useState(false)
        const [error, setError] = useState(null)

        const handleRefund = async () => {
            setError(null)
            setRefunding(true)
            
            const request = await commonService.postRequest(API.INVOICES + "storno/product/" + order.id + "/")
    
            if( request.status === 200 ){
                setOrders(prev => prev.filter(item => {
                    if(item.id === order.id){
                        order.is_refunded = true
                    }
                    return item
                }))
                onClose()
            } else {
                setError(request.status)
            }
    
            setRefunding(false)
        }

        return (
            <Form onSubmit={handleRefund}>
                <Header as="h3">Žiadosť o zrušenie termínu</Header>
                <Divider/>
                <p>
                    Pre odstupenie termínu sú stanovené tieto podmienky: <br/>
                    1. Termín bude zrušený a nebude sa dať obnoviť. <br/>
                    2. Na účet bude vrátených <strong>{ order.event.activity.client_ratio }%</strong> z pôvodnej ceny aktivity. <br/> 

                    <h4>Suma na vrátenie: { (((order.amount*order.price) / 100) * parseFloat(order.event.activity.client_ratio)) } EUR</h4>

                    { (error !== null && error > 200) && 
                        <p style={{ color: "red", fontWeight: "bold"}}>
                            { error === 404 && "Nie je možné zrušiť termín. Neexistuje transakcia pre tuto položku."}
                            { error === 401 && "Nie je možné zrušiť termín. Nemáte oprávnenia!"}
                            { error === 500 && "Služba je dočasne nedostupná."}
                        </p>
                    }
                </p>
                <Divider/>
                <Form.Field>
                    <Button disabled={refunding} loading={refunding} style={{ background: "var(--primary)", color: "var(--white)" }}>Potvrdiť</Button>
                </Form.Field>
            </Form>
        )
    }

    const QRRenderView = ({ code }) => {
        const [open, setOpen] = React.useState(false);

        return (
            <Modal
                closeIcon
                open={open}
                trigger={
                    <Button
                        className="verify-button"
                        icon="qrcode"
                        style={{
                            color: "var(--white)",
                        }}
                    />
                }
                onClose={() => setOpen(false)}
                onOpen={() => setOpen(true)}
                size="mini"
            >
                <Header icon="qrcode" content="Tvoj verifikačný kód" />
                <Modal.Content textAlign="center">
                    <Header
                        as="h2"
                        content={code}
                        style={{
                            color: "var(--primary)",
                            textAlign: "center",
                        }}
                    />
                    <div style={{ margin: "0 auto", textAlign: "center"}}>
                        <QrCode value={code} size={250} />
                    </div>
                </Modal.Content>
            </Modal>
        );
    };

    // template
    return (
        <Fragment>
            <DimmerLoader active={isLoading} />
            <Table striped unstackable style={{ overflow: "auto" }}>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>Názov aktivity</Table.HeaderCell>
                        {/* <Table.HeaderCell>Služba</Table.HeaderCell> */}
                        <Table.HeaderCell>Dátum termínu</Table.HeaderCell>
                        <Table.HeaderCell>Poskytovateľ</Table.HeaderCell>
                        <Table.HeaderCell>Objednané</Table.HeaderCell>
                        <Table.HeaderCell>{ user.is_town ? "Príspevok" : "Suma" }</Table.HeaderCell>
                        <Table.HeaderCell>Stav</Table.HeaderCell>
                        <Table.HeaderCell>Akcie</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>

                <Table.Body>
                    { tableData.length === 0 && 
                        <Table.Row> 
                            <Table.Cell colSpan={6} style={{ textAlign: "center", fontWeight: "bold" }}>
                                Neboli najdené žiadne záznamy
                            </Table.Cell>
                        </Table.Row>
                    }
                    {tableData.map((order) => (
                        <>
                        { order.show && 
                        <Table.Row>
                            <Table.Cell>
                                <Header as="h3" style={{ marginBottom: "0.2rem" }}>{ order.event.name }</Header>
                                <Icon name="map marker alternate"/> { order.event?.address }
                            </Table.Cell>
                            {/* <Table.Cell style={{ color: '#2185d0' }}>#APsolidarity</Table.Cell> */}
                            <Table.Cell>
                                { moment(order.event?.starts, "YYYY-MM-DD HH:mm").format("DD.MM.YYYY HH:mm") }
                                <br/>
                                { isBeforeCurrentTime(order.event?.starts) 
                                    ? <small style={{ color: "green" }}>Nadchádzajúci</small> 
                                    : <small style={{ color: "red" }}>Po termíne</small> 
                                }
                            </Table.Cell>
                            <Table.Cell>{ order.provider?.name }</Table.Cell>
                            <Table.Cell>{ (moment(order.created)).format("DD.MM.YYYY") }</Table.Cell>
                            <Table.Cell>{ order.price } €</Table.Cell>
                            <Table.Cell>
                                { order.is_refunded 
                                    ? "Zrušené/vratené" 
                                    : order.is_used ? "Využité" : isBeforeCurrentTime(order.event?.starts) ? "Nevyužité" : "Využité"
                                }

                            </Table.Cell>
                            <Table.Cell>
                                <Button.Group size="small" style={{ marginTop: "0.5rem" }}>
                                <Button
                                    as={Link}
                                    target="_blank"
                                    to={`/activity/${order.event.activity.id}/detail?event=${order.event.id}`}
                                    className="cancel-button"
                                    style={{ marginRight: "0.2rem" }}
                                    icon="eye"
                                />
                                <QRRenderView code={order.verification_code}/>
                                { isBeforeCurrentTime(order.event?.starts) && 
                                    <>
                                        { (!order.is_refunded && order.is_active) &&
                                            <SuperModal
                                                trigger={
                                                    <Button
                                                        icon="close"
                                                        className="storno-button"
                                                        title="Zrušiť termín"
                                                    /> 
                                                }
                                                content={
                                                    <RefundForm order={order}/>
                                                }
                                            />
                                        }
                                    </>
                                    }
                                </Button.Group> 
                            </Table.Cell>
                        </Table.Row>
                        }
                        </>
                    ))}
                </Table.Body>

                <Table.Footer>
                    <Table.Row>
                        <Table.HeaderCell colSpan="8">
                            <Pagination
                                defaultActivePage={1}
                                totalPages={Math.ceil(
                                    orders.length / PAGINATION_LIMIT
                                )}
                                onPageChange={handleChangePage}
                                boundaryRange={0}
                                siblingRange={0}
                            />
                        </Table.HeaderCell>
                    </Table.Row>
                </Table.Footer>
            </Table>
        </Fragment>
    );
}

export default OrderedEventsTable;
