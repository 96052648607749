// react
import React, { useState } from "react";
import {CopyToClipboard} from 'react-copy-to-clipboard';
// components
import ActivityInteract from "./ActivityInteract";
import { Grid/*, Accordion*/, Icon, Popup } from "semantic-ui-react";
import { FacebookShareButton, TwitterShareButton, TelegramShareButton, WhatsappShareButton, EmailShareButton, EmailIcon, WhatsappIcon, TelegramIcon, TwitterIcon, FacebookIcon } from 'react-share';
// others
// const CHAR_LIMIT = 200;

// component
export default function ActivityInfo(props) {
    // state
    // const [more, setMore] = useState(false);
    const [data] = useState(props.data ? props.data : {});
    const [provider] = useState(props.data ? props.data.provider : {});
    const [copied, setCopied] = useState(false)

    // template
    return (
        <Grid className="section-detail" style={{ paddingTop: 0, marginTop: 0 }}>
            <Grid.Row columns={2} centered>
                <Grid.Column
                    mobile={14}
                    tablet={14}
                    computer={props?.size || 6}
                >
                    <div className="box">
                        <div>
                            <h2>Informácie</h2>

                            <div className="customLinks" dangerouslySetInnerHTML={{__html: data?.description || ""}} />
                            
                            {/* <p>
                                {data.description
                                    ? data.description.substr(
                                          0,
                                          more
                                              ? data.description.length
                                              : CHAR_LIMIT
                                      ) + `${more ? "" : "..."}`
                                    : ""}
                            </p> */}
                            {/* <Accordion>
                                <Accordion.Title
                                    active={more}
                                    index={0}
                                    onClick={() => setMore((prev) => !prev)}
                                >
                                    <span className="toggle">
                                        Zobraziť {more ? `menej` : `viac`}
                                    </span>
                                </Accordion.Title>
                            </Accordion> */}
                        </div>

                        <div>
                            <h2>Poskytovateľ</h2>

                            <Grid>
                                <Grid.Row columns={2}>
                                    <Grid.Column
                                        mobile={4}
                                        tablet={4}
                                        computer={3}
                                    >
                                        <ul>
                                            <li>MENO:</li>
                                            <li>TELEFÓN:</li>
                                            <li>E-MAIL:</li>
                                        </ul>
                                    </Grid.Column>
                                    <Grid.Column
                                        mobile={12}
                                        tablet={12}
                                        computer={12}
                                    >
                                        <ul>
                                            <li>
                                                <b>{provider.name}</b>
                                            </li>
                                            <li>
                                                <b>
                                                    <a
                                                        href={`tel:${provider.phone}`}
                                                    >
                                                        {provider.phone}
                                                    </a>
                                                </b>
                                            </li>
                                            <li>
                                                <b>
                                                    <a
                                                        href={`mailto:${provider.email}`}
                                                    >
                                                        {provider.email}
                                                    </a>
                                                </b>
                                            </li>
                                        </ul>
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                        </div>

                        <div>
                            {provider.socials ? (
                                provider.socials.map((social) => (
                                    <Icon
                                        key={social.name}
                                        name={social.icon}
                                        size="big"
                                        onClick={() =>
                                            window.open(social.link, "_blank")
                                        }
                                    />
                                ))
                            ) : (
                                <></>
                            )}
                        </div>

                        <h3>Zdieľajte ďalej</h3>
                        <div style={{ display: "flex", flexDirection: "row", marginTop: "0.5rem" }}>
                            <FacebookShareButton
                                style={{ paddingRight: "0.5rem" }}
                                url={window.location.href}
                            >
                                <FacebookIcon size={32} round />
                            </FacebookShareButton>
                            <TwitterShareButton
                                style={{ paddingRight: "0.5rem" }}
                                url={window.location.href}
                            >
                                <TwitterIcon  size={32} round />
                            </TwitterShareButton>
                            <TelegramShareButton
                                style={{ paddingRight: "0.5rem" }}
                                url={window.location.href}
                            >
                                <TelegramIcon  size={32} round />
                            </TelegramShareButton>
                            <WhatsappShareButton
                                style={{ paddingRight: "0.5rem" }}
                                url={window.location.href}
                            >
                                <WhatsappIcon  size={32} round />
                            </WhatsappShareButton>
                            <EmailShareButton
                                style={{ paddingRight: "0.5rem" }}
                                url={window.location.href}
                            >
                                <EmailIcon  size={32} round />
                            </EmailShareButton>
                            <Popup hideOnScroll size="small" trigger={
                                 <CopyToClipboard text={window.location.href}>
                                    <div onClick={ () => setCopied(true)}>
                                        <Icon circular inverted style={{ fontSize: "1.1rem" }} name="code"/> 
                                        { copied && <span style={{ marginTop: "1rem" }}>Skopirované!</span> }
                                    </div>
                                 </CopyToClipboard>
                            } content="Skopírovať odkaz"/>
                        </div>

                    </div>
                </Grid.Column>

                <ActivityInteract
                    rating={data.rating}
                    cost={data.price}
                    sale={data.discountPrice}
                    eventName={data.name}
                    duration={data.durations}
                    destination={data.coords}
                    usersPosition={props.usersPosition}
                    timetable={data.timetable}
                    selected={props.selected}
                    setSelected={props.setSelected}
                />
            </Grid.Row>
        </Grid>
    );
}
