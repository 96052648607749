// react
import React from "react";
import { Switch, Route } from "react-router-dom";
// store
import { authService } from "../../../../store/services/ServiceAuth";
import { routes } from "../../../../store/routes";
// components
import ActivitiesList from "./ActivitiesList";
import ActivityAdd from "./ActivityAdd";
import ActivityEdit from "./ActivityEdit";

// component
function MyActivities() {
    authService.useIsProvider();

    // template
    return (
        <Switch>
            <Route
                exact
                path={routes.MY_ACTIVITIES}
                component={ActivitiesList}
            />
            <Route
                exact
                path={routes.MY_ACTIVITIES_ADD}
                component={ActivityAdd}
            />
            <Route
                exact
                path={routes.MY_ACTIVITIES_EDIT + ":id"}
                component={ActivityEdit}
            />
        </Switch>
    );
}

export default MyActivities;
