// react
import React from "react";
//components
import { Dimmer, Loader } from "semantic-ui-react";

// component
const CheckLoader = ({ isActive, message }) => {
    // template
    return (
        <Dimmer inverted active={isActive}>
            <Loader>{message}</Loader>
        </Dimmer>
    );
};

export default CheckLoader;
