import React, { useState, useEffect } from 'react';
import moment from 'moment';
// store 
import { API } from '../../../../../store/config';
import { commonService } from '../../../../../store/services/ServiceCommon';
// components
import { Table, Segment } from "semantic-ui-react";

const ActivitiesModalView = ({ filters, provider, onClose }) => {

    const [isLoading, setIsLoading] = useState(false)
    // eslint-disable-next-line
    const [stats, setStats] = useState([])

    useEffect( () => {
        async function fetchActivities(){
            setIsLoading(true)
            let params = ""
            let dateFrom = filters.date_from
            let dateTo = filters.date_to

            if( filters.mode === "custom" ){
                dateFrom = moment(moment(dateFrom, "DD.MM.YYYY")).format("YYYY-MM-DD") 
                dateTo = moment(moment(dateTo, "DD.MM.YYYY")).format("YYYY-MM-DD") 
            }

            if( filters.date_from !== "" && filters.date_to !== "" && filters.mode !== "full" ){
                params = "&from=" + dateFrom + "&to=" + dateTo
            }

            const request = await commonService.getStats(API.GET_STATS + "activities/?provider=" + provider + params)
            if( request.status === 200 ){
                setStats(request.response)
            }
            setIsLoading(false)
        }

        fetchActivities()
    }, [filters, provider])

    const displayEarned = (activity) => {
        return parseFloat(parseFloat(activity.profits.earned) - parseFloat(activity.profits.refunded)).toFixed(2)
    }
    return (
        <Segment loading={isLoading} style={{ border: "none", shadow: "none", boxShadow: "none", minHeight: "10rem", background: "transparent", padding: 0 }}>
            { !isLoading && 
                <Table striped unstackable style={{ overflow: "auto" }}>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell style={{ width: "20%" }}>Názov aktivity - Druh aktivity</Table.HeaderCell>
                            <Table.HeaderCell style={{ width: "20%" }} textAlign="left">Vlastník</Table.HeaderCell>
                            <Table.HeaderCell textAlign="left">Zisk (EUR)</Table.HeaderCell>
                            <Table.HeaderCell textAlign="left">Publikované / Zrušené</Table.HeaderCell>
                            <Table.HeaderCell textAlign="left">Predané / Reklamované</Table.HeaderCell>
                            <Table.HeaderCell textAlign="left">Miera reklamovanosti</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>

                    <Table.Body>
                        { stats.length === 0 && 
                            <Table.Row> 
                                <Table.Cell colSpan={7} style={{ textAlign: "center", fontWeight: "bold" }}>
                                    Neboli najdené žiadne záznamy
                                </Table.Cell>
                            </Table.Row>
                        }

                        {stats.map((activity) => (
                            <Table.Row style={{ fontSize: "0.9rem"}}>
                                <Table.Cell> <strong>{ activity.name }</strong> - { activity.type_name } </Table.Cell>
                                <Table.Cell> { activity.provider.name } </Table.Cell>
                                <Table.Cell> { displayEarned(activity) } </Table.Cell>
                                <Table.Cell> { activity.published_events } / { activity.canceled_events } </Table.Cell>
                                <Table.Cell> { (parseInt(activity.profits.activity_sold) - parseInt(activity.profits.activity_refunded)) } / { activity.profits.activity_refunded } </Table.Cell>
                                <Table.Cell> { activity.profits.complaint_rate + "%" } </Table.Cell>
                            </Table.Row>
                        ))}
                    </Table.Body>
                </Table>
            }
        </Segment>
    );
};

export default ActivitiesModalView;