// react
import React, { useState } from "react";
// components
import PlanningAddEventType from "./PlanningAddEventType";
import PlanningAddEventForm from "./PlanningAddEventForm";
import { Modal } from "semantic-ui-react";

// component
function PlanningAddEvent({
    modalAddEventOpen,
    handleModalAddEventClose,
    changeModalMessageText,
    changeModalMessageUpdate,
}) {
    // state
    const [eventType, setEventType] = useState("exactTime");
    const [modalStep, setModalStep] = useState(1);

    const newMessage = (message) => {
        changeModalMessageText(message);
        changeModalMessageUpdate(true);
        setModalStep(1);
        handleModalAddEventClose();
    };

    const changeEventType = (type) => {
        if (eventType !== type) {
            setEventType(type);
        }
    };

    const nextModalStep = () => {
        setModalStep(modalStep + 1);
    };

    const backModalStep = () => {
        setModalStep(modalStep - 1);
    };

    const resetModal = () => {
        setModalStep(1);
        handleModalAddEventClose();
    };

    //template
    return (
        <Modal
            closeIcon
            open={modalAddEventOpen}
            onClose={resetModal}
            closeOnDimmerClick={false}
        >
            <Modal.Header>Naplánovať novú aktivitu</Modal.Header>
            {modalStep === 1 ? (
                <PlanningAddEventType
                    eventType={eventType}
                    changeEventType={changeEventType}
                    nextModalStep={nextModalStep}
                />
            ) : (
                <PlanningAddEventForm
                    eventType={eventType}
                    backModalStep={backModalStep}
                    handleModalAddEventClose={handleModalAddEventClose}
                    newMessage={newMessage}
                />
            )}
        </Modal>
    );
}

export default PlanningAddEvent;
