// react
import React, { Fragment, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
// store
import { routes } from "../../store/routes";
import { LOADING_TIME } from "../../store/config";
import { searchService } from "../../store/services/ServiceSearch";
import { addToBasket, removeFromBasket } from "../../store/actions";
// components
import ActivityTab from "./ActivityTab";
import ProviderTab from "./ProviderTab";
import Loading from "../../components/others/Loading";
import Error from "../Error";
import ActivityDetailHeader from "../activity-detail/ActivityDetailHeader";
import { Grid, Container, Button, Tab } from "semantic-ui-react";
// others
import ReactGa from "react-ga";

// component
function EventDetail(props) {
    // state
    const [isLoading, setIsLoading] = useState(true);
    //const [activityId] = useState(props.match.params.activity_id);
    const [eventId] = useState(props.match.params.event_id);
    const [company, setCompany] = useState({});
    const [event, setEvent] = useState({});
    const [address, setAddress] = useState({});

    let dispatch = useDispatch();
    const basket = useSelector((state) => state.basket);

    // Google Analytics
    useEffect(() => {
        ReactGa.set({ page: window.location.pathname });
        ReactGa.pageview(window.location.pathname);
    }, []);

    const panes = [
        {
            menuItem: "Aktivita",
            render: () => (
                <Tab.Pane attached={false}>
                    <ActivityTab activity={event.activity} event={event} />
                </Tab.Pane>
            ),
        },
        {
            menuItem: "Poskytovateľ",
            render: () => (
                <Tab.Pane attached={false}>
                    <ProviderTab company={company} />
                </Tab.Pane>
            ),
        },
        {
            menuItem: "Galéria",
            render: () => (
                <Tab.Pane attached={false}>
                    Galéria momentálne nie je dostupná.
                </Tab.Pane>
            ),
        },
    ];

    // fetch data - activity
    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);

            // call getEventByID:
            const result = await searchService.getEventByID(eventId);

            if( result.status === 200 ){
                const eventData = result.response;
                setEvent(eventData);

                const companyData = await searchService.getCompany(
                    eventData.activity.company
                );
                setCompany(companyData);
                setAddress(eventData.address);
            }

            setTimeout(function () {
                setIsLoading(false);
            }, LOADING_TIME);
        };

        fetchData();
    }, [eventId]);

    const addProductToBasket = (e) => {
        e.preventDefault();
        dispatch(addToBasket(eventId));
    };

    const removeProductFromBasket = (e) => {
        e.preventDefault();
        dispatch(removeFromBasket(parseInt(eventId)));
    };

    // template
    return (
        <Fragment>
            {isLoading ? (
                <Loading />
            ) : (
                <Fragment>
                { event.id ? 
                    <Container>
                        <Grid columns="equal">
                            <Grid.Column>
                                <Button.Group floated="left">
                                    <Button
                                        as="a"
                                        href={routes.SEARCH}
                                        color="black"
                                        content="Návrat na vyhľadávanie"
                                        icon="left arrow"
                                        labelPosition="left"
                                        style={{ marginTop: "1rem" }}
                                    />
                                </Button.Group>
                                <Button.Group floated="right">
                                    <Button
                                        as="a"
                                        href={routes.BASKET}
                                        color="green"
                                        icon="cart"
                                        labelPosition="left"
                                        content={"Zobraziť (" + basket.length + ")"}
                                        style={{
                                            marginTop: "1rem",
                                            marginRight: "1rem",
                                        }}
                                    />

                                    {basket.indexOf(eventId) === -1 ? (
                                        <Button
                                            color="pink"
                                            icon="add to cart"
                                            labelPosition="left"
                                            onClick={(e) => addProductToBasket(e)}
                                            content="Pridať do košíka"
                                            style={{ marginTop: "1rem" }}
                                        />
                                    ) : (
                                        <>
                                            <Button
                                                color="red"
                                                icon="remove"
                                                labelPosition="left"
                                                onClick={(e) =>
                                                    removeProductFromBasket(e)
                                                }
                                                content="Odobrať s košíka"
                                                style={{ marginTop: "1rem" }}
                                            />
                                        </>
                                    )}
                                </Button.Group>
                            </Grid.Column>
                        </Grid>

                        <Grid columns="equal">
                            <Grid.Column>
                                <Grid columns="equal">
                                    <Grid.Column width={16}>
                                        <ActivityDetailHeader
                                            activityName={event.activity.name}
                                            addressName={address.name}
                                        />
                                    </Grid.Column>
                                    <Grid.Column width={16}>
                                        <Tab
                                            menu={{
                                                secondary: true,
                                                pointing: true,
                                            }}
                                            panes={panes}
                                        />
                                    </Grid.Column>
                                </Grid>
                            </Grid.Column>
                        </Grid>
                    </Container>
                    : <Error code={"404"} message={"Stránka nenájdená"} /> }
                </Fragment>
            )}
        </Fragment>
    );
}

export default EventDetail;
