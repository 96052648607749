// react
import React, { useState, useEffect } from 'react'
import { googleMapsAPIKEY } from '../../store/config';
// components
import {
    GoogleMap,
    LoadScript,
    Marker,
    DirectionsService,
    Polyline, 
    DistanceMatrixService
} from "@react-google-maps/api";
import Loading from "../../components/others/Loading";
import { Icon, Grid, Message } from 'semantic-ui-react';

// others
import { GAPageView, initGA } from "../../index";

// libraries for google maps
const libraries = ["places", "geometry", "drawing"];

// google map options
const defaultOptions = {
    zoomControl: false,
    mapTypeControl: false,
    scaleControl: false,
    streetViewControl: false,
    rotateControl: true,
    fullscreenControl: false,
    styles: [
        {
            featureType: "poi",
            elementType: "labels.icon",
            stylers: [
                {
                    visibility: "off",
                },
            ],
        },
        {
            featureType: "poi.business",
            stylers: [{ visibility: "off" }],
        },
        {
            featureType: "transit",
            elementType: "labels.icon",
            stylers: [{ visibility: "off" }],
        },
    ],
};

// styles associated with the map
const mapStyles = {
    height: "60vH",
    width: "100%",
    marginTop: '2rem'
};
const lineStyles = {
    strokeColor: '#778299',
    strokeOpacity: 1,
    strokeWeight: 4,
}
const marker = {
    url: "/images/icons/AP_Map_Pointer.svg",
    scaledSize: {width: 50, height: 50},
    anchor: {x: 25, y: 25}
}


// CONST COMPANY COORDS
const companyCoords = {
  lat: 48.9369284,
  lng: 21.9084632
}


// component
export default function Map(props) {
    const [position, setPosition] = useState(null);
    const [destination, setDestination] = useState(null);

    const [zoomCenter, setZoomCenter] = useState({
        ...companyCoords
    });

    const [polylinePath, setPolylinePath] = useState(null);

    const [walking, setWalking] = useState(null);
    const [driving, setDriving] = useState(null);

    const [isError, setIsError] = useState(false);

    useEffect(() => {
        initGA();
        GAPageView();
    }, []);

    useEffect(() => {
        setDestination(props.destination);

        if( props?.from ) {
            setPosition(props.from);
        }
    }, [props.destination, props.from])

    const getCurrentPositionCallback = (position) => {
        const coords = position?.coords; 
        const { latitude, longitude } = coords;

        // set the current position
        if( props?.current ) {
            setPosition({
                lat: latitude, 
                lng: longitude
            });
        }

        // set center coordinates to zoom in
        if( props?.destination ) {
            setZoomCenter({
                lat: (latitude + props.destination?.lat) / 2,
                lng: (longitude + props.destination?.lng) / 2,
            });
        }
    }

    // Correct the zoom if the correct zoom coordinations object is not yet set
    useEffect(() => {
        if( props?.destination ) {
            setZoomCenter({
                lat: (position?.lat + props.destination?.lat) / 2,
                lng: (position?.lng + props.destination?.lng) / 2,
            });
        }
    }, [position, props.destination]);


    const initCurrentPosition = () => {
        if ("geolocation" in navigator) {
            if( navigator.permissions && navigator.permissions?.query ) {
                navigator.permissions
                    .query({ name: "geolocation" })
                    .then((result) => {
                        if (["granted", "prompt"].includes(result.state)) {
                            navigator.geolocation.getCurrentPosition(
                                getCurrentPositionCallback
                            );
                        }
                    });
            } else {
                if (! navigator.geolocation.getCurrentPosition(
                    getCurrentPositionCallback
                )) {
                    setIsError(true);
                }
            }
        }
    };

    const setPolyline = (response) => {
        const coords = response?.routes?.[0].overview_path;

        if(coords) {
            setPolylinePath(coords);
        }
    }


    const setDurationDetails = (result, type = 'driving') => {
        const duration = result?.rows?.[0].elements?.[0].duration?.text;
        const distance = result?.rows?.[0].elements?.[0].distance?.text;

        switch(type) {
            case 'driving': {
                setDriving({
                    duration, distance
                });
                break;
            }
            case 'walking': {
                setWalking({
                    duration, distance
                });
                break;
            }
            default: 
                break;
        }
    }


    const getQueryParamsToNavigation = () => {
        return "https://www.waze.com/sk/livemap/directions?navigate=yes&from=" +
                        position?.lat +
                        "," +
                        position?.lng +
                        "&to=" +
                        destination?.lat +
                        "," +
                        destination?.lng +
                        ""
    }



    return (
        <div className="section text-center map">
            <h2 style={{ textAlign: "center" }}>
                ADRESA
            </h2>

            <LoadScript
                googleMapsApiKey={googleMapsAPIKEY}
                libraries={libraries}
                preventGoogleFontsLoading={false}
                loadingElement={<Loading />}
            >
                <GoogleMap
                    mapContainerStyle={mapStyles}
                    zoom={10}
                    center={zoomCenter || destination}
                    options={defaultOptions}
                    onLoad={props.current && initCurrentPosition}
                >
                    <Marker
                        //icon={marker}
                        position={destination}
                        draggable={false}
                        title="Cieľ"
                    />

                    {
                        (position) && (
                            <>
                                {
                                    (!polylinePath && destination) && (
                                        <DirectionsService
                                            options={{
                                                destination: destination,
                                                origin: position || destination,
                                                travelMode: "DRIVING",
                                            }}
                                            callback={(response) => setPolyline(response)}
                                        />
                                    )
                                }

                                
                                {
                                    (polylinePath && props?.path) && (
                                        <Polyline 
                                            path={polylinePath}
                                            geodesic={false}
                                            options={lineStyles}
                                        />
                                    )
                                }



                                {
                                    ((position && destination) && !driving && !walking) && (
                                        <> 
                                            <DistanceMatrixService
                                                options={{
                                                    destinations: [destination],
                                                    origins: [position],
                                                    travelMode: "DRIVING",
                                                }}
                                                callback={(response) => setDurationDetails(response)}
                                             />

                                             <DistanceMatrixService
                                                options={{
                                                    destinations: [destination],
                                                    origins: [position],
                                                    travelMode: "WALKING",
                                                }}
                                                callback={(response) => setDurationDetails(response, 'walking')}
                                             />
                                        </>
                                    )
                                }



                                {
                                    position && (
                                        <Marker
                                            icon={marker}
                                            position={position}
                                            draggable={false}
                                            title="Tvoja poloha"
                                        />
                                    )
                                }
                            </>
                        )
                    }

                </GoogleMap>
            </LoadScript>
            
            {
                position && (
                    <div className="details">
                        <Grid>
                            <Grid.Row columns={2} centered>
                                <Grid.Column mobile={15} tablet={15} computer={props?.navigation ? 7 : 15}>
                                    <div className="detail">
                                        <span className="distance">
                                            {walking?.distance || '0 km'} od Vás
                                        </span>
                                        <span className="walk">
                                            <Icon name='male' size='large' />
                                            {walking?.duration || '0 mins'}
                                        </span>
                                        <span className="walk">
                                            <Icon name='car' size='large' />
                                            {driving?.duration || '0 mins'}
                                        </span>
                                    </div>
                                </Grid.Column>
                                {
                                    props?.navigation && (
                                        <Grid.Column mobile={15} tablet={15} computer={7}>
                                            <a href={(position) ? getQueryParamsToNavigation() : '#'} rel="noopener noreferrer" target='_blank' className="navigate">
                                                <span>
                                                    Navigovať
                                                </span>
                                            </a>
                                        </Grid.Column>
                                    )
                                }
                            </Grid.Row>
                        </Grid>
                    </div>
                )
            }

            {
                isError && (
                     <Grid style={{marginTop: '1rem'}}>
                         <Grid.Row centered>
                             <Grid.Column mobile={15} tablet={15} computer={15}>
                                <Message
                                    dismissible={false}
                                    hidden={!isError}
                                    visible={isError}
                                    content="Mapu sa nepodarilo správne načítať."
                                />
                            </Grid.Column>
                         </Grid.Row>
                     </Grid>
                )
            }
        </div>
    )
}