// react
import React from "react";
// components
import { Button } from "semantic-ui-react";

// component
function ButtonGroupSearch({
    form,
    resetOnClick,
    resetDisabled,
    submitDisabled,
}) {
    // template
    return (
        <Button.Group
            floated="left"
            style={{
                marginBottom: "0.5rem",
                marginTop: "0.5rem",
            }}
        >
            <Button
                type="submit"
                color="pink"
                form={form}
                disabled={submitDisabled}
            >
                Vyhľadaj
            </Button>
            <Button
                type="button"
                color="pink"
                inverted
                onClick={resetOnClick}
                disabled={resetDisabled}
            >
                Zrušiť Filter
            </Button>
        </Button.Group>
    );
}

export default ButtonGroupSearch;
