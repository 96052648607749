import React, { useState, useEffect } from "react";
import { Link } from 'react-router-dom'
// components
import { Modal, Button, Icon, Header, Form, TextArea, Message } from "semantic-ui-react";
// store
import { isEmptyErrorList } from "../../store/services/helpers/validation";
import { commonService } from "../../store/services/ServiceCommon";
// others
//import Autocomplete from "react-google-autocomplete";
import { loadReCaptcha, ReCaptcha } from 'react-recaptcha-v3'


const SITE_KEY = "6LcE5ggbAAAAALYSdlwRjfN07-BGZQ7MFA4AA68b"


export default function APSolidarityForm({ opened, setOpenedFunc }) {
    const [checked, setChecked] = useState(false);
    const [processing, setProcessing] = useState(false);
    // eslint-disable-next-line
    const [positive, setPositive] = useState(false);
    const [isSent, setIsSent] = useState(false);

    const initFormData = {
        name: "",
        surname: "",
        function: "",
        phone: "",
        email: "",
        town: "",
        message: "",
    };

    const [formData, setFormData] = useState(initFormData);
    // eslint-disable-next-line
    const [captchaToken, setCaptchaToken] = useState('');


    const handleSubmit = async (event) => {
        setIsSent(false);
        event.preventDefault();
        setProcessing(true);
        
        // pripravene data na poslanie, ulahcim pracu :-D
        // eslint-disable-next-line
        const formDataToSend = {
            ...formData,
            //recaptchaToken: captchaToken
        }
        
        const request = await commonService.sendTownRegisterEmail(formDataToSend)
        
        if( request.status === 200 ){
            setPositive(true);
            setIsSent(true);
            setFormData(initFormData);
        } else {
            setPositive(false);
            setIsSent(true);
        }

        setProcessing(false);
    }


    useEffect(() => {
        loadReCaptcha(SITE_KEY);
    }, [])

    const verifyCallback = (token) => {
        setCaptchaToken(token);
    }


    return (
        <Modal
            closeIcon
            open={opened}
            onClose={() => setOpenedFunc(false)}
            onOpen={() => setOpenedFunc(true)}
            size="tiny"
            style={{ borderRadius: 0}}
        >
            
            <Header content="Registrovať obec/mesto" />

            <Modal.Content>
                <Form onSubmit={(e) => handleSubmit(e)}>
                    <Form.Group widths="equal">
                        <Form.Input
                            fluid
                            required
                            label="Meno"
                            value={formData.name}
                            onChange={(e) =>
                                setFormData({
                                    ...formData,
                                    name: e.target.value,
                                })
                            }
                        />
                        <Form.Input
                            fluid
                            required
                            label="Priezvisko"
                            value={formData.surname}
                            onChange={(e) =>
                                setFormData({
                                    ...formData,
                                    surname: e.target.value,
                                })
                            }
                        />
                    </Form.Group>

                    <Form.Group widths="equal">
                        {/*<div className="required field">
                            <label>Obec</label>
                            <div className="ui fluid input">
                                <Autocomplete
                                    apiKey="AIzaSyDeMwYICfoMpEB7VQRphfQiAAXt0HwiDCg"
                                    autoComplete="off"
                                    placeholder=""
                                    componentRestrictions={{ country: ["sk"] }}

                                    onPlaceSelected={(place) => {
                                        setFormData({
                                            ...formData,
                                            town: place.address_components?.[0]?.long_name || place.formatted_address,
                                        })
                                    }}
                                />
                            </div>
                        </div>*/}
                    
                        <Form.Input
                            fluid
                            required
                            label="Obec/Mesto"
                            value={formData.town}
                            onChange={(e) =>
                                setFormData({
                                    ...formData,
                                    town: e.target.value,
                                })
                            }
                        />

                        <Form.Input
                            fluid
                            required
                            label="Funkcia/pozícia"
                            value={formData.function}
                            onChange={(e) =>
                                setFormData({
                                    ...formData,
                                    function: e.target.value,
                                })
                            }
                        />
                    </Form.Group>

                    <Form.Group widths="equal">
                        <Form.Input
                            fluid
                            required
                            label="Email"
                            type="email"
                            value={formData.email}
                            onChange={(e) =>
                                setFormData({
                                    ...formData,
                                    email: e.target.value,
                                })
                            }
                        />
                         <Form.Input
                            fluid
                            required
                            label="Telefónne číslo"
                            value={formData.phone}
                            onChange={(e) =>
                                setFormData({
                                    ...formData,
                                    phone: e.target.value,
                                })
                            }
                        />
                    </Form.Group>
                    
                    <Form.Group widths="equal">
                        <Form.Field
                            control={TextArea}
                            label="Správa"
                            rows="3"
                            value={formData.message}
                            onChange={(e) =>
                                setFormData({
                                    ...formData,
                                    message: e.target.value,
                                })
                            }
                        />
                    </Form.Group>

                    <Form.Checkbox
                        checked={checked}
                        onChange={() => setChecked(!checked)}
                        label={{
                            children: (
                                <p>
                                    {`Súhlasím a bol som oboznámený so `}
                                    <Link
                                        to="/privacy"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        spracovaním osobných údajov
                                    </Link>
                                    .
                                </p>
                            ),
                        }}
                    />

                    <Message
                        onDismiss={() => setIsSent(false)}
                        positive={positive && isSent}
                        negative={!positive && isSent}
                        hidden={!isSent}
                        visible={isSent}
                        content={
                            <>
                                <h2>
                                    { positive && isSent
                                        ? "Správa bola úspešne odoslaná."
                                        : !positive && isSent
                                        ? "Chyba pri odosielaní správy."
                                        : ""
                                    }
                                </h2>
                                { positive && isSent 
                                    ? <p>Ďakujeme veľmi pekne za nezáväznú registráciu. <br/>Čoskoro Vás budeme kontaktovať.</p> 
                                    : !positive && isSent
                                    ? <p>
                                        <strong>Potencionálny problém:</strong> <br/>
                                        - neboli vyplnené všetky povinné údaje<br/>
                                        - zadaná emailová adresa nie je platná 
                                        </p>
                                    : ""
                                }

                                
                            </>
                        }
                    />

                    <Button 
                        fluid
                        color="green"
                        type="submit"
                        size="large"
                        loading={processing}
                        disabled={
                            !isEmptyErrorList([
                                formData.name,
                                formData.email,
                                formData.surname,
                                formData.function,
                                formData.city,
                                formData.phone,
                            ]) ||
                            !checked ||
                            processing
                        }
                    >
                        <Icon name="checkmark" /> Odoslať žiadosť
                    </Button>



                    <ReCaptcha
                        sitekey={SITE_KEY}
                        action='verify'
                        verifyCallback={verifyCallback}
                    />
                </Form>
            </Modal.Content>
        </Modal>
    );
}
