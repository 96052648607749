// react
import React, { Fragment, useState } from "react";
// store
import { planningService } from "../../../../store/services/ServicePlanning";
import { LOADING_TIME } from "../../../../store/config";
import { isEmpty } from "../../../../store/services/helpers/validation";
// components
import { Button, Form, Grid, Modal } from "semantic-ui-react";
// others
import moment from "moment";

moment.locale("sk");

// component
function PlanningDeleteDayForm({ deleteDate, newMessage }) {
    // state
    const [isProcessing, setIsProcessing] = useState(false);
    const [deleteReason, setDeleteReason] = useState("");

    /** component handlers **/
    const handleOnChangeDeleteReason = (e, data) => {
        setDeleteReason(data.value);
    };

    // submit form
    const handleSubmit = async (event) => {
        event.preventDefault();

        // dataset
        const data = {
            reason: deleteReason,
            date: moment(deleteDate).format("YYYY-MM-DD"),
        };

        setIsProcessing(true);

        if (await planningService.cancelDayEvents(data)) {
            setTimeout(function () {
                setIsProcessing(false);
                newMessage("Udalosti dňa boli úspešne zrušené!");
            }, LOADING_TIME);
        } else {
            setIsProcessing(false);
            newMessage("Zrušenie udalostí prebehlo neúspešne!");
        }
    };

    // template
    return (
        <Fragment>
            <Modal.Content>
                <Grid columns={1} divided centered>
                    <Grid.Row>
                        <Grid.Column width={16}>
                            <Form id="cancel-day-form" onSubmit={handleSubmit}>
                                <Form.TextArea
                                    required
                                    label="Dôvod zrušenia"
                                    placeholder="Dôvod zrušenia udalostí ..."
                                    value={deleteReason}
                                    onChange={handleOnChangeDeleteReason}
                                />
                            </Form>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Modal.Content>

            <Modal.Actions>
                <Button
                    color="pink"
                    content="Zrušiť udalosti dňa"
                    loading={isProcessing}
                    disabled={isProcessing || isEmpty(deleteReason)}
                    type="submit"
                    form="cancel-day-form"
                />
            </Modal.Actions>
        </Fragment>
    );
}

export default PlanningDeleteDayForm;
