// react
import React from "react";
import { Switch, Route } from "react-router-dom";
// store
import { authService } from "../../../../store/services/ServiceAuth";
import { routes } from "../../../../store/routes";
// components
import AddressesList from "./AddressesList";
import AddressAdd from "./AddressAdd";
import AddressEdit from "./AddressEdit";

// component
function Addresses() {
    authService.useIsProvider();

    // template
    return (
        <Switch>
            <Route exact path={routes.ADDRESSES} component={AddressesList} />
            <Route exact path={routes.ADDRESSES_ADD} component={AddressAdd} />
            <Route
                exact
                path={routes.ADDRESSES_EDIT + ":id"}
                component={AddressEdit}
            />
        </Switch>
    );
}

export default Addresses;
