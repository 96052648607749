// react
import React, { useState, useEffect, Fragment } from "react";
import { withRouter } from "react-router";
// store
import { addressService } from "../../../../store/services/ServiceAddress";
import { LOADING_TIME } from "../../../../store/config";
import { routes } from "../../../../store/routes";
// components
import ButtonSubmit from "../../../../components/buttons/ButtonSubmit";
import ModalConfirm from "../../../../components/modals/ModalConfirm";
import ModalResponse from "../../../../components/modals/ModalResponse";
import { Form } from "semantic-ui-react";

// component
function AddressForm(props) {
    // state
    const [data, setState] = useState(props.data);
    const [addressId] = useState(props.match ? props.match.params.id : "");
    const [isProcessing, setIsProcessing] = useState(false);
    const [isDeleteConfirmed, setIsDeleteConfirmed] = useState(false);
    const [errorOpen, setErrorOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState("Error message");

    useEffect(() => {
        setState(props.data);
    }, [props.data]);

    const handleErrorOpen = () => {
        setErrorOpen(true);
    };

    const handleErrorClose = () => {
        setErrorOpen(false);
        setIsProcessing(false);
    };

    const deleteAddress = () => {
        setIsDeleteConfirmed(true);
    };

    const handleCancel = () => {
        setIsDeleteConfirmed(false);
    };

    const handleSubmit = async (e) => {
        setIsProcessing(true);
        e.preventDefault();

        if (
            data.name !== "" &&
            data.street !== "" &&
            data.street_nr !== "" &&
            data.city !== "" &&
            data.zip_code !== "" &&
            data.position.lat !== "" &&
            data.position.lng !== ""
        ) {
            // build object and perform save
            let validatedData = {
                name: data.name,
                street: data.street,
                street_nr: data.street_nr,
                city: data.city,
                zip_code: data.zip_code,
                latitude: data.position.lat,
                longitude: data.position.lng,
            };

            // send validateData to backend Service
            if (props.isEdit) {
                // Update

                let tutorialAddressID = null;
                if (
                    props.tutorial !== false &&
                    props.tutorial.addressID !== null
                ) {
                    tutorialAddressID = props.tutorial.addressID;
                }

                if (!addressId && tutorialAddressID === null) {
                    setErrorMessage("Nepodarilo sa aktualizovať adresu.");
                    handleErrorOpen();
                }

                // Perform update action
                if (
                    await addressService.editAddress(
                        validatedData,
                        tutorialAddressID !== null
                            ? tutorialAddressID
                            : addressId
                    )
                ) {
                    setTimeout(function () {
                        setIsProcessing(false);
                        if (props.tutorial === false) {
                            props.history.push(routes.ADDRESSES);
                        } else {
                            props.tutorial.nextTutorial();
                        }
                    }, LOADING_TIME);
                } else {
                    setIsProcessing(false);
                    setErrorMessage("Počas ukladania dát došlo k chybe!");
                    handleErrorOpen();
                }
            } else {
                // Create
                if (await addressService.saveAddress(validatedData)) {
                    setTimeout(function () {
                        setIsProcessing(false);
                        if (props.tutorial === false) {
                            props.history.push(routes.ADDRESSES);
                        } else {
                            props.tutorial.nextTutorial();
                        }
                    }, LOADING_TIME);
                } else {
                    setIsProcessing(false);
                    setErrorMessage("Počas ukladania dát došlo k chybe!");
                    handleErrorOpen();
                }
            }
        } else {
            setErrorMessage(
                "Niektoré povinné údaje neboli správne doplnené. Upresnite adresu presunutím značky na miesto konania aktivity."
            );
            handleErrorOpen();
        }
    };

    const handleConfirm = async () => {
        setIsDeleteConfirmed(true);
        setIsProcessing(true);
        if (await addressService.deleteAddress(addressId)) {
            setTimeout(function () {
                setIsProcessing(false);
                props.history.push(routes.ADDRESSES);
            }, LOADING_TIME);
        } else {
            setIsDeleteConfirmed(false);
            setIsProcessing(false);
            setErrorMessage(
                "Nepodarilo sa odstrániť adresu. Adresa je aktívne priradená v ponúkaných aktivitách."
            );
            handleErrorOpen();
        }
    };

    // template
    return (
        <Fragment>
            <Form float="right" onSubmit={handleSubmit}>
                <Form.Group widths="equal">
                    <Form.Input
                        value={data.name}
                        onChange={ e => {
                            const value = e.target.value;
                            setState(prevState => {
                                return { ...prevState, name: value }
                            })
                        }}
                        name="name"
                        fluid
                        type="text"
                        placeholder="Názov lokality"
                        label="Názov lokality"
                    />
                </Form.Group>
                <Form.Group widths="equal">
                    <Form.Input
                        value={data.street}
                        onChange={ e => {
                            const value = e.target.value;
                            setState(prevState => {
                                return { ...prevState, street: value }
                            })
                        }}
                        width="12"
                        fluid
                        placeholder="Ulica"
                        label="Ulica"
                    />
                    <Form.Input
                        value={data.street_nr}
                        onChange={ e => {
                            const value = e.target.value;
                            setState(prevState => {
                                return { ...prevState, street_nr: value }
                            })
                        }}
                        width="4"
                        fluid
                        placeholder="Popisné číslo"
                        label="Popisné číslo"
                    />
                </Form.Group>

                <Form.Group widths="equal">
                    <Form.Input
                        value={data.city}
                        onChange={ e => {
                            const value = e.target.value;
                            setState(prevState => {
                                return { ...prevState, city: value }
                            })
                        }}
                        width="12"
                        fluid
                        placeholder="Mesto"
                        label="Mesto"
                    />
                    <Form.Input
                        value={data.zip_code}
                        onChange={ e => {
                            const value = e.target.value;
                            setState(prevState => {
                                return { ...prevState, zip_code: value }
                            })
                        }}
                        width="4"
                        fluid
                        placeholder="PSČ"
                        label="PSČ"
                    />
                </Form.Group>

                <Form.Group widths="equal">
                    <Form.Input
                        value={data.position.lat}
                        onChange={ (e, { value }) => (
                            setState({
                                ...data, 
                                position: {
                                    ...data.position,
                                    lat: value?.replace(",", ".") || ""  
                                }
                            })
                        )}
                        fluid
                        placeholder="Zemepisná šírka "
                        label="Zemepisná šírka "
                    />
                    <Form.Input
                        value={data.position.lng}
                        onChange={ (e, { value }) => (
                            setState({
                                ...data, 
                                position: {
                                    ...data.position,
                                    lng: value?.replace(",", ".") || "" 
                                }
                            })
                        )}
                        fluid
                        placeholder="Zemepisná dĺžka"
                        label="Zemepisná dĺžka"
                    />
                </Form.Group>

                <Form.Group>
                    {props.tutorial === false ? (
                        <>
                            <ButtonSubmit
                                loading={isProcessing}
                                disabled={isProcessing}
                                text={
                                    props.isEdit
                                        ? "Upraviť adresu"
                                        : "Uložiť adresu"
                                }
                            />
                            {props.isEdit ? (
                                <Form.Button
                                    loading={isProcessing}
                                    disabled={isProcessing}
                                    type="button"
                                    onClick={deleteAddress}
                                    size="big"
                                    color="red"
                                    style={{ marginTop: "1rem" }}
                                >
                                    Zmazať adresu
                                </Form.Button>
                            ) : (
                                ""
                            )}
                        </>
                    ) : (
                        <>
                            <Form.Button
                                type="button"
                                onClick={props.tutorial.backTutorial}
                                size="large"
                                color="grey"
                            >
                                Späť
                            </Form.Button>
                            <Form.Button
                                loading={isProcessing}
                                disabled={isProcessing}
                                size="large"
                                color="pink"
                            >
                                {props.isEdit
                                    ? "Upraviť adresu a pokračovať"
                                    : "Uložiť adresu a pokračovať"}
                            </Form.Button>
                        </>
                    )}
                </Form.Group>

                <ModalConfirm
                    open={isDeleteConfirmed}
                    header="Vymazanie adresy"
                    content="Si si istý(á), že chceš vymazať túto adresu?"
                    confirmButton="Áno"
                    cancelButton="Nie"
                    onCancel={handleCancel}
                    onConfirm={handleConfirm}
                />
            </Form>

            <ModalResponse
                open={errorOpen}
                onClose={handleErrorClose}
                icon="warning circle"
                header="Chyba"
                message={errorMessage}
                onClick={handleErrorClose}
            />
        </Fragment>
    );
}

export default withRouter(AddressForm);
