import React, { useState } from 'react';
import { Modal } from 'semantic-ui-react';



const SuperModal = ({ trigger, content, size, header, ...rest }) => {
    // trigger = {node}
    // content = {elementType}

    // **** optional
    // size = {enum} mini, tiny, small, large, fullscreen... TINY by default
    // header = {string} OPTIONAL

    const [open, setOpen] = useState(false)

    return (
        <Modal
            {...rest}
            onClose={() => setOpen(false)}
            onOpen={() => setOpen(true)}
            open={open}
            trigger={trigger}
            size={size || "tiny"}
        >
            {header && <Modal.Header>{header}</Modal.Header>}
            <Modal.Content>
                { React.cloneElement(content, {onClose: () => setOpen(false)}) }
            </Modal.Content>

        </Modal>
    )
};

export default SuperModal;