import request from "umi-request";
import { API } from "../config";

// services
import { authService } from "./ServiceAuth";

/*
 * Service functions
 */
export const countryService = {
    getCountries,
    updateCountry,
};

async function getCountries() {
    let data;
    data = await request(API.GET_COUNTRIES, {
        method: "get",
    })
        .then(function (response) {
            return response;
        })
        .catch(function (error) {
            return [];
        });

    return data;
}

async function updateCountry(data) {
    let updated;
    let accessToken = await authService.getAccessToken();
    updated = await request(API.EDIT_COUNTRY, {
        method: "put",
        data: data,
        headers: {
            Authorization: `Bearer ${accessToken}`,
        },
    })
        .then(function (response) {
            return true;
        })
        .catch(function (error) {
            return false;
        });

    return updated;
}
