// react
import React from 'react'

// components 
import { Icon, Grid } from "semantic-ui-react";

// component
export default function InfoBox(props) {
    return (
        <Grid.Column mobile={14} tablet={14} computer={props?.size || 6}>
            <div className="box">
                {props.children}

                {
                    !props.disableIcons && (
                        <div>
                            <Icon name='globe' size='big' onClick={() => window.open('https://activitypoint.xyz', '_blank')} />
                            <Icon name='facebook f' size='big' onClick={() => window.open('https://www.facebook.com/ActivityPoint.xyz/', '_blank')} />
                            <Icon name='youtube' size='big' onClick={() => window.open('https://www.youtube.com/channel/UCzfBevKgYpFL5SlH3ajROXQ?view_as=subscriber', '_blank')} />
                            <Icon name='instagram' size='big' onClick={() => window.open('https://www.instagram.com/activitypoint/', '_blank')} />
                        </div>
                    )
                }
            </div>
        </Grid.Column>
    )
}
