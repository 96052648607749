// react
import React, { Fragment, useEffect, useState } from "react";
// store
import { companyService } from "../../../../store/services/ServiceCompany";
import { isEmptyErrorList } from "../../../../store/services/helpers/validation";
// components
import ButtonSubmit from "../../../../components/buttons/ButtonSubmit";
import { Form, Button } from "semantic-ui-react";

// component
const BankForm = ({ id, setBank, onClose }) => {
    // state
    const [loading, setLoading] = useState(id ? true : false);
    const [isProcessing, setIsProcessing] = useState(false);

    // form attributes
    const [name, setName] = useState("");
    // SK00 1111 1111 1111 0000 0000 (2 char + 22 int) - TODO: validation
    const [iban, setIban] = useState("");
    const [swift, setSwift] = useState("");
    const [alias, setAlias] = useState("");
    const [bankDetail, setBankDetail] = useState("");

    // fetch data
    useEffect(() => {
        const fetchData = async () => {
            if (id) {
                setLoading(true);

                // call backend API to get bank by ID
                const result = await companyService.getBankAccountByID(id);
                // set bank data to form on success
                if (result.status === 200) {
                    setName(result.response.name);
                    setIban(result.response.iban);
                    setSwift(result.response.swift_code);
                    setAlias(result.response.alias);
                    setBankDetail(result.response.bank_detail);
                }

                setLoading(false);
            }
        };

        fetchData();
    }, [id]);

    const handleSubmit = async () => {
        setIsProcessing(true);
        const data = {
            name: name,
            iban: iban,
            swift_code: swift,
            alias: alias,
            bank_detail: bankDetail,
            default: true,
        };

        if (id) {
            // call api to update bank account
            const result = await companyService.updateBankAccount(data, id);
            if (result.status === 200) {
                onClose();
                setBank(result.response);
            }
        } else {
            // call api to create bank account
            const result = await companyService.createBankAccount(data);
            if (result.status === 201) {
                onClose();
                setBank(result.response);
            }
        }

        setIsProcessing(false);
    };

    // template
    return (
        <Fragment>
            <Form loading={loading} onSubmit={handleSubmit}>
                <Form.Field widths="equal">
                    <Form.Group>
                        <Form.Input
                            autoFocus
                            required
                            width={8}
                            label="Názov účtu"
                            placeholder="Názov účtu"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                        />
                        <Form.Input
                            required
                            width={8}
                            label="Swift"
                            placeholder="Swift"
                            value={swift}
                            onChange={(e) => setSwift(e.target.value)}
                        />
                    </Form.Group>
                </Form.Field>
                <Form.Field widths="equal">
                    <Form.Input
                        required
                        width={16}
                        label="IBAN účtu"
                        placeholder="IBAN účtu"
                        value={iban}
                        onChange={(e) => setIban(e.target.value)}
                    />
                </Form.Field>
                <Form.Field widths="equal">
                    <Form.Group>
                        <Form.Input
                            width={16}
                            required
                            label="Názov banky"
                            placeholder="Názov banky"
                            value={bankDetail}
                            onChange={(e) => setBankDetail(e.target.value)}
                        />
                        <Form.Input
                            className="hide"
                            width={8}
                            label="Alias banky"
                            placeholder="Alias banky"
                            value={alias}
                            onChange={(e) => setAlias(e.target.value)}
                        />
                    </Form.Group>
                </Form.Field>

                <ButtonSubmit
                    loading={isProcessing}
                    disabled={
                        isProcessing ||
                        !isEmptyErrorList([name, iban, swift, bankDetail])
                    }
                    text="Uložiť"
                />
                <Button size="big" onClick={() => onClose()}>
                    Zrušiť
                </Button>
            </Form>
        </Fragment>
    );
};

export default BankForm;
