// react
import React, { useState, Fragment } from "react";
import { withRouter } from "react-router";
// store
import { bundleService } from "../../../../store/services/ServiceBundle";
import {
    providerOptions,
    durationOptions,
    bundleOptions,
} from "../../../../store/services/helpers/settings";
import { LOADING_TIME } from "../../../../store/config";
import {
    isEmpty,
    isNumberError,
    isDateError,
    isEmptyErrorList,
} from "../../../../store/services/helpers/validation";
import { routes } from "../../../../store/routes";
// components
import { DateInput } from "semantic-ui-calendar-react";
import ButtonDelete from "../../../../components/buttons/ButtonDelete";
import ButtonSubmit from "../../../../components/buttons/ButtonSubmit";
import ModalConfirm from "../../../../components/modals/ModalConfirm";
import ModalResponse from "../../../../components/modals/ModalResponse";
import { Form, Divider, Header, Checkbox } from "semantic-ui-react";

// component
function BundleForm({ data, history }) {
    // state
    const [isEditForm] = useState(data.id ? true : false);
    const [isDeleteConfirmed, setIsDeleteConfirmed] = useState(false);
    const [isProcessing, setIsProcessing] = useState(false);
    const [bundleId] = useState(data.id ? data.id : null);
    const [errorOpen, setErrorOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState("Error message");

    // form attributes
    const [name, setName] = useState(data.name || "");
    const [providerType, setProviderType] = useState(
        data.providerType ? data.providerType.toString() : ""
    );
    const [duration, setDuration] = useState(
        data.duration ? data.duration : "0"
    );
    const [durationType, setDurationType] = useState(
        data.durationType ? data.durationType.toString() : "1"
    );
    const [price, setPrice] = useState(data.price ? data.price : "0");
    const [activityCount, setActivityCount] = useState(
        data.activityCount ? data.activityCount : "1"
    );
    const [bundleType, setBundleType] = useState(
        data.bundleType ? data.bundleType.toString() : "1"
    );
    const [percentage, setPercentage] = useState(
        data.percentage ? data.percentage : ""
    );
    const [active_from, setActiveFrom] = useState(
        data.active_from ? data.active_from.toString() : ""
    );
    const [status, setStatus] = useState(isEditForm ? data.status : true);

    /** component functions **/

    const handleSubmit = async (event) => {
        event.preventDefault();
        const data = {
            name: name,
            duration: duration,
            activityCount: activityCount,
            price: price,
            bundleType: parseInt(bundleType),
            percentage: percentage ? parseInt(percentage) : null,
            providerType: parseInt(providerType),
            durationType: parseInt(durationType),
            status: status,
            active_from: active_from,
        };

        setIsProcessing(true);

        let formError = false;
        // validate form fields
        if (
            isNumberError(duration) ||
            isNumberError(activityCount) ||
            (!isEmpty(percentage) && isNumberError(percentage)) ||
            isDateError(active_from)
        ) {
            formError = true;
        }

        if (!formError) {
            if (isEditForm && bundleId !== null) {
                if (await bundleService.editBundle(data, bundleId)) {
                    setTimeout(function () {
                        setIsProcessing(false);
                        history.push(routes.BUNDLES);
                    }, LOADING_TIME);
                } else {
                    setIsProcessing(false);
                    setErrorMessage("Počas aktualizácie dát došlo k chybe!");
                    handleErrorOpen();
                }
            } else {
                if (await bundleService.saveBundle(data)) {
                    setTimeout(function () {
                        setIsProcessing(false);
                        history.push(routes.BUNDLES);
                    }, LOADING_TIME);
                } else {
                    setIsProcessing(false);
                    setErrorMessage("Počas ukladania dát došlo k chybe!");
                    handleErrorOpen();
                }
            }
        }

        setIsProcessing(false);
    };

    const handleOnChangeActiveFrom = (e, data) => {
        setActiveFrom(data.value);
    };

    const handleCheckBox = (e, data) => {
        setStatus(data.checked);
    };

    const deleteBundle = () => {
        setIsDeleteConfirmed(true);
    };

    const handleCancel = () => {
        setIsDeleteConfirmed(false);
    };

    const handleConfirm = async () => {
        setIsDeleteConfirmed(true);
        setIsProcessing(true);
        if (await bundleService.deleteBundle(bundleId)) {
            setTimeout(function () {
                setIsProcessing(false);
                history.push(routes.BUNDLES);
            }, LOADING_TIME);
        } else {
            setIsProcessing(false);
            setIsDeleteConfirmed(false);
            setErrorMessage("Počas vymazávania balíčka došlo k chybe!");
            handleErrorOpen();
        }
    };

    const handleErrorOpen = () => {
        setErrorOpen(true);
    };

    const handleErrorClose = () => {
        setErrorOpen(false);
    };

    // template
    return (
        <Fragment>
            <Form loading={isProcessing} onSubmit={handleSubmit}>
                <Form.Group widths="equal">
                    <Form.Input
                        required
                        autoFocus
                        fluid
                        label="Názov Balíčka"
                        placeholder="Názov Balíčka"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                    />
                    <Form.Select
                        required
                        fluid
                        label="Typ poskytovateľa služieb"
                        options={providerOptions}
                        placeholder="Typ poskytovateľa služieb"
                        value={providerType}
                        onChange={(e) => setProviderType(e.target.value)}
                    />
                </Form.Group>

                <Divider horizontal section>
                    <Header as="h4">Nastavenie balíčka</Header>
                </Divider>

                <Form.Group widths="equal">
                    <Form.Input
                        required
                        fluid
                        label="Platnosť balíčka"
                        placeholder="Platnosť balíčka"
                        value={duration}
                        onChange={(e) => setDuration(e.target.value)}
                        error={
                            !isEmpty(duration) ? isNumberError(duration) : false
                        }
                    />
                    <Form.Select
                        required
                        fluid
                        label="Doba trvania"
                        options={durationOptions}
                        placeholder="Doba trvania"
                        value={durationType}
                        onChange={(e) => setDurationType(e.target.value)}
                    />
                    <Form.Input
                        required
                        fluid
                        type="text"
                        label="Cena za balíček (€)"
                        placeholder="Cena za balíček (€)"
                        value={price}
                        onChange={(e) => setPrice(e.target.value)}
                    />
                    <Form.Input
                        required
                        fluid
                        label="Max. počet aktivít"
                        placeholder="Max. počet aktivít"
                        value={activityCount}
                        onChange={(e) => setActivityCount(e.target.value)}
                        error={
                            !isEmpty(activityCount)
                                ? isNumberError(activityCount)
                                : false
                        }
                    />
                </Form.Group>

                <Form.Group widths="equal">
                    <Form.Select
                        required
                        fluid
                        label="Typ balíčka"
                        options={bundleOptions}
                        placeholder="Typ balíčka"
                        value={bundleType}
                        onChange={(e) => setBundleType(e.target.value)}
                    />
                    <Form.Input
                        fluid
                        label="Výška poplatku (%)"
                        placeholder="Výška poplatku (%)"
                        value={percentage}
                        onChange={(e) => setPercentage(e.target.value)}
                        error={
                            !isEmpty(percentage)
                                ? isNumberError(percentage)
                                : false
                        }
                    />

                    <DateInput
                        required
                        animation="none"
                        placeholder="YYYY-MM-DD"
                        label="Platný od"
                        dateFormat="YYYY-MM-DD"
                        localization="sk"
                        value={active_from}
                        iconPosition="right"
                        closable
                        hideMobileKeyboard
                        onChange={handleOnChangeActiveFrom}
                        error={
                            !isEmpty(active_from)
                                ? isDateError(active_from)
                                : false
                        }
                    />
                </Form.Group>

                <Form.Group>
                    <Checkbox
                        required
                        className="bundle-active"
                        toggle
                        defaultChecked={status}
                        label="Nastaviť balík ako aktívny"
                        onChange={handleCheckBox}
                    />
                </Form.Group>

                <Divider horizontal section />

                <Form.Group>
                    <ButtonSubmit
                        loading={isProcessing}
                        disabled={
                            isProcessing ||
                            !isEmptyErrorList([
                                name,
                                providerType,
                                duration,
                                price,
                                activityCount,
                                bundleType,
                                active_from,
                            ])
                        }
                        text={isEditForm ? "Upraviť" : "Vytvoriť balíček"}
                    />
                    {isEditForm ? (
                        <ButtonDelete
                            loading={isDeleteConfirmed}
                            disabled={isProcessing}
                            onClick={deleteBundle}
                            text="Vymazať"
                        />
                    ) : (
                        ""
                    )}
                </Form.Group>

                <ModalConfirm
                    open={isDeleteConfirmed}
                    header="Vymazanie balíka"
                    content="Si si istý(á), že chceš vymazať tento balík?"
                    confirmButton="Áno"
                    cancelButton="Nie"
                    onCancel={handleCancel}
                    onConfirm={handleConfirm}
                />
            </Form>

            <ModalResponse
                open={errorOpen}
                onClose={handleErrorClose}
                icon="warning circle"
                header="Chyba"
                message={errorMessage}
                onClick={handleErrorClose}
            />
        </Fragment>
    );
}

export default withRouter(BundleForm);
