// react
import React, { useState, useEffect } from "react";
// components
import { Button, Icon, Label, Input, Grid } from "semantic-ui-react";
// others
import moment from "moment";

// component
const BasketItem = ({
    item,
    items,
    setItems,
    removeProductFromBasket,
    removeItemFromView,
}) => {
    const [amount, setAmount] = useState(items.find(basketItem => basketItem.id === item.id )?.amount || 1)

    const removeItem = (item) => {
        removeProductFromBasket(item);

        if (removeItemFromView) {
            removeItemFromView(item);
        }
    };

    const handleAction = (action, custom) => {
        custom = custom || 1

        if( action === "custom" ) {
            if( custom > 0 ) {
                setAmount(custom)
            } else {
                removeItem(item)
            }
        }
        if( action === "increment" ) setAmount(prevState => prevState + 1)
        if( action === "decrement" ) {
            if( (amount - 1) > 0 ) {
                setAmount(prevState => prevState - 1)
            } else {
                removeItem(item)
            }
        }
    }

    useEffect(() => {
        setItems(prevState => prevState.filter(basketItem => {
            if( basketItem.id === item.id ){
                basketItem.amount = amount
            } 

            return basketItem
        }))
        // eslint-disable-next-line
    }, [amount])

    // template
    return (
        <Grid.Row verticalAlign="middle" style={{ borderBottom: "1px solid lightgrey" }} key={item.id}>
            <Grid.Column computer={10} tablet={16} mobile={16}>
                <h3 style={{ marginBottom: "0.2rem" }}>
                    {item.name}{" "}
                    {item.is_discounted && (
                        <Label
                            style={{ marginLeft: "2rem" }}
                            as="a"
                            color="green"
                            tag
                        >
                            {" "}
                            Zľavnený
                        </Label>
                    )}
                </h3>
                <Label.Group>
                    <Label color="black">
                        <Icon name="map marker alternate" />{" "}
                        <span>
                            {item.address}
                        </span>
                    </Label>
                    <Label color="black">
                        <Icon name="clock outline" />{" "}
                        <span>
                            {moment(item.starts).utc().format("DD.MM.YYYY HH:mm")}
                        </span>
                    </Label>
                    <Label color={
                        item.event_type === "future"
                            ? "green"
                            : item.event_type === "solidarity"
                            ? "blue"
                            : "pink"
                        }>
                        {
                            item.event_type === "future"
                            ? "#APfuture"
                            : item.event_type === "solidarity"
                            ? item.is_highlighted ? "#APsolidarity - event" : "#APsolidarity"
                            : "#APbusiness"
                        }
                    </Label>
                </Label.Group>
            </Grid.Column>
            <Grid.Column computer={6} tablet={16} mobile={16} style={{ textAlign: "right" }}>
                <span style={{ fontSize: "1.2rem", fontWeight: "bold", marginRight: "2.5rem" }}>
                    {item.is_discounted && (
                        <>
                            <strike>{parseFloat(item.price * amount).toFixed(2)}</strike>{" "}
                            <span
                                style={{
                                    fontSize: "1rem",
                                    paddingLeft: "1rem",
                                }}
                            >
                                {parseFloat(item.discountPrice * amount).toFixed(2)}{" "}
                                {item.discountPrice_type}
                            </span>
                        </>
                    )}
                    {!item.is_discounted && (
                        <>
                            {parseFloat(item.price * amount).toFixed(2)} {item.price_type}
                        </>
                    )}
                </span>

                <Button disabled={item.event_type !== "solidarity"} size="tiny" primary content={" - "} style={{ fontWeight: "bold", background: "transparent", color: "var(--dark)", fontSize: "1.3rem"}} onClick={ () => handleAction("decrement") }/>
                <Input disabled={item.event_type !== "solidarity"} size="mini" style={{ width: "50px", textAlign: "center", paddingRight: "0.2rem" }} value={amount} onChange={(e, {value}) => handleAction("custom", value)}/>
                <Button disabled={item.event_type !== "solidarity"} size="tiny" primary content={" + "} style={{ fontWeight: "bold", background: "transparent", color: "var(--dark)", fontSize: "1.3rem" }} onClick={ () => handleAction("increment") }/>
                
                <Button
                    onClick={(event) => removeItem(item)}
                    icon="close"
                    style={{
                        marginLeft: "2rem",
                        padding: "0.5rem 0.8rem",
                        background: "transparent",
                        color: "red",
                        fontWeight: "bold",
                    }}
                />
            </Grid.Column>
        </Grid.Row>
    );
};

export default BasketItem;
