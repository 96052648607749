// react
import React, { useEffect } from "react";
// components
import Navbar from "../../components/general/Navbar";
import Footer from "../../components/general/Footer";
import Banner from "../../components/general/Banner";
// sections
import AboutUsInfo from "./AboutUsInfo";
// others
import { GAPageView, initGA } from "../../index";

// component
function AboutUs() {
    // Google analytics
    useEffect(() => {
        initGA();
        GAPageView();
    }, []);

    // template
    return (
        <div className="website">
            <Navbar linkBack="/" />
            <Banner src="/images/public/about/ap-about-us.jpg" position="bottom"/>
            <AboutUsInfo />
            <Footer />
        </div>
    );
}

export default AboutUs;
