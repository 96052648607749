// react
import React, { useState, Fragment, useEffect } from "react";
// store
import {
    isEmpty,
    isEmptyErrorList,
    isPostCodeError,
} from "../../../store/services/helpers/validation";
import { commonService } from "../../../store/services/ServiceCommon";
import { LOADING_TIME } from "../../../store/config";
import { setSelectCountriesOptions } from "../../../store/services/helpers/functions";
import { userProfileService } from "../../../store/services/ServiceUserProfile";
import { addressService } from "../../../store/services/ServiceAddress";
// components
import ButtonSubmit from "../../../components/buttons/ButtonSubmit";
import ModalResponse from "../../../components/modals/ModalResponse";
import { Form, Grid, Header } from "semantic-ui-react";

// component
function ProfileAddress() {
    // state
    const [isLoading, setIsLoading] = useState(false);
    const [isProcessing, setIsProcessing] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);
    const [modalIsError, setModalIsError] = useState(false);
    const [modalMessage, setModalMessage] = useState("");
    const [addressId, setAddressId] = useState(0);
    const [countryList, setCountryList] = useState([]);

    // form attributes
    const [street, setStreet] = useState("");
    const [postCode, setPostCode] = useState("");
    const [city, setCity] = useState("");
    const [country, setCountry] = useState("");

    // fetch data - country select options
    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);
            const result = await commonService.getChoices();
            if (result.status === 200) {
                setCountryList(result.response.countries);
            }

            const dataProfile = await userProfileService.getUserProfile();
            // set address to state
            if (dataProfile[0].address) {
                setAddressId(dataProfile[0].address.id);
                setCountry(dataProfile[0].address.country);
                setCity(dataProfile[0].address.city);
                setStreet(dataProfile[0].address.street);
                setPostCode(dataProfile[0].address.postcode);
            }

            setTimeout(function () {
                setIsLoading(false);
            }, LOADING_TIME);
        };

        fetchData();
    }, []);

    /** component functions **/

    // set country select options
    const countryOptions = setSelectCountriesOptions(countryList);

    const openModal = (message, error) => {
        setModalMessage(message);
        setModalIsError(error);
        setIsProcessing(false);
        handleModalOpen();
    };

    const handleModalOpen = () => {
        setModalOpen(true);
    };

    const handleModalClose = () => {
        setModalOpen(false);
    };

    const handleOnChangeCountry = (e, data) => {
        setCountry(data.value);
    };

    // submit form
    const handleSubmit = async (event) => {
        event.preventDefault();
        setIsProcessing(true);

        let formError = false;

        // validate form fields
        if (isPostCodeError(postCode)) {
            formError = true;
        }

        if (!formError) {
            const dataAddress = {
                address_line: "",
                street: street,
                city: city,
                postcode: postCode,
                country: country,
            };

            // update address
            if (addressId !== 0) {
                let resultAddress = await addressService.updateBaseAddress(
                    addressId,
                    dataAddress
                );

                if (resultAddress.status === 200) {
                    openModal("Aktualizácia adresy prebehla úspešne.", false);
                } else {
                    openModal("Počas aktualizácie adresy došlo k chybe!", true);
                }
            }
        }

        setIsProcessing(false);
    };

    // template
    return (
        <Fragment>
            <Grid>
                <Grid.Column computer={16} tablet={16} mobile={16}>
                    <Header as="h3">FAKTURAČNÁ ADRESA</Header>
                    <Form loading={isLoading} onSubmit={handleSubmit}>
                        <Form.Group>
                            <Form.Select
                                width={8}
                                required
                                size="large"
                                fluid
                                search
                                label="Krajina"
                                placeholder="Krajina"
                                options={countryOptions}
                                value={country}
                                onChange={handleOnChangeCountry}
                            />
                            <Form.Input
                                width={8}
                                required
                                fluid
                                label="Mesto/Obec"
                                placeholder="Mesto/Obec"
                                value={city}
                                onChange={(e) => setCity(e.target.value)}
                            />
                        </Form.Group>
                        <Form.Group>
                            <Form.Input
                                width={8}
                                required
                                fluid
                                label="Ulica a popisné číslo"
                                placeholder="Ulica 1"
                                value={street}
                                onChange={(e) => setStreet(e.target.value)}
                            />
                            <Form.Input
                                width={8}
                                required
                                fluid
                                label="PSČ"
                                placeholder="PSČ"
                                value={postCode}
                                onChange={(e) => setPostCode(e.target.value)}
                                error={
                                    !isEmpty(postCode)
                                        ? isPostCodeError(postCode)
                                        : false
                                }
                            />
                        </Form.Group>

                        <ButtonSubmit
                            loading={isProcessing}
                            disabled={
                                isProcessing ||
                                !isEmptyErrorList([street, city, postCode])
                            }
                            text="Uložiť"
                        />
                    </Form>
                </Grid.Column>
            </Grid>

            <ModalResponse
                open={modalOpen}
                onClose={handleModalClose}
                icon={modalIsError ? "warning circle" : "checkmark"}
                header={
                    modalIsError
                        ? "Aktualizácia neúspešná"
                        : "Aktualizácia úspešná"
                }
                message={modalMessage}
                onClick={handleModalClose}
            />
        </Fragment>
    );
}

export default ProfileAddress;
