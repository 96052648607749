const basketConfigurationReducer = (productList = [], action) => {
    switch (action.type) {
        case "ADD_BASKET_CONFIGURATION":
            return action.productList;
        case "ADD_PRODUCT_TO_BASKET_CONFIGURATION":
            return [...productList, action.product];
        case "REMOVE_PRODUCT_FROM_BASKET_CONFIGURATION":
            return productList.filter(product => product.id !== action.product); // action.product as ID
        case "EMPTY_BAKSET_CONFIGURATION":
            return []
        default:
            return productList;
    }
};

export default basketConfigurationReducer;