import request from "umi-request";
import { API } from "../config";

// services
import { authService } from "./ServiceAuth";

/*
 * Service functions
 */
export const resetPasswordService = {
    requestResetPassword,
    resetPasswordConfirm,
    setNewPassword,
};

async function requestResetPassword(email) {
    let requested;
    requested = await request(API.RESET_PASSWORD, {
        method: "post",
        data: {
            email,
        },
    })
        .then(function (response) {
            return true;
        })
        .catch(function (error) {
            if (error.response) {
                if (error.response.status === 403) {
                    return true;
                }
            } else {
                return false;
            }
        });

    return requested;
}

async function resetPasswordConfirm(data) {
    let reset;

    reset = await request(API.RESET_CONFIRM, {
        method: "post",
        data: data,
    })
        .then(function (response) {
            return true;
        })
        .catch(function (error) {
            if (error.response) {
                if (error.response.status === 403) {
                    return true;
                }
            } else {
                return false;
            }
        });

    return reset;
}

async function setNewPassword(data) {
    let reset;

    let accessToken = await authService.getAccessToken();
    reset = await request(API.SET_PASSWORD, {
        method: "post",
        data: data,
        headers: {
            Authorization: `Bearer ${accessToken}`,
        },
    })
        .then(function (response) {
            return true;
        })
        .catch(function (error) {
            if (error.response) {
                if (error.response.status === 403) {
                    return true;
                }
            } else {
                return false;
            }
        });

    return reset;
}
