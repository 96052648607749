import React, { useState, useEffect } from 'react';
import moment from 'moment';
// store 
import { API } from '../../../../../store/config';
import { commonService } from '../../../../../store/services/ServiceCommon';
// components
import ActivitiesModalView from './ActivitiesModalView';
import SuperModal from '../../../../../components/modals/SuperModal';
import { Table, Segment, Icon } from "semantic-ui-react";

const ProvidersModalView = ({ filters, coach, onClose }) => {

    const [isLoading, setIsLoading] = useState(false)
    // eslint-disable-next-line
    const [stats, setStats] = useState([])

    useEffect( () => {
        async function fetchActivities(){
            setIsLoading(true)
            let params = ""
            let dateFrom = filters.date_from
            let dateTo = filters.date_to

            if( filters.mode === "custom" ){
                dateFrom = moment(moment(dateFrom, "DD.MM.YYYY")).format("YYYY-MM-DD") 
                dateTo = moment(moment(dateTo, "DD.MM.YYYY")).format("YYYY-MM-DD") 
            }

            if( filters.date_from !== "" && filters.date_to !== "" && filters.mode !== "full" ){
                params = "&from=" + dateFrom + "&to=" + dateTo
            }

            const request = await commonService.getStats(API.GET_STATS + "providers/?coach=" + coach + params)
            if( request.status === 200 ){
                setStats(request.response)
            }
            setIsLoading(false)
        }

        fetchActivities()
    }, [filters, coach])

    const displayEarned = (activity) => {
        return parseFloat(parseFloat(activity.profits.earned) - parseFloat(activity.profits.refunded)).toFixed(2)
    }
    return (
        <Segment loading={isLoading} style={{ border: "none", shadow: "none", boxShadow: "none", minHeight: "10rem", background: "transparent", padding: 0 }}>
            { !isLoading && 
                <Table striped unstackable style={{ overflow: "auto" }}>
                    <Table.Header>
                        <Table.Row style={{ fontSize: "0.9rem"}}>
                            <Table.HeaderCell style={{ width: "30%" }}>Poskytovateľ</Table.HeaderCell>
                            <Table.HeaderCell textAlign="left">Počet aktivít</Table.HeaderCell>
                            <Table.HeaderCell textAlign="left">Zisk (EUR)</Table.HeaderCell>
                            <Table.HeaderCell textAlign="left">Publikované / Zrušené</Table.HeaderCell>
                            <Table.HeaderCell textAlign="left">Predané / Reklamované</Table.HeaderCell>
                            <Table.HeaderCell textAlign="left">Miera reklamovanosti</Table.HeaderCell>
                            <Table.HeaderCell textAlign="center">Akcie</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>

                    <Table.Body>
                        { stats.length === 0 && 
                            <Table.Row> 
                                <Table.Cell colSpan={7} style={{ textAlign: "center", fontWeight: "bold" }}>
                                    Neboli najdené žiadne záznamy
                                </Table.Cell>
                            </Table.Row>
                        }

                        {stats.map((provider) => (
                            <Table.Row style={{ fontSize: "0.9rem"}}>
                                <Table.Cell> { provider.profits.name } </Table.Cell>
                                <Table.Cell> { provider.total_activities } </Table.Cell>
                                <Table.Cell> { displayEarned(provider) } </Table.Cell>
                                <Table.Cell> { provider.published_events } / { provider.canceled_events } </Table.Cell>
                                <Table.Cell> { (parseInt(provider.profits.activity_sold) - parseInt(provider.profits.activity_refunded)) } / { provider.profits.activity_refunded } </Table.Cell>
                                <Table.Cell> { provider.profits.complaint_rate + "%" } </Table.Cell>
                                <Table.Cell textAlign="center"> 
                                    <SuperModal
                                        closeIcon
                                        size="large"
                                        trigger={
                                            <Icon style={{ cursor: "pointer" }} name="eye"/> 
                                        }
                                        content={<ActivitiesModalView filters={filters} provider={provider.profits.provider_id}/>}
                                    />
                                </Table.Cell>
                            </Table.Row>
                        ))}
                    </Table.Body>
                </Table>
            }
        </Segment>
    );
};

export default ProvidersModalView;