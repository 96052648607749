import React, { Fragment, useState, useEffect } from 'react';
import moment from 'moment';
// store
import { authService } from "../../../../store/services/ServiceAuth";
import { paymentService } from "../../../../store/services/ServicePayment";
import { commonService } from "../../../../store/services/ServiceCommon";
// components
import HeaderTitle from "../../../../components/others/HeaderTitle";
import Loading from "../../../../components/others/Loading";
import { Form, Button, Grid, Card, Divider } from "semantic-ui-react";
import { DateInput } from "semantic-ui-calendar-react";
import { FlexRow, FlexItem } from '../../../../components/tables/FlexTable'
import ProviderProfitsTable from './ProviderProfitsTable';

const Profits = () => {
    authService.useIsAdmin();

    const [isLoading, setIsLoading] = useState(true)
    const [filters, setFilters] = useState({
        from: moment().startOf('month').format("DD.MM.YYYY"),
        to: moment().endOf('month').format("DD.MM.YYYY"),
        type: "",
        providers: [],
    })
    const [profits, setProfits] = useState({})
    // eslint-disable-next-line
    const [providerOptions, setProviderOptions] = useState([])

    async function fetchProfits(){
        setIsLoading(true);

        let providerString = ""
        if( filters.providers.length > 0 ){
            providerString = filters.providers.join();
        }

        let params = "?providers=" + providerString;

        if(filters.from) params += `&from=${moment(filters.from, "DD.MM.YYYY").format("YYYY-MM-DD")}`
        if(filters.to) params += `&to=${moment(filters.to, "DD.MM.YYYY").format("YYYY-MM-DD")}`

        const request = await paymentService.getProfits(params)

        if(request.status === 200){
            const responseProviders = request.response.providers
            let filteredProviders = []
            for (let i = 0; i < responseProviders.length; i++) {
                // apply filters by type
                let isRendered = false
                if( (filters.type === responseProviders[i].type || filters.type === "") ){
                    isRendered = true
                }

                filteredProviders.push({
                    ...responseProviders[i], 
                    isRendered: isRendered
                })
                
            }

            setProfits({
                ...request.response,
                providers: filteredProviders
            })
        }

        setIsLoading(false);
    }

    const handleFilter = async () => {
        fetchProfits()
    }

    useEffect(() => {
        async function fetchProviderChoices(){
            const request = await commonService.getProvidersAsChoices()
            setProviderOptions(request.response)
        }

        fetchProviderChoices()
    }, [])

    useEffect(() => {
        fetchProfits()
        // eslint-disable-next-line
    }, [])

    const InfoCardProfit = ({ title, type, earned, refunded }) => {
        return(
            <Card fluid style={{ height: "10rem" }}>
                <Card.Content textAlign="center">
                    <FlexRow padding="0" background="transparent">
                        <FlexItem textAlign="center">
                            { title }: <br/>
                            <strong style={{ fontSize: "1.5rem", lineHeight: "2rem" }}>
                                { (earned - refunded) > 0 
                                    ? <span style={{ color: "green" }}> +{(earned - refunded)} { type === "money" && " €" }</span>
                                    : (earned-refunded) === 0 
                                        ? <span>0 { type === "money" && " €" }</span>
                                        : <span style={{ color: "red" }}> -{(earned - refunded)} { type === "money" && " €" }</span>
                                }
                            </strong> 
                        </FlexItem>
                    </FlexRow>
                    <FlexRow padding="0" background="transparent">
                        <FlexItem>
                            <Divider style={{ marginBottom: "0.4rem" }}/>
                        </FlexItem>
                    </FlexRow>
                    <FlexRow padding="0" background="transparent">
                        <FlexItem textAlign="center">
                        <span style={{ fontSize: "0.9rem" }}>
                            { type === "money" ? "Zarobené" : "Predané" }:
                        </span> 
                        <br/>
                        <strong style={{ color: "green" }}>{ earned } { type === "money" && "€" }</strong> 
                        </FlexItem>
                        <FlexItem textAlign="center">
                            <span style={{ fontSize: "0.9rem" }}>
                                Vrátené: 
                            </span>
                            <br/>
                            <strong style={{ color: "red" }}>{ refunded } { type === "money" && "€" }</strong> 
                        </FlexItem>
                    </FlexRow>
                </Card.Content>
            </Card>
        )
    }

    return (
        <Fragment>
            <HeaderTitle title="Prehľad ziskovosti" />
            <section>
                <Grid>
                    <Grid.Row style={{ paddingBottom: 0 }}>
                        <Grid.Column computer={12} tablet={16} mobile={16} >
                            <Form onSubmit={handleFilter}>
                                <Form.Group widths="equal">
                                    <DateInput
                                        fluid
                                        clearable
                                        localization="sk"
                                        label="Dátum od"
                                        placeholder="DD.MM.YYYY"
                                        dateFormat="DD.MM.YYYY"
                                        value={filters.from}
                                        onChange={(e, { value }) => setFilters({...filters, from: value})}
                                        iconPosition="right"
                                        animation="none"
                                        closable
                                        hideMobileKeyboard
                                    />
                                    <DateInput
                                        fluid
                                        clearable
                                        localization="sk"
                                        label="Dátum do"
                                        placeholder="DD.MM.YYYY"
                                        dateFormat="DD.MM.YYYY"
                                        value={filters.to}
                                        onChange={(e, { value }) => setFilters({...filters, to: value})}
                                        iconPosition="right"
                                        animation="none"
                                        closable
                                        hideMobileKeyboard
                                    />
                                    <Form.Select
                                        fluid
                                        search
                                        clearable
                                        multiple
                                        label='Poskytovatelia'
                                        options={providerOptions}
                                        placeholder='Vyberte poskytovateľa'
                                        value={ filters.providers }
                                        onChange={(e, { value }) => setFilters({...filters, providers: value})}
                                    />
                                    <Form.Field style={{ marginTop: "1.8rem" }}>
                                        <Button style={{ background: "var(--primary)", color: "var(--white)" }}>Potvrdiť</Button>
                                    </Form.Field>
                                </Form.Group>
                            </Form>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
                { isLoading 
                    ? <Loading />
                    : 
                    <>
                        <Grid style={{ marginBottom: "1rem" }}>
                            <Grid.Row columns={4}>
                                <Grid.Column>
                                    <InfoCardProfit title="Celkovo" type="money" earned={profits.total_earned} refunded={profits.total_refunded}/>
                                </Grid.Column>
                                <Grid.Column>
                                    <InfoCardProfit title="#APsolidarity" type="money" earned={profits.total_solidarity} refunded={profits.total_solidarity_refunded}/>
                                </Grid.Column>
                                <Grid.Column>
                                    <InfoCardProfit title="#APbusiness" type="money" earned={profits.total_business} refunded={profits.total_business_refunded}/>
                                </Grid.Column>
                                <Grid.Column>
                                    <InfoCardProfit title="Predaných aktivít" type="counter" earned={profits.total_activities} refunded={profits.total_activities_refunded}/>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                        <Divider/>
                        <ProviderProfitsTable 
                            profits={profits}
                            filters={filters}
                            setFilters={setFilters}
                            setProfits={setProfits}
                        />
                    </> 
                }
            </section>
        </Fragment>
    );
};

export default Profits;