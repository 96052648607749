// react
import React, { useState, useEffect, Fragment } from "react";
// store
import { companyService } from "../../../../store/services/ServiceCompany";
import { providerOptions } from "../../../../store/services/helpers/settings";
import { LOADING_TIME } from "../../../../store/config";
import {
    isEmpty,
    isEmptyErrorList,
    isPhoneError,
    isStreetNumberError,
    isICOError,
    isDICError,
    isICDPHError,
    isPostCodeError,
} from "../../../../store/services/helpers/validation";
// components
import ButtonSubmit from "../../../../components/buttons/ButtonSubmit";
import ModalResponse from "../../../../components/modals/ModalResponse";
import { Form, Divider } from "semantic-ui-react";

// component
function BillingForm({ setBank, setIsLoadingData }) {
    // state
    const [isLoading, setIsLoading] = useState(false);
    const [isProcessing, setIsProcessing] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [isError, setIsError] = useState(false);
    const [message, setMessage] = useState("");

    // form attributes
    const [providerType, setProviderType] = useState("2");
    const [isPrivatePerson, setIsPrivatePerson] = useState(false);
    const [companyName, setCompanyName] = useState("");
    const [firstname, setFirstname] = useState("");
    const [lastname, setLastname] = useState("");
    const [ico, setIco] = useState("");
    const [dic, setDic] = useState("");
    const [icDph, setIcDph] = useState("");
    const [phone, setPhone] = useState("");
    const [street, setStreet] = useState("");
    const [number, setNumber] = useState("");
    const [businessFolder, setBusinessFolder] = useState("");
    const [taxMode, setTaxMode] = useState(1);
    const [city, setCity] = useState("");
    const [postCode, setPostCode] = useState("");

    // fetch data
    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);
            setIsLoadingData(true)
            const data = await companyService.getCompanyInformation();

            // set state
            if (data[0]) {
                setProviderType(data[0].providerType.toString());

                if (data[0].providerType === 1) {
                    setIsPrivatePerson(true);
                } else {
                    setIsPrivatePerson(false);
                }

                setCompanyName(data[0].name);
                setFirstname(data[0].firstname);
                setLastname(data[0].lastname);
                setIco(data[0].ico);
                setDic(data[0].dic);
                setIcDph(data[0].ic_dph);
                setPhone(data[0].phone_number);
                setStreet(data[0].billing_street);
                setNumber(data[0].billing_street_nr);
                setCity(data[0].billing_city);
                setPostCode(data[0].billing_zip);
                setTaxMode(data[0].tax_mode);
                setBusinessFolder(data[0].business_folder);

                if( data[0].bank_account !== null ){
                    setBank(data[0].bank_account);
                }
            }

            setTimeout(function () {
                setIsLoading(false);
                setIsLoadingData(false)
            }, LOADING_TIME);
        };

        fetchData();
    }, [setBank, setIsLoadingData]);

    /** component functions **/
    const handleProviderTypeChange = (e, data) => {
        setProviderType(data.value);

        if (data.value === "1") {
            setIsPrivatePerson(true);
        } else {
            setIsPrivatePerson(false);
        }
    };

    const handleOpen = () => {
        setIsOpen(true);
    };

    const handleClose = () => {
        setIsOpen(false);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        setIsProcessing(true);

        // create object:
        const submittedData = {
            providerType: parseInt(providerType),
            phone_number: phone,
            billing_street: street,
            billing_street_nr: number,
            billing_city: city,
            billing_zip: postCode,
            firstname: firstname,
            lastname: lastname,
            name: companyName,
            ico: ico,
            dic: dic,
            ic_dph: icDph,
            tax_mode: taxMode,
            business_folder: businessFolder,
        };

        let formError = false;

        // validate form fields
        if (
            isPhoneError(phone) ||
            isPostCodeError(postCode) ||
            isStreetNumberError(number) ||
            (isICOError(ico) || isDICError(dic) || (isICDPHError(icDph) && !isEmpty(icDph)))
        ) {
            formError = true;
        }

        if (!formError) {
            if (await companyService.updateCompanyInformation(submittedData)) {
                setIsProcessing(false);
                setIsError(false);
                setMessage("Aktualizácia fakturačných údajov prebehla úspešne.");
                handleOpen();
            } else {
                setIsProcessing(false);
                setMessage("Počas aktualizácie údajov došlo k chybe. Skontrolujte povinné údaje!");
                setIsError(true);
                handleOpen();
            }
        } else {
            setMessage("Formulár nie je správne vyplnený!");
            setIsError(true);
            handleOpen();
        }

        setIsProcessing(false);
    };

    // template
    return (
        <Fragment>
            <Form loading={isLoading} onSubmit={handleSubmit}>
                <Form.Field width={16}>
                    <Form.Select
                        disabled={true}
                        style={{ display: "none" }}
                        fluid
                        label=""
                        placeholder="Typ používateľa"
                        options={providerOptions}
                        onChange={handleProviderTypeChange}
                        value={providerType}
                    />
                </Form.Field>

                <Divider horizontal>Fakturačné údaje</Divider>

                <Form.Field widths="equal">
                    <Form.Group>
                        <Form.Input
                            required
                            width={16}
                            label="Názov obchodnej spoločnosti"
                            placeholder="Názov obchodnej spoločnosti"
                            value={companyName}
                            onChange={(e) => setCompanyName(e.target.value)}
                        />

                        <Form.Input
                            className={isPrivatePerson ? "" : "hide"}
                            required={isPrivatePerson}
                            width={8}
                            label="Krstné meno"
                            placeholder="Krstné meno"
                            value={firstname}
                            onChange={(e) => setFirstname(e.target.value)}
                        />
                        <Form.Input
                            className={isPrivatePerson ? "" : "hide"}
                            required={isPrivatePerson}
                            width={8}
                            label="Priezvisko"
                            placeholder="Priezvisko"
                            value={lastname}
                            onChange={(e) => setLastname(e.target.value)}
                        />
                    </Form.Group>
                    <Form.Group widths="equal">
                        <Form.Input
                            width={3}
                            required
                            label="IČO"
                            placeholder="IČO"
                            value={ico}
                            onChange={(e) => setIco(e.target.value)}
                            error={!isEmpty(ico) ? isICOError(ico) : false}
                        />
                        <Form.Input
                            width={3}
                            label="DIČ"
                            required
                            placeholder="DIČ"
                            value={dic}
                            onChange={(e) => setDic(e.target.value)}
                            error={!isEmpty(dic) ? isDICError(dic) : false}
                        />
                        <Form.Input
                            width={3}
                            label="IČ DPH"
                            placeholder="IČ DPH"
                            value={icDph}
                            onChange={(e) => setIcDph(e.target.value)}
                            error={
                                !isEmpty(icDph) ? isICDPHError(icDph) : false
                            }
                        />

                        <Form.Input
                            width={7}
                            required
                            label="Telefónne číslo"
                            placeholder="+421901234567"
                            value={phone}
                            onChange={(e) => setPhone(e.target.value)}
                            error={
                                !isEmpty(phone) ? isPhoneError(phone) : false
                            }
                        />
                    </Form.Group>
                    <Form.Group widths="equal">
                        <Form.Input
                            label="Zapísaný v registri"
                            placeholder="..."
                            value={businessFolder}
                            onChange={(e) =>  setBusinessFolder(e.target.value)
                            }
                        />
                        <Form.Select
                            required
                            label="Typ platiteľa"
                            options={[
                                { key: 1, value: 1, text: "Platiteľ DPH"},
                                { key: 2, value: 2, text: "Neplatiteľ DPH"},
                                { key: 3, value: 3, text: "Registrovaný podľa §7, 7a"},
                            ]}
                            value={taxMode}
                            onChange={(e, { value }) =>  setTaxMode(value)}
                        />
                    </Form.Group>
                </Form.Field>

                <br />
                <Divider horizontal>Fakturačná adresa</Divider>
                <br />

                <Form.Group>
                    <Form.Input
                        width={10}
                        required
                        fluid
                        label="Ulica"
                        placeholder="Ulica"
                        value={street}
                        onChange={(e) => setStreet(e.target.value)}
                    />
                    <Form.Input
                        width={6}
                        required
                        fluid
                        label="Popisné číslo"
                        placeholder="Popisné číslo"
                        value={number}
                        onChange={(e) => setNumber(e.target.value)}
                        error={
                            !isEmpty(number)
                                ? isStreetNumberError(number)
                                : false
                        }
                    />
                </Form.Group>
                <Form.Group>
                    <Form.Input
                        width={10}
                        required
                        fluid
                        label="Mesto/Obec"
                        placeholder="Mesto/Obec"
                        value={city}
                        onChange={(e) => setCity(e.target.value)}
                    />
                    <Form.Input
                        width={6}
                        required
                        fluid
                        label="PSČ"
                        placeholder="PSČ"
                        value={postCode}
                        onChange={(e) => setPostCode(e.target.value)}
                        error={
                            !isEmpty(postCode)
                                ? isPostCodeError(postCode)
                                : false
                        }
                    />
                </Form.Group>

                <ButtonSubmit
                    loading={isProcessing}
                    disabled={
                        isProcessing ||
                        (isPrivatePerson
                            ? !isEmptyErrorList([companyName, firstname, lastname, ico, dic])
                            : !isEmptyErrorList([companyName, ico, dic])) ||
                        !isEmptyErrorList([
                            street,
                            number,
                            city,
                            postCode,
                            phone,
                        ]) || isICOError(ico) || isDICError(dic) || (isICDPHError(icDph) && ! isEmpty(icDph))
                    }
                    text="Uložiť"
                />
            </Form>

            <ModalResponse
                open={isOpen}
                onClose={handleClose}
                icon={isError ? "warning circle" : "checkmark"}
                header={isError ? "Chyba" : "Úspešne aktualizované"}
                message={message}
                onClick={handleClose}
            />
        </Fragment>
    );
}

export default BillingForm;
