import { API } from "../config";
// services
import { APIRequest } from "./helpers/requests";

/*
 * Service functions
 */
export const commonService = {
    getChoices,
    sendEmail,
    sendTownRegisterEmail,
    sendBusinessRegisterEmail,
    getProvidersAsChoices,
    getStats,
    postRequest,
};

async function postRequest(endpoint, data){
    return await APIRequest({
        url: endpoint,
        method: "POST",
        data: data,
        private: true,
    });
}

async function getChoices() {
    return await APIRequest({
        url: API.GET_CHOICES,
        method: "GET",
        data: {},
        private: false,
    });
}

async function getStats(endpoint) {
    return await APIRequest({
        url: endpoint,
        method: "GET",
        data: {},
        private: true,
    });
}

async function getProvidersAsChoices() {
    return await APIRequest({
        url: API.GET_PROVIDERS_COMPANIES_AS_CHOICES,
        method: "GET",
        data: {},
        private: true,
    });
}

async function sendEmail(data) {
    return await APIRequest({
        url: API.SEND_CONTACT_EMAIL,
        method: "POST",
        data: data,
        private: false,
    });
}


async function sendTownRegisterEmail(data) {
    return await APIRequest({
        url: API.SEND_TOWN_REGISTER_EMAIL,
        method: "POST",
        data: data,
        private: false,
    });
}

async function sendBusinessRegisterEmail(data) {
    return await APIRequest({
        url: API.SEND_BUSINESS_REGISTER_EMAIL,
        method: "POST",
        data: data,
        private: false,
    });
}
