// react
import React, { Fragment, useEffect } from "react";
// components
import HeaderTitle from "../../../../components/others/HeaderTitle";
import CategorySearch from "./CategorySearch";
// google analytics
import { GAPageView, initGA } from "../../../../index";

// component
function CategoryList() {
    // google analytics
    useEffect(() => {
        initGA();
        GAPageView();
    }, []);

    // template
    return (
        <Fragment>
            <HeaderTitle title="Administrácia kategórií" />
            <section>
                <CategorySearch />
            </section>
        </Fragment>
    );
}

export default CategoryList;
