// react
import React from "react";
import { Link } from "react-router-dom";
// store
import { routes } from "../../store/routes";
// components
import {
    Button,
    Header,
    Container,
    Icon,
    Divider,
    Message,
} from "semantic-ui-react";

// component
function ActivationMessage({ success, tryAgain }) {
    // template
    return (
        <Container text>
            <Header
                as="h1"
                style={{
                    marginTop: "0.5em",
                }}
                content={
                    success
                        ? "Aktivácia účtu dokončená"
                        : "Aktivácia účtu neúspešná"
                }
            />
            <Icon
                name={success ? "checkmark" : "warning"}
                size="huge"
                color="pink"
                circular
                align="center"
            />
            <Header
                as="h2"
                content={
                    success
                        ? "Tvoj účet bol úspešne aktivovaný."
                        : "Tvoj účet bohužiaľ nebol úspešne aktivovaný."
                }
            />
            <p>
                {success
                    ? "Úspešne si zvládol vytvorenie a aktiváciu tvojho účtu. Od tohto\n" +
                      " momentu môžeš naplno využívať aplikáciu ActivityPoint."
                    : "Aktiváciu je potrebné zopakovať."}
            </p>

            {success ? (
                <>
                    <Button as={Link} to={routes.LOGIN} color="pink" size="big">
                        Prihlásiť sa
                    </Button>

                    <Divider />

                    <Message
                        icon="shopping basket"
                        header="Vytvoril si si konto počas
                        objednávky?"
                        content="Vráť sa do nákupného košíka,
                        prihlás sa a dokonči svoju objednávku."
                    ></Message>
                    <Button
                        as={Link}
                        to={routes.BASKET}
                        color="pink"
                        size="large"
                    >
                        Zobraziť nákupný košík
                    </Button>
                </>
            ) : (
                <Button color="pink" size="big" onClick={tryAgain}>
                    Skúsiť znova
                </Button>
            )}
        </Container>
    );
}

export default ActivationMessage;
