import React, { useEffect, useState } from "react";
import { Link as RouteLink } from "react-router-dom";
// components
// eslint-disable-next-line
import Navbar from "../../components/general/Navbar";
import Footer from "../../components/general/Footer";
// import Banner from "../../components/general/Banner";
import APSolidarityInfo from "./APSolidarityInfo";
import APSolidarityForm from "./APSolidarityForm";
// eslint-disable-next-line
import { Button, Label, Image } from "semantic-ui-react";
// eslint-disable-next-line
import { Link } from "react-scroll";

// others
import { GAPageView, initGA } from "../../index";
// import { routes } from '../../store/routes';

export default function APSolidarity() {
    const [contacting, setContacting] = useState(false);

    // Google analytics
    useEffect(() => {
        initGA();
        GAPageView();
    }, []);

    return (
        <div className="website" style={{ fontFamily: "Metropolis Regular" }}>
            {/* <Navbar linkBack="/" /> */}
            <div className="navigation" style={{ marginBottom: "1rem" }}>
                <span className="navigation-logo">
                    <RouteLink to={"/"}>
                        <Image
                            className="navlogo"
                            src="/images/logos/logo_dark_version.png"
                            width="40%"
                        />
                    </RouteLink>
                </span>
            </div>
            <div className="banner-wrapper">
                <div
                    className="banner-container"
                    style={{
                        backgroundImage: `url(/images/public/activity/activitypoint-hero-apsolidarity.jpg)`,
                        backgroundPosition: "center 40%",
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "cover",
                        height: "80vH",
                    }}
                />

                <div className="back" style={{ maxWidth: "45rem" }}>
                    <div>
                        <Label
                            style={{ color: "white", background: "#3577df" }}
                        >
                            {" "}
                            #APsolidarity{" "}
                        </Label>
                    </div>
                    <div
                        className="text"
                        style={{
                            paddingBottom: "0.2rem",
                            paddingTop: "0.5rem",
                        }}
                    >
                        <span
                            style={{
                                fontSize: "2.5rem",
                                fontFamily: "Metropolis Bold",
                            }}
                        >
                            Prínos pre mestá a obce
                        </span>
                    </div>
                    {/* <p>Jednoduchý spôsob na zviditeľnenie vášho mesta či obce <br/> a na získanie finančných príspevkov na jeho budovanie.</p> */}
                    <ul
                        style={{
                            paddingLeft: "1rem",
                            marginBottom: /*"1.5rem"*/ 0,
                            listStyleType: "none",
                        }}
                    >
                        <li class="custom-bullet">
                            Unikátny spôsob zobrazenia zaujímavých miest v meste
                            či obci, s jedinečnou možnosťou výberu dobrovoľných
                            príspevkov pre potreby údržby a rozvoja
                        </li>
                        <li class="custom-bullet">
                            Plnohodnotne nahrádza informačné centrá
                        </li>
                        <li class="custom-bullet">Naviguje</li>
                        <li class="custom-bullet">
                            Samospráva nezaťažená prácou, celú agendu spracuje
                            team AP
                        </li>
                    </ul>
                    {/* <Link
                        to="about-activitypoint"
                        spy={true}
                        smooth={true}
                        offset={-20}
                        duration={500}
                    >
                        <Button style={{ padding: "1rem" }} className="btnSearch">Ako funguje ActivityPoint?</Button>
                    </Link>
                    <Button 
                        onClick={() => setContacting(true)}
                        style={{ 
                            padding: "1rem", 
                            marginLeft: "1rem", 
                            background: "none",
                            color: "var(--dark)"
                        }}
                    >
                        Registrovať obec/mesto
                    </Button> */}
                </div>
            </div>
            <APSolidarityInfo setContacting={setContacting} />
            <APSolidarityForm
                opened={contacting}
                setOpenedFunc={setContacting}
            />
            <Footer />
        </div>
    );
}
