// react
import React from "react";
import { Link } from "react-router-dom";
// store
import { routes } from "../../store/routes";
// components
import { Icon, Message, Header } from "semantic-ui-react";

// component
function RegisterMessageLogin() {
    // template
    return (
        <Message style={{ textAlign: "center" }}>
            <Header as="h5">
                <Icon name="help" />
                <Header.Content>
                    Už máš konto?{" "}
                    <Link to={routes.LOGIN}>{` Prihlás sa tu`}</Link>
                </Header.Content>
            </Header>
        </Message>
    );
}

export default RegisterMessageLogin;
