// react
import React from "react";
import { Link } from "react-router-dom";
// store
import { routes } from "../../store/routes";

// component
function LinkLogo() {
    // template
    return (
        <Link to={routes.HOME}>
            <img
                src="/images/logos/logo.png"
                width="103"
                height="33"
                alt="Activity Point logo"
            />
        </Link>
    );
}

export default LinkLogo;
