// react
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
// store
import { authService } from "../../store/services/ServiceAuth";
import { routes } from "../../store/routes";
// components
import ResetPasswordForm from "./ResetPasswordForm";
import HeaderTitle from "../../components/others/HeaderTitle";
// others
import { GAPageView, initGA } from "../../index";
import Navbar from "../../components/general/Navbar";

// component
function ResetPassword() {
    let history = useHistory();
    let dispatch = useDispatch();

    // Google analytics
    useEffect(() => {
        initGA();
        GAPageView();
    }, []);

    useEffect(() => {
        async function checkUser() {
            let result = await authService.getAccessToken();
            if (result !== false) {
                history.push(routes.DASHBOARD);
            }
        }

        checkUser();
    }, [history, dispatch]);

    // template
    return (
        <>
            <div className="cms">
                <Navbar linkBack="/login" />
                <div className="content-container" style={{ width: "100%" }}>
                    <HeaderTitle
                        title="Obnovenie hesla"
                        style={{ textAlign: "center" }}
                    />
                    <section>
                        <ResetPasswordForm userEmail={""} />
                    </section>
                </div>
            </div>
        </>
    );
}

export default ResetPassword;
