// react
import React, { useState, Fragment, useEffect } from "react";
// store
import { userProfileService } from "../../../store/services/ServiceUserProfile";
import { LOADING_TIME } from "../../../store/config";
// components
import ButtonSubmit from "../../../components/buttons/ButtonSubmit";
import ModalResponse from "../../../components/modals/ModalResponse";
import { Form, Grid, Header } from "semantic-ui-react";

// component
function ProfileActivation() {
    // state
    const [profileState, setProfileState] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const [isProcessing, setIsProcessing] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);
    const [modalIsError, setModalIsError] = useState(false);
    const [modalMessage, setModalMessage] = useState("");

    // fetch data - profile state
    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);
            const data = await userProfileService.getUserProfile();
            // set profile active state to state
            if (data[0]) {
                setProfileState(data[0].state);
            }

            setTimeout(function () {
                setIsLoading(false);
            }, LOADING_TIME);
        };

        fetchData();
    }, []);

    /** component functions **/

    const openModal = (message, error) => {
        setModalMessage(message);
        setModalIsError(error);
        setIsProcessing(false);
        handleModalOpen();
    };

    const handleModalOpen = () => {
        setModalOpen(true);
    };

    const handleModalClose = () => {
        setModalOpen(false);
    };

    // submit form
    const handleSubmit = async (event) => {
        event.preventDefault();
        setIsProcessing(true);

        // change actual profile state to opposite state
        let updatedProfileState;
        if (profileState) {
            setProfileState(false);
            updatedProfileState = {
                state: false,
            };
        } else {
            setProfileState(true);
            updatedProfileState = {
                state: true,
            };
        }

        if (await userProfileService.updateUserProfile(updatedProfileState)) {
            openModal("Zmena stavu aktivity účtu prebehla úspešne.", false);
        } else {
            openModal("Počas zmeny stavu aktivity účtu došlo k chybe!", true);
        }
    };

    // template
    return (
        <Fragment>
            <Grid>
                <Grid.Column computer={8} tablet={8} mobile={16}>
                    <Header as="h3">DEAKTIVÁCIA PROFILU</Header>

                    <Header as="h4">
                        Tvoj profil je momentálne{" "}
                        {profileState ? "aktívny" : "neaktívny"}.
                    </Header>
                    <Header as="h5">
                        Kliknutím na tlačidlo nižšie zmeníš stav tvojho profilu.
                    </Header>
                    <Form loading={isLoading} onSubmit={handleSubmit}>
                        <ButtonSubmit
                            loading={isProcessing}
                            disabled={isProcessing}
                            text={
                                profileState
                                    ? "Deaktivovať profil"
                                    : "Aktivovať profil"
                            }
                        />
                    </Form>
                </Grid.Column>
            </Grid>

            <ModalResponse
                open={modalOpen}
                onClose={handleModalClose}
                icon={modalIsError ? "warning circle" : "checkmark"}
                header={
                    modalIsError
                        ? "Aktualizácia neúspešná"
                        : "Aktualizácia úspešná"
                }
                message={modalMessage}
                onClick={handleModalClose}
            />
        </Fragment>
    );
}

export default ProfileActivation;
