// react
import React, { useEffect } from "react";
import { Link } from "react-router-dom";
// store
import { routes } from "../store/routes";
// others
import { GAPageView, initGA } from "../index";

// component
function Error({ code, message }) {
    // Google analytics
    useEffect(() => {
        initGA();
        GAPageView();
    }, []);

    // template
    return (
        <div className="error-page">
            <span className="center">
                <code>{code}</code>
                <p>{message}</p>
                <Link to={routes.HOME}>
                    <button className="custom-btn">
                        Návrat na domovskú stránku
                    </button>
                </Link>
            </span>
        </div>
    );
}

export default Error;
