// react
import React, { useEffect, useState } from "react";
import { Route, useHistory } from "react-router-dom";
// store
import { routes } from "../../store/routes";
import { authService } from "../../store/services/ServiceAuth";
// components
import Loading from "../others/Loading";

const PublicRoute = ({ component: Component, restricted, ...rest }) => {
    const history = useHistory();
    const [isLoading, setIsLoading] = useState(true);
    const [currentPath] = useState(window.location.pathname);

    useEffect(() => {
        async function checkUser() {
            const result = await authService.validateToken();
            setIsLoading(false);

            if (result !== false && restricted) {
                history.push(routes.DASHBOARD);
            }
        }

        checkUser();
    }, [history, currentPath, restricted]);

    if (isLoading) {
        return <Loading />;
    } else {
        return <Route {...rest} render={(props) => <Component {...props} />} />;
    }
};

export default PublicRoute;
