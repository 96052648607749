// react
import React, { Fragment, useEffect } from "react";
// store
import { authService } from "../../../../store/services/ServiceAuth";
// components
import HeaderTitle from "../../../../components/others/HeaderTitle";
import RequestSearch from "./RequestSearch";
// others
import { GAPageView, initGA } from "../../../../index";

// component
function Requests() {
    authService.useIsAdmin();

    // Google analytics
    useEffect(() => {
        initGA();
        GAPageView();
    }, []);

    // template
    return (
        <Fragment>
            <HeaderTitle title="Administrácia žiadostí" />
            <section>
                <RequestSearch />
            </section>
        </Fragment>
    );
}

export default Requests;
