import React, { useState, useEffect, useCallback } from "react";
// store
import { searchService } from "../../store/services/ServiceSearch";
import { LOADING_TIME } from "../../store/config";
import "../../styles/activitydetail.css";
// components
import Navbar from "../../components/general/Navbar";
import Footer from "../../components/general/Footer";
import Gallery from "../../components/others/Gallery";
import ActivityInfoSection from "./ActivityInfoSection";
// import Map from "../../components/others/Map";
import Loading from "../../components/others/Loading";
import { useHistory } from "react-router";
import { Grid, Container } from 'semantic-ui-react';
// import ActivityReviews from "./ActivityReviewsSection";
// import ActivitiesSimilarActivities from "./ActivitySimilarSection";
// others
// const COUNT_SHOW_REVIEWS_LIMIT = 4;

// component
export default function Activity(props) {
    // state
    // set the initial position of a user,
    // to have it available all around the component
    const queryParams = new URLSearchParams(window.location.search);
    const eventParam = queryParams.get("event");

    const history = useHistory();
    const [position, setPosition] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [data, setData] = useState(false);
    const [activityId] = useState(props.match.params.id);
    const [activityGallery, setActivityGallery] = useState([]);

    const [selected, setSelected] = useState({});

    // fetch data
    useEffect(() => {
        async function fetchData(id) {
            setIsLoading(true);
            let params = ""
            if (eventParam){
                params = "?event=" + eventParam
            }

            const result = await searchService.getActivityDetail(id, params);

            if (result.status === 200) {
                setData(result.response);
            } else if (result.status === 404) {
                history.push("/404");
                return;
            }

            createGallery(result.response);

            setTimeout(function () {
                setIsLoading(false);
            }, LOADING_TIME);
        }

        fetchData(activityId);
        // eslint-disable-next-line
    }, [activityId, history]);

    const getQueryParamsToNavigation = () => {
        return "https://www.waze.com/sk/livemap/directions?navigate=yes&from=" +
                        position?.lat +
                        "," +
                        position?.lng +
                        "&to=" +
                        selected.coords?.lat +
                        "," +
                        selected.coords?.lng +
                        ""
    }

    const initCurrentPosition = useCallback(() => {
        if ("geolocation" in navigator) {
            if( navigator.permissions && navigator.permissions?.query ) {
                navigator.permissions
                    .query({ name: "geolocation" })
                    .then((result) => {
                        if (["granted", "prompt"].includes(result.state)) {
                            navigator.geolocation.getCurrentPosition(
                                getCurrentPositionCallback
                            );
                        }
                    });
            } else {
                navigator.geolocation.getCurrentPosition(
                    getCurrentPositionCallback
                );
            }
        }
    }, []);

    const getCurrentPositionCallback = (position) => {
        const coords = position?.coords;
        const { latitude, longitude } = coords;

        // set the current position
        setPosition({
            lat: latitude,
            lng: longitude,
        });
    };

    useEffect(() => {
        if (!position) initCurrentPosition();
    }, [initCurrentPosition, position]);

    useEffect(() => {
        //console.log(selected)
    })

    const createGallery = (data) => {
        let galleryActivity = [];
        let photoItem = {};

        if (data.gallery) {
            // eslint-disable-next-line
            data.gallery.map((photo) => {
                photoItem = {
                    key: "",
                    src: "",
                    width: "100%",
                };
                photoItem.key = photo.id.toString();
                photoItem.src = photo.source;
                galleryActivity.push(photoItem);
            });
        }

        setActivityGallery(galleryActivity);
    };

    const photoPlaceholder = [
        {
            key: "photo1",
            src: "/images/general/image_placeholder_cover.jpg",
            width: "100%",
        },
    ];

    // template
    return (
        <>
            <Navbar linkBack="/search" />
            {isLoading ? (
                <Loading />
            ) : (
                <>
                    <Gallery
                        selected={selected}
                        photos={
                            data.gallery
                                ? data.gallery.length > 0
                                    ? activityGallery
                                    : photoPlaceholder
                                : photoPlaceholder
                        }
                        activityName={data.name}
                        timetable={data.timetable}
                    />
                    <ActivityInfoSection
                        data={data}
                        usersPosition={position}
                        selected={selected}
                        setSelected={setSelected}
                    />
                </>
            )}

            {/* <Map
                //current={true}
                destination={selected.coords}
                from={position}
                path={true}
                navigation={true}
            /> */}
            { position && 
                <Container className="section text-center map" style={{ paddingTop: "0px !important" }}>
                    <div className="details" style={{ paddingTop: 0 }}>
                        <Grid>
                            <Grid.Row centered>
                                <Grid.Column mobile={16} tablet={16} computer={16}>
                                    <a href={(position) ? getQueryParamsToNavigation() : '#'} rel="noopener noreferrer" target='_blank' className="navigate">
                                        <span>
                                            Navigovať
                                        </span>
                                    </a>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </div>
                </Container>
            }

            {/*
                <ActivityReviews
                    reviews={reviews}
                    showLimit={COUNT_SHOW_REVIEWS_LIMIT}
                />
            */}

            {/*
            <ActivitiesSimilarActivities
                activities={activities}
                usersPosition={position}
            />
            */}

            <Footer />
        </>
    );
}
