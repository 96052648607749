// react
import React from "react";
import { Link } from "react-router-dom";
// components
import { Button, Icon } from "semantic-ui-react";

// component
function ButtonAdd({ link, text }) {
    // template
    return (
        <Button
            as={Link}
            to={link}
            type="button"
            size="large"
            floated="right"
            color="pink"
            icon
            labelPosition="left"
            style={{
                marginLeft: "1rem",
                marginTop: "1rem",
                marginBottom: "1rem",
            }}
        >
            <Icon name="plus" /> {text}
        </Button>
    );
}

export default ButtonAdd;
