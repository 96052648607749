// react
import React, { useState, Fragment } from "react";
import { connect } from "react-redux";
// store
import { resetPasswordService } from "../../store/services/ServiceResetPassword";
import {
    isEmpty,
    isEmptyErrorList,
    isPasswordError,
    isRepeatPasswordError,
} from "../../store/services/helpers/validation";
// components
import ConfirmSuccessMessage from "./ConfirmSuccessMessage";
import ButtonSubmit from "../../components/buttons/ButtonSubmit";
import { Icon, Form, Segment, Grid, Header } from "semantic-ui-react";

// component
function ChooseYourPasswordForm(props) {
    // state
    const [newPassword, setNewPassword] = useState("");
    const [newAgainPassword, setNewAgainPassword] = useState("");
    const [isProcessing, setIsProcessing] = useState(false);
    const [confirmSuccessful, setConfirmSuccessful] = useState(false);

    const handleSubmit = async (event) => {
        event.preventDefault();
        setIsProcessing(true);

        // validate form fields
        let formError = false;

        if (
            isPasswordError(newPassword) ||
            isRepeatPasswordError(newPassword, newAgainPassword)
        ) {
            formError = true;
        }

        if (!formError) {
            // create dataset:
            const data = {
                uid: props.data.uid,
                token: props.data.token,
                new_password: newPassword,
            };

            // todo send reset password confirmation
            if (await resetPasswordService.resetPasswordConfirm(data)) {
                setConfirmSuccessful(true);
            } else {
                alert("Chyba pri resetovaní hesla.");
            }
        }

        setIsProcessing(false);
    };

    // template
    return (
        <Fragment>
            <Grid centered>
                {!confirmSuccessful ? (
                    <Grid.Column style={{ maxWidth: 450 }}>
                        <Segment>
                            <Form onSubmit={handleSubmit}>
                                <Form.Field width={16}>
                                    <Form.Input
                                        size="huge"
                                        type="password"
                                        required
                                        autoFocus
                                        fluid
                                        label="Nové heslo"
                                        placeholder="Nové heslo"
                                        value={newPassword}
                                        onChange={(e) =>
                                            setNewPassword(e.target.value)
                                        }
                                        error={
                                            !isEmpty(newPassword)
                                                ? isPasswordError(newPassword)
                                                : false
                                        }
                                    />
                                </Form.Field>

                                <Form.Field width={16}>
                                    <Form.Input
                                        size="huge"
                                        type="password"
                                        required
                                        fluid
                                        label="Nové heslo znova"
                                        placeholder="Nové heslo znova"
                                        value={newAgainPassword}
                                        onChange={(e) =>
                                            setNewAgainPassword(e.target.value)
                                        }
                                        error={
                                            !isEmpty(newAgainPassword)
                                                ? isRepeatPasswordError(
                                                      newPassword,
                                                      newAgainPassword
                                                  )
                                                : false
                                        }
                                    />
                                </Form.Field>

                                <Form.Field>
                                    <Header as="h5">
                                        <Icon name="info" circular />
                                        <Header.Content>
                                            Podmienky hesla
                                            <Header.Subheader>
                                                1 veľké písmeno, 1 číslica,
                                                minimálne 8 znakov
                                            </Header.Subheader>
                                        </Header.Content>
                                    </Header>
                                </Form.Field>

                                <ButtonSubmit
                                    loading={isProcessing}
                                    disabled={
                                        isProcessing ||
                                        !isEmptyErrorList([
                                            newPassword,
                                            newAgainPassword,
                                        ])
                                    }
                                    text="Resetovať heslo"
                                    fluid={true}
                                />
                            </Form>
                        </Segment>
                    </Grid.Column>
                ) : (
                    <Grid.Column style={{ maxWidth: 800 }}>
                        <Segment className="center-segment" textAlign="center">
                            <ConfirmSuccessMessage />
                        </Segment>
                    </Grid.Column>
                )}
            </Grid>
        </Fragment>
    );
}

export default connect()(ChooseYourPasswordForm);
