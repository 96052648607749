import React, { Fragment, useState } from 'react';
import { Tab } from 'semantic-ui-react'
// store
import { authService } from '../../../../store/services/ServiceAuth';

// components
import HeaderTitle from '../../../../components/others/HeaderTitle';
import UsersSearch from './UsersSearch'

const UsersListing = () => {
    authService.useIsAdmin();
    const [totalProviders, setTotalProviders] = useState("");

    const panes = [
        {
            menuItem: 'Poskytovatelia ' + totalProviders,

            render: () =>
            <Tab.Pane attached={false}>
                <UsersSearch setTotalProviders={setTotalProviders} totalProvider={totalProviders}/>
            </Tab.Pane>
        }
    ]

    return (
        <Fragment>
            <HeaderTitle title="Správa používateľov" />

            <section>
                <Tab menu={{ secondary: true, pointing: true }} panes={panes} />
            </section>
        </Fragment>
    );
};

export default UsersListing;