import React, { Fragment, useEffect, useState } from "react";
// store
import { PAGINATION_LIMIT, API } from "../../../../store/config";
// components
import { Table, Pagination, Icon } from "semantic-ui-react";

// component
function RewardsTable({ rewards }) {
    // eslint-disable-next-line
    const [tableData, setTableData] = useState(
        rewards.slice(0, PAGINATION_LIMIT)
    );

    // set data to table
    useEffect(() => {
        setTableData(rewards.slice(0, PAGINATION_LIMIT));
    }, [rewards]);

    const handleChangePage = (event, data) => {
        setTableData(
            rewards.slice(
                data.activePage * PAGINATION_LIMIT - PAGINATION_LIMIT,
                data.activePage * PAGINATION_LIMIT
            )
        );
    };

    // template
    return (
        <Fragment>
            <Table striped unstackable style={{ overflow: "auto" }}>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>Poskytovateľ</Table.HeaderCell>
                        <Table.HeaderCell textAlign="left">Platca DPH</Table.HeaderCell>
                        <Table.HeaderCell textAlign="left">Obdobie</Table.HeaderCell>
                        <Table.HeaderCell textAlign="left">Zárobok</Table.HeaderCell>
                        <Table.HeaderCell textAlign="left">Provízia</Table.HeaderCell>
                        <Table.HeaderCell textAlign="center">Vyplatená</Table.HeaderCell>
                        <Table.HeaderCell textAlign="center">Akcie</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>

                <Table.Body>
                    { tableData.length === 0 && 
                        <Table.Row> 
                            <Table.Cell colSpan={6} style={{ textAlign: "center", fontWeight: "bold" }}>
                                Neboli najdené žiadne záznamy
                            </Table.Cell>
                        </Table.Row>
                    }
                    {tableData.map((reward) => (
                        <Table.Row>
                            <Table.Cell>
                                <h3 style={{ marginBottom: 0 }}> 
                                    { reward.provider_name }
                                </h3>
                                <span> { reward.provider_address } </span>
                            </Table.Cell>
                            <Table.Cell textAlign="left">
                                { reward.commission.taxpayer ? "Áno" : "Nie" }
                            </Table.Cell>
                            <Table.Cell textAlign="left">
                                { reward.dates.date_from } - { reward.dates.date_to }
                            </Table.Cell>
                            <Table.Cell textAlign="left" style={{ color: "green" }}>
                                + { reward.commission.provider_profit } EUR
                            </Table.Cell>
                            <Table.Cell textAlign="left">
                                { reward.commission.commission } EUR
                            </Table.Cell>
                            <Table.Cell textAlign="center">
                                { reward.is_paid ? "Áno" : "Nie" }
                            </Table.Cell>
                            <Table.Cell textAlign="center">
                                <a href={API.GET_REWARDS + reward.id + "/docs"} target="_blank" rel="noopener noreferrer">
                                    <Icon name="file pdf" style={{color: "red"}}/>
                                </a>
                            </Table.Cell>
                        </Table.Row>
                    ))}
                </Table.Body>

                <Table.Footer>
                    <Table.Row>
                        <Table.HeaderCell colSpan="8">
                            <Pagination
                                defaultActivePage={1}
                                totalPages={Math.ceil(
                                    rewards.length / PAGINATION_LIMIT
                                )}
                                onPageChange={handleChangePage}
                                boundaryRange={0}
                                siblingRange={0}
                            />
                        </Table.HeaderCell>
                    </Table.Row>
                </Table.Footer>
            </Table>
        </Fragment>
    );
}

export default RewardsTable;
