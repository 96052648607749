// react
import React, { Fragment, useState, useEffect } from "react";
// store
import { activityService } from "../../../../store/services/ServiceActivity";
import { LOADING_TIME } from "../../../../store/config";
import { setSelectOptions } from "../../../../store/services/helpers/functions";
// components
import HeaderTitle from "../../../../components/others/HeaderTitle";
import ActivityForm from "./ActivityForm";
import Loading from "../../../../components/others/Loading";
// others
import { GAPageView, initGA } from "../../../../index";

// component
function ActivityEdit(props) {
    // state
    const [isLoading, setIsLoading] = useState(false);
    const [categories, setCategories] = useState([]);
    const [activity, setActivity] = useState({});
    const [activityId] = useState(props.match.params.id);

    // Google analytics
    useEffect(() => {
        initGA();
        GAPageView();
    }, []);

    // fetch data
    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);
            const result = await activityService.getActivities("?id=" + activityId);
            setActivity(result.response[0]);

            const resultCategories = await activityService.getCategories();
            setCategories(resultCategories);

            setTimeout(function () {
                setIsLoading(false);
            }, LOADING_TIME);
        };

        fetchData();
    }, [activityId]);

    // set options for dropdown form field
    const categoriesOptions = setSelectOptions(categories);

    // template
    return (
        <Fragment>
            {isLoading ? (
                <Loading />
            ) : (
                <>
                    <HeaderTitle title="Upraviť typ aktivity" />
                    <section>
                        <ActivityForm
                            data={activity?.id ? activity : {}}
                            categoriesProps={categoriesOptions}
                        />
                    </section>
                </>
            )}
        </Fragment>
    );
}

export default ActivityEdit;
