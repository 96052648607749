// react
import React, { Fragment, useEffect } from "react";
// components
import HeaderTitle from "../../../../components/others/HeaderTitle";
import PlanningCalendar from "./PlanningCalendar";
// others
import { GAPageView, initGA } from "../../../../index";

// component
function PlanningOverview() {
    // Google analytics
    useEffect(() => {
        initGA();
        GAPageView();
    }, []);

    // template
    return (
        <Fragment>
            <HeaderTitle title="Plánovanie aktivít" />
            <section>
                <PlanningCalendar />
            </section>
        </Fragment>
    );
}

export default PlanningOverview;
