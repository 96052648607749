// react
import React from "react";
// components
import DataTable from "../../../../components/tables/DataTable";
import DimmerLoader from "../../../../components/others/DimmerLoader";
// others
import moment from "moment";

const UsersActivityTable = ({ data, isLoading }) => {
    const orderedHeaders = ["id", "role", "name", "email", "last_login"];

    const headers = {
        _id: {
            text: "ID",
            invisible: true,
            filterable: false,
            transform: (value) => `#${value}`,
        },
        name: {
            text: "Meno",
            sortable: true,
            filterable: true,
        },
        email: {
            text: "Email",
            sortable: false,
            filterable: true,
        },
        last_login: {
            text: "Dátum poslednej aktivity",
            sortable: true,
            filterable: false,
            transform: (value, index, row) => {
                return getDate(row.last_login);
            },
        },
        role: {
            text: "Typ poskytovateľa",
            invisible: false,
            sortable: false,
            filterable: false,
            transform: (value, index, row) => {
                return getType(row);
            },
        },
        date_joined: {
            invisible: true,
        },
        is_town: {
            invisible: true,
        },
        is_coach: {
            invisible: true,
        },
        is_provider: {
            invisible: true,
        },
        is_active: {
            invisible: true,
        },
    };

    function getDate(input) {
        if (input === null) {
            return "neznámy";
        }
        return moment(input).format("YYYY-MM-DD");
    }

    function getType(input) {
        if (input.is_provider) {
            if (input.is_coach) {
                return "Kouč";
            } else if (input.is_town) {
                return "Mesto";
            }
        }

        return "Poskytovateľ";
    }

    return (
        <>
            <DimmerLoader active={isLoading} />
            <DataTable
                data={data}
                orderedHeaders={orderedHeaders}
                headers={headers}
            />
        </>
    );
};
export default UsersActivityTable;
