// react
import React, { Fragment } from "react";

// component
function Loading() {
    // template
    return (
        <Fragment>
            <div className="loading">
                <div className="loader">
                    <span></span>
                    <span></span>
                    <span></span>
                </div>
            </div>
        </Fragment>
    );
}

export default Loading;
