import React, { useState, useEffect } from "react";
import { Button } from "semantic-ui-react";
// import { Link } from "react-scroll";
import { Link as RouteLink } from "react-router-dom";
// components
// import Navbar from "../../components/general/Navbar";
import Footer from "../../components/general/Footer";
import ImageGallery from "../../components/ImageGallery";
import { Grid, Label, Image, Container, Header } from "semantic-ui-react";

// others
import { GAPageView, initGA } from "../../index";
import ContactForm from "./ContactForm";

export default function APBusiness() {
    const [contacting, setContacting] = useState(false);

    // Google analytics
    useEffect(() => {
        initGA();
        GAPageView();
    }, []);

    return (
        <div className="website" style={{ fontFamily: "Metropolis Regular" }}>
            {/* <Navbar linkBack="/"/> */}
            <div className="navigation" style={{ marginBottom: "1rem" }}>
                <span className="navigation-logo">
                    <RouteLink to={"/"}>
                        <Image
                            className="navlogo"
                            src="/images/logos/logo_dark_version.png"
                            width="40%"
                        />
                    </RouteLink>
                </span>
            </div>
            <div className="banner-wrapper">
                <div
                    className="banner-container"
                    style={{
                        backgroundImage: `url(/images/public/apbusiness/apbusiness_banner.jpg)`,
                        backgroundPosition: "center 40%",
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "cover",
                        height: "80vH",
                    }}
                />

                <div className="back" style={{ maxWidth: "45rem" }}>
                    <div>
                        <Label
                            style={{ color: "black", background: "#ffe200" }}
                        >
                            {" "}
                            #APimage{" "}
                        </Label>
                    </div>
                    <div
                        className="text"
                        style={{
                            paddingBottom: "0.2rem",
                            paddingTop: "0.5rem",
                        }}
                    >
                        <span
                            style={{
                                fontSize: "2rem",
                                fontFamily: "Metropolis Bold",
                            }}
                        >
                            Ponúkaš aktivity ?
                        </span>
                    </div>
                    <ul
                        style={{
                            paddingLeft: "1rem",
                            marginBottom: "1.5rem",
                            listStyleType: "none",
                        }}
                    >
                        <li class="custom-bullet">
                            Chceš zviditeľniť svoj biznis ? Ponúkaš pohybové,
                            športové, edukačné, kultúrne a historické aktivity ?
                        </li>
                        <li class="custom-bullet">
                            Nebaví ťa tlačiť prachy do boostingu, reklám,
                            cieľoviek, kade-tade po sociálnych sieťach ?
                        </li>
                        <li class="custom-bullet">
                            Riešiš lajky, komenty, hejty, plačeš do vankúša ?
                        </li>
                        <li class="custom-bullet">
                            Nerieš, ActivityPoint je tu, aby ti našiel nových
                            klientov
                        </li>
                    </ul>
                    {/* <Link
                        to="about-activitypoint"
                        spy={true}
                        smooth={true}
                        offset={-20}
                        duration={500}
                    >
                        <Button style={{ padding: "1rem" }} className="btnSearch">Ako funguje ActivityPoint?</Button>
                    </Link>
                    <a href={`mailto:${"obchod@activitypoint.xy"}`}>
                        <Button 
                            // onClick={() => setContacting(true)}
                            style={{ 
                                padding: "1rem", 
                                marginLeft: "1rem", 
                                background: "none",
                                color: "var(--dark)",
                                fontFamily: "Metropolis Regular"
                            }}
                        >
                            Registrovať službu/priestor
                        </Button>
                    </a> */}
                </div>
            </div>

            <Container>
                <Grid className="section" relaxed stackable>
                    <Grid.Row columns={3} centered style={{ marginTop: "0" }}>
                        <Grid.Column style={{ textAlign: "center" }}>
                            <Image
                                src="/images/icons/AP_Map_Pointer_ap_inzert.svg"
                                style={{ marginBottom: "1rem", width: "50px" }}
                                centered
                            />
                            <div>
                                <span
                                    style={{
                                        fontSize: "1.3rem",
                                        fontWeight: "bold",
                                    }}
                                >
                                    Zvýrazníme ťa
                                </span>
                                <p style={{ marginTop: "0.5rem" }}>
                                    užívateľ hĺadá aktivity na mape a teba vidí
                                    ako dostupnú možnosť vo svojom okolí, stáva
                                    sa z neho potenciálny klient pre teba a tvoj
                                    biznis, dobré, že?
                                </p>
                            </div>
                        </Grid.Column>
                        <Grid.Column style={{ textAlign: "center" }}>
                            <Image
                                src="/images/icons/AP_Map_Pointer_ap_inzert.svg"
                                style={{ marginBottom: "1rem", width: "50px" }}
                                centered
                            />
                            <div>
                                <span
                                    style={{
                                        fontSize: "1.3rem",
                                        fontWeight: "bold",
                                    }}
                                >
                                    Informujeme
                                </span>
                                <p style={{ marginTop: "0.5rem" }}>
                                    potenciálny klient dostane o tebe všetky
                                    potrebné informácie v jednom bode, vrátane
                                    tvojich sociálnych sietí. Všetky tieto
                                    informácie vie jednoducho zdieľať ďalej.
                                </p>
                            </div>
                        </Grid.Column>
                        <Grid.Column style={{ textAlign: "center" }}>
                            <Image
                                src="/images/icons/AP_Map_Pointer_ap_inzert.svg"
                                style={{ marginBottom: "1rem", width: "50px" }}
                                centered
                            />
                            <div>
                                <span
                                    style={{
                                        fontSize: "1.3rem",
                                        fontWeight: "bold",
                                    }}
                                >
                                    Navigujeme
                                </span>
                                <p style={{ marginTop: "0.5rem" }}>
                                    keď ťa potenciálny klient na mape našiel,
                                    informoval sa a tvoja služba sa mu páči,
                                    jednoducho sa naviguje na ponúkanú aktivitu,
                                    alebo to zdieľa priateľom ako zaujímavú
                                    ponuku. <br />
                                    <strong>Tvoj kšeft rastie.</strong>
                                </p>
                            </div>
                        </Grid.Column>
                    </Grid.Row>

                    <Grid.Row columns={1} centered>
                        <Grid.Column id="about-activitypoint">
                            <div
                                style={{
                                    color: "white",
                                    background: "rgb(255, 226, 0)",
                                    padding: "1rem",
                                    marginBottom: "2rem",
                                    borderRadius: "5px",
                                }}
                            >
                                <Header
                                    as="h2"
                                    textAlign="center"
                                    style={{
                                        fontSize: "2rem",
                                        marginTop: "2rem",
                                    }}
                                >
                                    Ako to celé funguje ?
                                </Header>
                                <p
                                    style={{
                                        width: "90%",
                                        margin: "1.5rem auto",
                                        textAlign: "center",
                                        fontSize: "1.15rem",
                                        color: "black",
                                    }}
                                >
                                    Webová platforma ActivityPoint za pomoci
                                    bodov na mape, zobrazí tvoju ponuku
                                    potenciálnemu klientovi. Ponuku si záujemca
                                    podrobne odfiltruje prostredníctvom
                                    kategórie, do ktorej budeš po dohode
                                    zaradený. Si blízko záujemcu? Ten vidí práve
                                    tvoju ponuku. Vie kde sa nachádzaš, čo
                                    ponúkaš a vidí aj všetky tvoje sociálne
                                    siete, na ktorých si sa tak nadrel. Bez
                                    reklám a spamov, jedinečný bod na mape s
                                    tvojou ponukou odpovedá ihneď na dopyt
                                    budúceho klienta, ktorý hľadá niečo
                                    dostupné, nové, zaujímavé, iné. Vyhľadávanie
                                    aktivít prostredníctvom platformy
                                    ActivityPoint šetrí čas pričom potenciálny
                                    klient nepotrebuje u nás žiadnu registráciu
                                    aby videl tvoju ponuku na mape.
                                </p>
                            </div>
                        </Grid.Column>
                    </Grid.Row>

                    <Grid.Row
                        columns={2}
                        centered
                        style={{ marginTop: "1rem" }}
                    >
                        <Grid.Column mobile={16} tablet={8} computer={8}>
                            <h2>
                                Inzeruj svoje aktivity v #APimage a získaj viac
                            </h2>
                            <p style={{ lineHeight: "1rem" }}>
                                <ul
                                    style={{
                                        paddingLeft: "1rem",
                                        marginBottom: "1.5rem",
                                        listStyleType: "none",
                                    }}
                                >
                                    <li class="custom-bullet">
                                        Za 69,90 € / mesačne svietiš na mape v
                                        okolí užívateľa kde informuješ a
                                        naviguješ 24/7/365
                                    </li>
                                    <li class="custom-bullet">
                                        Ak máš otázky kontaktuj nás
                                        prostredníctvom nezáväznej registrácie,
                                        radi pokecáme
                                    </li>
                                    <li class="custom-bullet">
                                        Všetky úkony spojené s tvojim bodom na
                                        mape sú na našich pleciach. Tak na čo
                                        čakáš ?
                                    </li>
                                    <li class="custom-bullet">
                                        Tvoju objednávku s aktivitou nám pošleš
                                        cez „Nezáväzná žiadosť o registráciu“
                                        <br />
                                    </li>
                                    <li class="custom-bullet">
                                        Nezabudni, zdieľaním myšlienky platformy
                                        ActivityPoint podporuješ aj vlastný
                                        kšeft
                                    </li>
                                </ul>
                            </p>
                            <Button
                                onClick={() => setContacting((prev) => !prev)}
                                style={{
                                    marginTop: "1rem",
                                    marginBottom: "2rem",
                                    padding: "1rem 3rem",
                                    color: "black",
                                    background: "#ffe200",
                                }}
                            >
                                Nezáväzná žiadosť o registráciu
                            </Button>
                        </Grid.Column>

                        <Grid.Column
                            mobile={12}
                            tablet={6}
                            computer={6}
                            textAlign={"center"}
                            style={{ height: "auto" }}
                        >
                            <ImageGallery
                                images={[
                                    "/images/public/apbusiness/gallery-0.jpg",
                                    "/images/public/apbusiness/gallery-1.jpg",
                                    "/images/public/apbusiness/gallery-2.jpg",
                                    "/images/public/apbusiness/gallery-3.png",
                                    "/images/public/apbusiness/gallery-4.jpg",
                                ]}
                            />
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Container>

            <ContactForm opened={contacting} setOpenedFunc={setContacting} />
            <Footer />
        </div>
    );
}
