// react
import React from "react";
// components
import { Button } from "semantic-ui-react";

// component
function ButtonSubmit({ loading, disabled, text, fluid }) {
    // template
    return (
        <Button
            type="submit"
            size="big"
            color="pink"
            fluid={fluid}
            style={{ marginTop: "1rem" }}
            loading={loading}
            disabled={disabled}
        >
            {text}
        </Button>
    );
}

export default ButtonSubmit;
