import React, { useState, useEffect } from "react";
import { Button } from "semantic-ui-react";
// import { Link } from "react-scroll";
import { Link as RouteLink } from "react-router-dom";
// components
// import Navbar from "../../components/general/Navbar";
import Footer from "../../components/general/Footer";
import ImageGallery from "../../components/ImageGallery";
import { Grid, Label, Image, Container, Header } from "semantic-ui-react";

// others
import { GAPageView, initGA } from "../../index";
import ContactForm from "./ContactForm";

export default function APEvent() {
    const [contacting, setContacting] = useState(false);

    // Google analytics
    useEffect(() => {
        initGA();
        GAPageView();
    }, []);

    return (
        <div className="website" style={{ fontFamily: "Metropolis Regular" }}>
            {/* <Navbar linkBack="/" /> */}
            <div className="navigation" style={{ marginBottom: "1rem" }}>
                <span className="navigation-logo">
                    <RouteLink to={"/"}>
                        <Image
                            className="navlogo"
                            src="/images/logos/logo_dark_version.png"
                            width="40%"
                        />
                    </RouteLink>
                </span>
            </div>
            <div className="banner-wrapper">
                <div
                    className="banner-container"
                    style={{
                        backgroundImage: `url(/images/public/apevent/banner.jpg)`,
                        backgroundPosition: "center 40%",
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "cover",
                        height: "80vH",
                    }}
                />

                <div className="back" style={{ maxWidth: "45rem" }}>
                    <div>
                        <Label
                            style={{ color: "black", background: "#50d90d" }}
                        >
                            {" "}
                            #APevent{" "}
                        </Label>
                    </div>
                    <div
                        className="text"
                        style={{
                            paddingBottom: "0.2rem",
                            paddingTop: "0.5rem",
                        }}
                    >
                        <span
                            style={{
                                fontSize: "2rem",
                                fontFamily: "Metropolis Bold",
                            }}
                        >
                            Chystáš event ?
                        </span>
                    </div>
                    <ul
                        style={{
                            paddingLeft: "1rem",
                            marginBottom: "0.5rem",
                            listStyleType: "none",
                        }}
                    >
                        <li class="custom-bullet">
                            Aj ty rozmýšľaš, špekuluješ ako ponúknuť ľuďom
                            informácie o podujatí/festivale, teda evente, ktorý
                            organizuješ ?
                        </li>
                        <li class="custom-bullet">
                            Píšeš pracne obsah na sociálne siete a aj tak niekto
                            volá a 8x sa pýta: „...kedy a kde to vlastne
                            organizujete?“
                        </li>
                        <li class="custom-bullet">
                            ActivityPoint tvoj event vybliká u seba na mape a to
                            si všimne každý.
                        </li>
                    </ul>

                    <p>Čítaj ďalej.</p>

                    {/* <Link
                        to="about-activitypoint"
                        spy={true}
                        smooth={true}
                        offset={-20}
                        duration={500}
                    >
                        <Button style={{ padding: "1rem" }} className="btnSearch">Ako funguje ActivityPoint?</Button>
                    </Link>
                    <a href={`mailto:${"obchod@activitypoint.xy"}`}>
                        <Button 
                            // onClick={() => setContacting(true)}
                            style={{ 
                                padding: "1rem", 
                                marginLeft: "1rem", 
                                background: "none",
                                color: "var(--dark)",
                                fontFamily: "Metropolis Regular"
                            }}
                        >
                            Registrovať event
                        </Button>
                    </a> */}
                </div>
            </div>

            <Container>
                <Grid className="section" relaxed stackable>
                    <Grid.Row columns={3} centered style={{ marginTop: "0" }}>
                        <Grid.Column style={{ textAlign: "center" }}>
                            <Image
                                src="/images/icons/AP_Map_Pointer_future_terms.svg"
                                style={{ marginBottom: "1rem", width: "50px" }}
                                centered
                            />
                            <div>
                                <span
                                    style={{
                                        fontSize: "1.3rem",
                                        fontWeight: "bold",
                                    }}
                                >
                                    Zobrazíme
                                </span>
                                <p style={{ marginTop: "0.5rem" }}>
                                    tvoj event na mape aktivít - zelený
                                    blikajúci bodje neprehliadnuteľný. Bliká
                                    priamo v mape do očí záujemcu o tvoj event,
                                    jedinečné že ?
                                </p>
                            </div>
                        </Grid.Column>
                        <Grid.Column style={{ textAlign: "center" }}>
                            <Image
                                src="/images/icons/AP_Map_Pointer_future_terms.svg"
                                style={{ marginBottom: "1rem", width: "50px" }}
                                centered
                            />
                            <div>
                                <span
                                    style={{
                                        fontSize: "1.3rem",
                                        fontWeight: "bold",
                                    }}
                                >
                                    Informujeme
                                </span>
                                <p style={{ marginTop: "0.5rem" }}>
                                    záujemcu o všetkom, čo považuješ za dôležité
                                    spomenúť v súvislosti s udalosťou, ktorú
                                    organizuješ, zároveň sa prekliká na všetky
                                    tvoje sociálne siete + webík.
                                </p>
                            </div>
                        </Grid.Column>
                        <Grid.Column style={{ textAlign: "center" }}>
                            <Image
                                src="/images/icons/AP_Map_Pointer_future_terms.svg"
                                style={{ marginBottom: "1rem", width: "50px" }}
                                centered
                            />
                            <div>
                                <span
                                    style={{
                                        fontSize: "1.3rem",
                                        fontWeight: "bold",
                                    }}
                                >
                                    Navigujeme
                                </span>
                                <p style={{ marginTop: "0.5rem" }}>
                                    užívateľa na presné miesto konania eventu.
                                    Zaručene ti nebude zvoniť v sídle
                                    spoločnosti, ak organizuješ event v lese na
                                    lúke. Áno aj ten vieme zobraziť
                                    nepotrebujeme adresu, k spokojnosti nám
                                    stačí GPS udalosti.
                                </p>
                            </div>
                        </Grid.Column>
                    </Grid.Row>

                    <Grid.Row columns={1} centered>
                        <Grid.Column id="about-activitypoint">
                            <div
                                style={{
                                    color: "black",
                                    background: "rgb(80, 217, 13)",
                                    padding: "1rem",
                                    marginBottom: "2rem",
                                    borderRadius: "5px",
                                }}
                            >
                                <Header
                                    as="h2"
                                    textAlign="center"
                                    style={{
                                        fontSize: "2rem",
                                        marginTop: "2rem",
                                    }}
                                >
                                    Ako funguje ActivityPoint ?
                                </Header>
                                <p
                                    style={{
                                        width: "90%",
                                        margin: "1.5rem auto",
                                        textAlign: "center",
                                        fontSize: "1.15rem",
                                    }}
                                >
                                    Webová platforma ActivityPoint je primárne
                                    určená novým návštevníkom regiónu, ktorí
                                    nepoznajú ponuku aktivít v regióne.
                                    ActivityPoint predstavuje mapu s bod mi v
                                    okolí návštevníka. Návštevník je jednoduchým
                                    spôsobom, bez potreby registrácie
                                    informovaný, čo zaujímavé a hodnotné región
                                    ponúka. Body, ktoré sú na mape zobrazené
                                    znázorňujú pohybové, športové, edukačné,
                                    kultúrne a historické aktivity v okolí, tak
                                    aby priniesli kvalitné voľnočasové možnosti.
                                    Body sú rozdelené farebne s vizuálnym
                                    efektom, čo umožňuje návštevníkovi rýchle
                                    zorientovanie sa v ponuke okolia. Body
                                    zobrazené na mape, znázorňujú pohybové,
                                    športové, edukačné, kultúrne a historické
                                    aktivity a eventy. Mapa aktivít šetrí čas
                                    vyhľadávania a umožňuje okrem podrobného
                                    informovania aj navigáciu k jednotlivým
                                    bodom.
                                </p>
                            </div>
                        </Grid.Column>
                    </Grid.Row>

                    <Grid.Row
                        columns={2}
                        centered
                        style={{ marginTop: "1rem" }}
                    >
                        <Grid.Column mobile={16} tablet={8} computer={8}>
                            <h2>Zviditeľni svoj event</h2>
                            <p style={{ lineHeight: "1rem" }}>
                                <ul
                                    style={{
                                        paddingLeft: "1rem",
                                        marginBottom: "0.5rem",
                                        listStyleType: "none",
                                    }}
                                >
                                    <li class="custom-bullet">
                                        Len za 29,90 € / deň svietiš na mape
                                        aktivít, v ktorej komunikuješ ozaj
                                        všetky potrebné informácie.
                                    </li>
                                    <li class="custom-bullet">
                                        Propagácia obsiahnutá v jednom bode
                                        zahŕňa oblasti, ktoré normálne
                                        komunikuješ na iných kanáloch.
                                    </li>
                                    <li class="custom-bullet">
                                        Máš otázky ? Kontaktuj nás, radi
                                        pokecáme.
                                    </li>
                                    <li class="custom-bullet">
                                        Všetky úmorné úkony spojené s
                                        propagáciou tvojho eventu na mape sú na
                                        našich pleciach. Tak na čo čakáš ?
                                    </li>
                                    <li class="custom-bullet">
                                        Pošli nám objednávku s tvojím eventom a
                                        budeš blikať na mape.
                                    </li>
                                    <li class="custom-bullet">
                                        Nezabudni, zdieľaním myšlienky platformy
                                        ActivityPoint podporuješ aj vlastný
                                        kšeft.
                                    </li>
                                </ul>
                            </p>
                            <Button
                                onClick={() => setContacting((prev) => !prev)}
                                style={{
                                    marginTop: "1rem",
                                    marginBottom: "2rem",
                                    padding: "1rem 3rem",
                                    color: "black",
                                    background: "#50d90d",
                                }}
                            >
                                Nezáväzná žiadosť o objednávku
                            </Button>
                        </Grid.Column>

                        <Grid.Column
                            mobile={12}
                            tablet={6}
                            computer={6}
                            textAlign={"center"}
                            style={{ height: "auto" }}
                        >
                            <ImageGallery
                                images={[
                                    "/images/public/apevent/gallery-1.jpg",
                                    "/images/public/apevent/gallery-0.jpg",
                                    "/images/public/apevent/gallery-3.jpg",
                                    "/images/public/apevent/gallery-4.jpg",
                                ]}
                            />
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Container>

            <ContactForm opened={contacting} setOpenedFunc={setContacting} />
            <Footer />
        </div>
    );
}
