// react
import React, { useState, Fragment } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
// store
import { routes } from "../../store/routes";
import { resetPasswordService } from "../../store/services/ServiceResetPassword";
import { isEmpty, isEmailError } from "../../store/services/helpers/validation";
// components
import ButtonSubmit from "../../components/buttons/ButtonSubmit";
import ModalResponse from "../../components/modals/ModalResponse";
import { Form, Grid, Segment } from "semantic-ui-react";

// component
function ResetPasswordForm({ userEmail }) {
    // state
    const [email, setEmail] = useState(userEmail);
    const [isProcessing, setIsProcessing] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);
    const [modalIsError, setModalIsError] = useState(false);
    const [modalMessage, setModalMessage] = useState("");

    const openModal = (message, error) => {
        setModalMessage(message);
        setModalIsError(error);
        handleModalOpen();
    };

    const handleModalOpen = () => {
        setModalOpen(true);
    };

    const handleModalClose = () => {
        setModalOpen(false);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        setIsProcessing(true);

        // validate form fields
        let formError = false;
        if (isEmailError(email)) {
            formError = true;
        }

        if (!formError) {
            // send request for reset password
            if (await resetPasswordService.requestResetPassword(email)) {
                openModal(
                    "Žiadosť na obnovenie hesla bola odoslaná na Váš email",
                    false
                );
            } else {
                openModal(
                    "Žiadosť na obnovenie hesla sa nepodarilo odoslať!",
                    true
                );
            }
        }

        setIsProcessing(false);
    };

    // template
    return (
        <Fragment>
            <Grid centered>
                <Grid.Column style={{ maxWidth: 450 }}>
                    <Segment>
                        <Link to={routes.HOME}>
                            <img
                                src="/images/logos/logo_dark_version.png"
                                width="60%"
                                style={{
                                    padding: "1rem",
                                    display: "block",
                                    marginLeft: "auto",
                                    marginRight: "auto",
                                }}
                                alt="Activity Point logo"
                            />
                        </Link>
                        <Form onSubmit={handleSubmit}>
                            <Form.Field width={16}>
                                <Form.Input
                                    size="huge"
                                    required
                                    autoFocus
                                    fluid
                                    label="Email"
                                    placeholder="Email"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    error={
                                        !isEmpty(email)
                                            ? isEmailError(email)
                                            : false
                                    }
                                />
                            </Form.Field>

                            <ButtonSubmit
                                fluid={true}
                                loading={isProcessing}
                                disabled={isProcessing || isEmpty(email)}
                                text="Odoslať žiadosť na obnovenie hesla"
                            />
                        </Form>
                    </Segment>
                </Grid.Column>
            </Grid>

            <ModalResponse
                open={modalOpen}
                onClose={handleModalClose}
                icon={modalIsError ? "warning circle" : "checkmark"}
                header={
                    !modalIsError ? "Požiadavka odoslaná" : "Chyba pri odoslaní"
                }
                message={modalMessage}
                onClick={handleModalClose}
            />
        </Fragment>
    );
}

export default connect()(ResetPasswordForm);
