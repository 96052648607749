// react
import React, { useState, Fragment, useEffect } from "react";
// store
import { routes } from "../../store/routes";
import { LOADING_TIME } from "../../store/config";
import { addressService } from "../../store/services/ServiceAddress";
import { commonService } from "../../store/services/ServiceCommon";
import { authService } from "../../store/services/ServiceAuth";
import { setSelectCountriesOptions } from "../../store/services/helpers/functions";
import {
    isEmailError,
    isEmpty,
    isEmptyErrorList,
    isPasswordError,
    isPhoneError,
    isPostCodeError,
    isRepeatPasswordError,
    isStreetNumberError,
} from "../../store/services/helpers/validation";
// components
import Loading from "../../components/others/Loading";
import EmailActivationMessage from "./EmailActivationMessage";
import ButtonSubmit from "../../components/buttons/ButtonSubmit";
import ButtonBack from "../../components/buttons/ButtonBack";
import RegisterCheckbox from "./RegisterCheckbox";
import RegisterMessageLogin from "./RegisterMessageLogin";
import ModalResponse from "../../components/modals/ModalResponse";
import { Divider, Form, Header, Icon } from "semantic-ui-react";
// others
import { GAPageView, initGA } from "../../index";

// component
function RegisterClient({ basketRegistration, setLoginForm }) {
    // state
    const [success, setSuccess] = useState(false);
    const [isProcessing, setIsProcessing] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [isCheckboxError, setIsCheckboxError] = useState(false);
    const [errorOpen, setErrorOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    // form attributes
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [repeatPassword, setRepeatPassword] = useState("");
    const [street, setStreet] = useState("");
    const [number, setNumber] = useState("");
    const [city, setCity] = useState("");
    const [postCode, setPostCode] = useState("");
    const [country, setCountry] = useState("");
    const [countryList, setCountryList] = useState([]);
    const [phone, setPhone] = useState("");

    // Google analytics
    useEffect(() => {
        initGA();
        GAPageView();
    }, []);

    // fetch data - country select options
    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);

            const result = await commonService.getChoices();
            if (result.status === 200) {
                setCountryList(result.response.countries);
            }

            setTimeout(function () {
                setIsLoading(false);
            }, LOADING_TIME);
        };

        fetchData();
    }, []);

    // set country select options
    const countryOptions = setSelectCountriesOptions(countryList);

    // form handlers
    const handleOnChangeCheckbox = (e, data) => {
        if (isCheckboxError) {
            setIsCheckboxError(false);
        }
    };

    const handleOnChangeCountry = (e, data) => {
        setCountry(data.value);
    };

    const handleRegister = async (event) => {
        event.preventDefault();
        setIsProcessing(true);

        let formError = false;

        // validate form fields
        if (
            isEmailError(email) ||
            isPasswordError(password) ||
            isRepeatPasswordError(password, repeatPassword) ||
            isPhoneError(phone) ||
            isPostCodeError(postCode) ||
            isStreetNumberError(number)
        ) {
            formError = true;
        }
        // validate form checkbox
        if (!document.getElementById("checkbox-register-client").checked) {
            setIsCheckboxError(true);
            formError = true;
        }

        // register user when form is valid
        if (!formError) {
            const dataAddress = {
                address_line: "",
                street: `${street} ${number}`,
                city: city,
                postcode: postCode,
                country: country,
            };

            // send address data object
            let resultAddress = await addressService.createBaseAddress(
                dataAddress
            );

            let addressId = 0;
            if (resultAddress.status === 201) {
                addressId = resultAddress.response.id;

                const dataProfile = {
                    is_provider: false,
                    firstname: firstName,
                    lastname: lastName,
                    email: email,
                    password: password,
                    phone: phone,
                    address: addressId,
                };

                // send data object
                let result = await authService.register(dataProfile);

                if (result.status !== 201) {
                    if (result.response.email) {
                        setErrorMessage("Emailová adresa už je obsadená!");
                    } else if (result.response.password) {
                        setErrorMessage(
                            "Zadané heslo nie je platné! Zadajte iné heslo."
                        );
                    } else {
                        setErrorMessage("Pri registrácii nastala chyba!");
                    }

                    setErrorOpen(true);
                    setSuccess(false);
                } else {
                    setSuccess(true);
                }
                setIsProcessing(false);
            } else {
                setErrorMessage("Pri registrácii nastala chyba!");
                setSuccess(false);
                setIsProcessing(false);
            }
        }
        // show form error
        else {
            setSuccess(false);
            setIsProcessing(false);
        }
    };

    // template
    return (
        <Fragment>
            {isLoading ? (
                <Loading />
            ) : (
                <Fragment>
                    {!basketRegistration && (
                        <ButtonBack link={routes.REGISTER} />
                    )}
                    {!success ? (
                        <Fragment>
                            <Form
                                onSubmit={handleRegister}
                                className="attached fluid segment"
                            >
                                <Form.Group width={16} widths="equal">
                                    <Form.Input
                                        size="large"
                                        required
                                        autoFocus
                                        fluid
                                        label="Meno"
                                        placeholder="Meno"
                                        value={firstName}
                                        onChange={(e) =>
                                            setFirstName(e.target.value)
                                        }
                                    />
                                    <Form.Input
                                        size="large"
                                        required
                                        fluid
                                        label="Priezvisko"
                                        placeholder="Priezvisko"
                                        value={lastName}
                                        onChange={(e) =>
                                            setLastName(e.target.value)
                                        }
                                    />
                                </Form.Group>
                                <Form.Group>
                                    <Form.Input
                                        width={16}
                                        size="large"
                                        required
                                        fluid
                                        label="Email"
                                        placeholder="Email"
                                        value={email}
                                        onChange={(e) =>
                                            setEmail(e.target.value)
                                        }
                                        error={
                                            !isEmpty(email)
                                                ? isEmailError(email)
                                                : false
                                        }
                                    />
                                </Form.Group>
                                <Form.Group width={16} widths="equal">
                                    <Form.Input
                                        size="large"
                                        required
                                        fluid
                                        label="Heslo"
                                        placeholder="Heslo"
                                        value={password}
                                        type="password"
                                        onChange={(e) =>
                                            setPassword(e.target.value)
                                        }
                                        error={
                                            !isEmpty(password)
                                                ? isPasswordError(password)
                                                : false
                                        }
                                    />
                                    <Form.Input
                                        size="large"
                                        required
                                        fluid
                                        label="Opakuj heslo"
                                        placeholder="Opakuj heslo"
                                        value={repeatPassword}
                                        type="password"
                                        onChange={(e) =>
                                            setRepeatPassword(e.target.value)
                                        }
                                        error={
                                            !isEmpty(repeatPassword)
                                                ? isRepeatPasswordError(
                                                      password,
                                                      repeatPassword
                                                  )
                                                : false
                                        }
                                    />
                                </Form.Group>
                                <Form.Field>
                                    <Header as="h5">
                                        <Icon name="info" circular />
                                        <Header.Content>
                                            Podmienky hesla
                                            <Header.Subheader>
                                                1 veľké písmeno, 1 číslica,
                                                minimálne 8 znakov
                                            </Header.Subheader>
                                        </Header.Content>
                                    </Header>
                                </Form.Field>

                                <Divider horizontal>
                                    Adresa a kontaktné údaje
                                </Divider>

                                <Form.Group>
                                    <Form.Select
                                        width={8}
                                        required
                                        size="large"
                                        fluid
                                        search
                                        label="Krajina"
                                        placeholder="Krajina"
                                        options={countryOptions}
                                        value={country}
                                        onChange={handleOnChangeCountry}
                                    />
                                    <Form.Input
                                        width={8}
                                        required
                                        size="large"
                                        fluid
                                        label="Telefónne číslo"
                                        placeholder="+421901234567"
                                        value={phone}
                                        onChange={(e) =>
                                            setPhone(e.target.value)
                                        }
                                        error={
                                            !isEmpty(phone)
                                                ? isPhoneError(phone)
                                                : false
                                        }
                                    />
                                </Form.Group>

                                <Form.Group>
                                    <Form.Input
                                        width={8}
                                        required
                                        fluid
                                        label="Mesto/Obec"
                                        placeholder="Mesto/Obec"
                                        value={city}
                                        onChange={(e) =>
                                            setCity(e.target.value)
                                        }
                                    />
                                    <Form.Input
                                        width={8}
                                        required
                                        fluid
                                        label="PSČ"
                                        placeholder="PSČ"
                                        value={postCode}
                                        onChange={(e) =>
                                            setPostCode(e.target.value)
                                        }
                                        error={
                                            !isEmpty(postCode)
                                                ? isPostCodeError(postCode)
                                                : false
                                        }
                                    />
                                </Form.Group>

                                <Form.Group>
                                    <Form.Input
                                        width={8}
                                        required
                                        fluid
                                        label="Ulica"
                                        placeholder="Ulica"
                                        value={street}
                                        onChange={(e) =>
                                            setStreet(e.target.value)
                                        }
                                    />
                                    <Form.Input
                                        width={8}
                                        required
                                        fluid
                                        label="Popisné číslo"
                                        placeholder="Popisné číslo"
                                        value={number}
                                        onChange={(e) =>
                                            setNumber(e.target.value)
                                        }
                                        error={
                                            !isEmpty(number)
                                                ? isStreetNumberError(number)
                                                : false
                                        }
                                    />
                                </Form.Group>

                                <RegisterCheckbox
                                    checkboxId="checkbox-register-client"
                                    onChange={handleOnChangeCheckbox}
                                    isCheckboxError={isCheckboxError}
                                />

                                <ButtonSubmit
                                    loading={isProcessing}
                                    disabled={
                                        isProcessing ||
                                        !isEmptyErrorList([
                                            firstName,
                                            lastName,
                                            email,
                                            password,
                                            repeatPassword,
                                            street,
                                            number,
                                            city,
                                            postCode,
                                            country,
                                        ])
                                    }
                                    text="Registrovať"
                                />
                            </Form>
                            {!basketRegistration && <RegisterMessageLogin />}
                        </Fragment>
                    ) : (
                        <EmailActivationMessage
                            email={email}
                            basketRegistration={basketRegistration}
                            setLoginForm={setLoginForm}
                        />
                    )}

                    <ModalResponse
                        open={errorOpen}
                        onClose={() => setErrorOpen(false)}
                        icon="warning circle"
                        header="Chyba"
                        message={errorMessage}
                        onClick={() => setErrorOpen(false)}
                    />
                </Fragment>
            )}
        </Fragment>
    );
}

export default RegisterClient;
