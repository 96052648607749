// react
import React, { useState, Fragment } from "react";
// store
import { authService } from "../../store/services/ServiceAuth";
// components
import ButtonAction from "../payment-overview/payment-modal/ButtonAction";
import {
    Segment,
    Header,
    Icon,
    Button,
    Message,
    Container,
    Divider,
} from "semantic-ui-react";

// component
function EmailActivationMessage(props) {
    // state
    const [sending, setSending] = useState(false);
    const [sended, setSended] = useState(false);

    const handleResend = async () => {
        setSended(false);
        setSending(true);

        if (await authService.resendActivation({ email: props.email })) {
            setSended(true);
            setSending(false);
        } else {
            alert("Chyba pri preposlaní aktivačného emailu.");
            setSended(false);
            setSending(false);
        }
    };

    // template
    return (
        <Fragment>
            <Segment className="center-segment" textAlign="center">
                <Container text>
                    <Header
                        as="h1"
                        style={{
                            marginTop: "0.5rem",
                        }}
                    >
                        Aktivačný email odoslaný
                    </Header>
                    <Icon
                        name="mail"
                        size="huge"
                        color="pink"
                        circular
                        align="center"
                        style={{
                            marginBottom: "0.5rem",
                        }}
                    />

                    <Header as="h3">
                        Na emailovú adresu <i>{props.email}</i> sme odoslali
                        žiadosť na aktiváciu Vášho účtu.
                    </Header>

                    <Segment.Inline>
                        <p>
                            Ak ste neobdržali aktivačný email zvoľte nasledujúcu
                            akciu.
                        </p>
                        <br />
                    </Segment.Inline>
                    <Segment.Inline>
                        <Button
                            size="large"
                            color="pink"
                            animated
                            onClick={handleResend}
                            loading={sending}
                            disabled={sending}
                        >
                            <Button.Content visible>
                                Odoslať znova
                            </Button.Content>
                            <Button.Content hidden>
                                <Icon name="send" />
                            </Button.Content>
                        </Button>
                    </Segment.Inline>
                    <Message
                        style={{ textAlign: "center" }}
                        hidden={!sended}
                        success
                        header="Aktivačný email bol znova odoslaný"
                        content="Aktiváciou účtu získate prístup do platformy ActivityPoint"
                    />

                    {props.basketRegistration && (
                        <>
                            <Divider />
                            <Segment.Inline>
                                <p>
                                    Po úspešnej aktivácii emailu sa prihláste
                                    kliknutím na tlačidlo nižšie a dokončite
                                    objednávku.
                                </p>
                                <ButtonAction
                                    text="Prihlásiť sa"
                                    onClick={props.setLoginForm}
                                />
                            </Segment.Inline>
                        </>
                    )}
                </Container>
            </Segment>
        </Fragment>
    );
}

export default EmailActivationMessage;
