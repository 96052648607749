// react
import React, { useState, useEffect, Fragment } from "react";
// store
import { bundleService } from "../../../../store/services/ServiceBundle";
import { activityService } from "../../../../store/services/ServiceActivity";
import { LOADING_TIME } from "../../../../store/config";
import {
    isDateError,
    isEmpty,
    isEmptyErrorList,
} from "../../../../store/services/helpers/validation";
import { setSelectOptions } from "../../../../store/services/helpers/functions";
// components
import PaymentTable from "./PaymentTable";
import Loading from "../../../../components/others/Loading";
import ButtonGroupSearch from "../../../../components/buttons/ButtonGroupSearch";
import AccordionColumn from "../../../../components/others/AccordionColumn";
import { Form, Button, Grid, Segment, Header } from "semantic-ui-react";
import { DateInput } from "semantic-ui-calendar-react";

// component
function PaymentSearch() {
    // state
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [tableIsLoading] = useState(false);
    const [bundle, setBundle] = useState("");
    const [activity, setActivity] = useState("");
    const [dateFrom, setDateFrom] = useState("");
    const [dateTo, setDateTo] = useState("");
    const [bundles, setBundles] = useState([]);
    const [activities, setActivities] = useState([]);

    // fetch data - bundles, activities
    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);
            const [allBundles, allActivities] = await Promise.all([
                bundleService.getBundles(),
                activityService.getActivities(""),
            ]);
            setBundles(allBundles);
            setActivities(allActivities);

            setTimeout(function () {
                setIsLoading(false);
            }, LOADING_TIME);
        };
        fetchData();
    }, []);

    // set options for dropdown form fields - bundles, activities
    const bundlesOptions = setSelectOptions(bundles);
    const activitiesOptions = setSelectOptions(activities);

    /** component functions **/

    const resetForm = () => {
        setDateFrom("");
        setDateTo("");
        setBundle("");
        setActivity("");
    };

    const handleOnChangeBundle = (e, data) => {
        setBundle(data.value);
    };

    const handleOnChangeActivity = (e, data) => {
        setActivity(data.value);
    };

    const handleOnChangeDateFrom = (e, data) => {
        setDateFrom(data.value);
    };

    const handleOnChangeDateTo = (e, data) => {
        setDateTo(data.value);
    };

    // submit search
    const handleSubmit = async (e) => {
        e.preventDefault();

        // TODO: temporary set dummy data for table
        setData([
            {
                id: 1,
                paid_date: "2020-07-01",
                date_to: "2020-07-31",
                price: "100.00",
                credit_card: "XXXX-XXXX-XXXX-6789",
                activities: [
                    {
                        id: 1,
                        name: "Hokej",
                    },
                ],
                provider: "Provider s.r.o.",
            },
            {
                id: 2,
                paid_date: "2020-07-04",
                date_to: "2020-07-08",
                price: "430.00",
                credit_card: "XXXX-XXXX-XXXX-6789",
                activities: [
                    {
                        id: 1,
                        name: "Futbal",
                    },
                    {
                        id: 2,
                        name: "Hokej",
                    },
                    {
                        id: 3,
                        name: "Americký futbal",
                    },
                ],
                provider: "Provider s.r.o.",
            },
            {
                id: 3,
                paid_date: "2020-07-01",
                date_to: "2020-07-31",
                price: "150.00",
                credit_card: "XXXX-XXXX-XXXX-6789",
                activities: [
                    {
                        id: 1,
                        name: "Futbal",
                    },
                ],
                provider: "Provider s.r.o.",
            },
        ]);
    };

    // search form inputs
    const panels = [
        {
            key: "details",
            title: "Kritéria vyhľadávania",
            content: {
                content: (
                    <div>
                        <Form id="form-search-payments" onSubmit={handleSubmit}>
                            <Form.Group widths="equal">
                                <DateInput
                                    label="Dátum úhrady od"
                                    placeholder="Dátum od"
                                    dateFormat="YYYY-MM-DD"
                                    value={dateFrom}
                                    onChange={handleOnChangeDateFrom}
                                    iconPosition="right"
                                    animation="none"
                                    closable
                                    hideMobileKeyboard
                                    error={
                                        !isEmpty(dateFrom)
                                            ? isDateError(dateFrom)
                                            : false
                                    }
                                />
                                <DateInput
                                    label="Dátum úhrady do"
                                    placeholder="Dátum do"
                                    dateFormat="YYYY-MM-DD"
                                    value={dateTo}
                                    onChange={handleOnChangeDateTo}
                                    iconPosition="right"
                                    animation="none"
                                    closable
                                    hideMobileKeyboard
                                    error={
                                        !isEmpty(dateTo)
                                            ? isDateError(dateTo)
                                            : false
                                    }
                                />
                            </Form.Group>
                            <Form.Group widths="equal">
                                <Form.Select
                                    fluid
                                    label="Názov balíčka"
                                    placeholder="Balíček"
                                    options={bundlesOptions}
                                    value={bundle}
                                    onChange={handleOnChangeBundle}
                                />
                                <Form.Select
                                    fluid
                                    label="Názov aktivity"
                                    placeholder="Aktivita"
                                    options={activitiesOptions}
                                    value={activity}
                                    onChange={handleOnChangeActivity}
                                />
                            </Form.Group>
                        </Form>

                        <ButtonGroupSearch
                            form="form-search-payments"
                            resetDisabled={
                                !isEmptyErrorList([
                                    dateFrom,
                                    dateTo,
                                    bundle,
                                    activity,
                                ])
                            }
                            resetOnClick={resetForm}
                        />

                        <Button.Group
                            floated="left"
                            style={{
                                marginBottom: "0.5rem",
                                marginTop: "0.5rem",
                            }}
                        >
                            <Button
                                color="pink"
                                type="submit"
                                form="form-search-payments"
                            >
                                Vyhľadaj
                            </Button>
                            <Button
                                type="button"
                                onClick={resetForm}
                                disabled={
                                    isEmpty(dateFrom) &&
                                    isEmpty(dateTo) &&
                                    isEmpty(bundle) &&
                                    isEmpty(activity)
                                }
                                color="pink"
                                inverted
                            >
                                Zrušiť Filter
                            </Button>
                        </Button.Group>
                    </div>
                ),
            },
        },
    ];

    // template
    return (
        <Fragment>
            {isLoading ? (
                <Loading />
            ) : (
                <Grid>
                    <AccordionColumn panels={panels} />
                    <Grid.Column mobile={16} tablet={16} computer={16}>
                        <Segment>
                            <Header as="h5">Prehľad platieb</Header>
                            <PaymentTable
                                payments={data}
                                isLoading={tableIsLoading}
                            />
                        </Segment>
                    </Grid.Column>
                </Grid>
            )}
        </Fragment>
    );
}

export default PaymentSearch;
