// react
import React from "react";
// components
import { Button, Header, Popup, Table } from "semantic-ui-react";
// others
import moment from "moment";

// component
function PlanningCalendarDay({
    dayShort,
    dayDate,
    hasDayEvents,
    events,
    startDeleteDay,
}) {
    // template
    return (
        <Table.HeaderCell>
            <Header as="h4">
                {`${dayShort} | ${dayDate.format("DD.MM")}`}
                <Popup
                    content="Zrušiť udalosti v rámci dňa"
                    trigger={
                        <Button
                            icon="cancel"
                            size="mini"
                            disabled={hasDayEvents(events, dayDate)}
                            style={
                                moment(dayDate).isBefore(moment())
                                    ? {
                                          display: "none",
                                      }
                                    : {
                                          marginLeft: "0.5rem",
                                      }
                            }
                            onClick={() => startDeleteDay(dayDate)}
                        />
                    }
                />
            </Header>
        </Table.HeaderCell>
    );
}

export default PlanningCalendarDay;
