// react
import React, { Fragment, useEffect } from "react";
// components
import HeaderTitle from "../../../../components/others/HeaderTitle";
import CategoryForm from "./CategoryForm";
// others
import { GAPageView, initGA } from "../../../../index";

// component
function CategoryAdd() {
    // Google analytics
    useEffect(() => {
        initGA();
        GAPageView();
    }, []);

    // template
    return (
        <Fragment>
            <HeaderTitle title="Pridať novú kategóriu" />
            <section>
                <CategoryForm data={[]} isEdit={false} />
            </section>
        </Fragment>
    );
}

export default CategoryAdd;
