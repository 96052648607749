// react
import React, { useEffect, useState, Fragment } from "react";
import { Link } from "react-router-dom";
// store
import { routes } from "../../store/routes";
import { paymentService } from "../../store/services/ServicePayment";
// components
import { Loader, Segment, Dimmer, Header, Button } from "semantic-ui-react";

// component
const PaymentFinish = () => {
    // state
    const [currentURL] = useState(window.location.href);
    const [processing, setProcessing] = useState(false);
    const [isPaid, setIsPaid] = useState(false);

    useEffect(() => {
        async function checkPayment() {
            setProcessing(true);
            const uri_params = currentURL.split("?")[1];

            const result = await paymentService.validatePayment(uri_params);

            if (result.status === 200) {
                // success
                setIsPaid(true);
            } else {
                // failure
                setIsPaid(false);
            }

            setProcessing(false);
        }

        checkPayment();
    }, [currentURL]);

    // template
    return (
        <div className="content-container" style={{ width: "100%" }}>
            {processing ? (
                <Segment
                    style={{ height: "100vh" }}
                    placeholder
                    className="center-segment"
                    textAlign="center"
                >
                    <Dimmer active inverted>
                        <Loader size="massive" inverted>
                            Prebieha overenie platby, počkajte prosím.
                        </Loader>
                    </Dimmer>
                </Segment>
            ) : (
                <Segment
                    style={{ height: "100vh" }}
                    placeholder
                    className="center-segment"
                    textAlign="center"
                >
                    {isPaid ? (
                        <Fragment>
                            <Header as="h2">
                                Platba bola úspešne zaplatená.
                            </Header>
                            <Button
                                color="pink"
                                size="big"
                                as={Link}
                                to={routes.MY_ORDERS}
                            >
                                Prejsť na moje objednávky
                            </Button>
                        </Fragment>
                    ) : (
                        <Fragment>
                            <Header as="h2">
                                Platbu sa nepodarilo overiť!
                            </Header>
                            <Button
                                color="pink"
                                size="big"
                                as={Link}
                                to={routes.MY_ORDERS}
                            >
                                Prejsť na moje objednávky
                            </Button>
                        </Fragment>
                    )}
                </Segment>
            )}
        </div>
    );
};

export default PaymentFinish;
