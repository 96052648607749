/* Tutorial Component functions */

// set design of tutorial steps
export const setStepsDesign = (next, tutorialStep) => {
    // get steps titles
    let tutorialTitles = document.getElementsByClassName("tutorial-step");
    // remove classes
    for (let i = 0; i < tutorialTitles.length; i++) {
        tutorialTitles[i].classList.remove("tutorial-active");
        tutorialTitles[i].classList.remove("tutorial-passed");
    }

    // set design for next step
    if (next) {
        // add passed steps
        for (let i = 0; i < tutorialStep + 1; i++) {
            tutorialTitles[i].classList.add("tutorial-passed");
        }
        // set actual step
        tutorialTitles[tutorialStep + 1].classList.add("tutorial-active");
    }
    // set design for previous step
    else {
        // add passed steps
        for (let i = 0; i < tutorialStep - 1; i++) {
            tutorialTitles[i].classList.add("tutorial-passed");
        }
        // set actual step
        tutorialTitles[tutorialStep - 1].classList.add("tutorial-active");
    }
};
