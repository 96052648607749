// react
import React from "react";
import { Switch, Route } from "react-router-dom";
// store
import { authService } from "../../../../store/services/ServiceAuth";
import { routes } from "../../../../store/routes";
// components
import ActivityList from "./ActivityList";
import ActivityAdd from "./ActivityAdd";
import ActivityEdit from "./ActivityEdit";

// component
function Activities() {
    authService.useIsAdmin();

    // template
    return (
        <Switch>
            <Route exact path={routes.ACTIVITIES} component={ActivityList} />
            <Route exact path={routes.ACTIVITIES_ADD} component={ActivityAdd} />
            <Route
                exact
                path={routes.ACTIVITIES_EDIT + ":id"}
                component={ActivityEdit}
            />
        </Switch>
    );
}

export default Activities;
