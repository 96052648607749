import { APIRequest } from "./helpers/requests";
import { API } from "../config";

export const statsService = {
    getUsersActivity,
};

async function getUsersActivity(params) {
    return await APIRequest({
        url: API.GET_STATS_USERS_BY_ACTIVITY + params,
        method: "GET",
        private: true,
    });
}
