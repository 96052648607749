// react
import React from "react";
// components
import ProviderDetailInfo from "./ProviderDetailInfo";
import ProviderDetailActivities from "./ProviderDetailActivities";
import { Header, Modal, Grid, Icon, Button, Divider } from "semantic-ui-react";

// component
function ProviderDetail({ modalOpen, handleModalClose, provider }) {
    // template
    return (
        <Modal
            centered={false}
            style={{ borderRadius: 0 }}
            open={modalOpen}
            size="small"
        >
            <Modal.Content>
                <Modal.Description>
                    <Grid>
                        <ProviderDetailInfo provider={provider} />
                        <Divider horizontal>
                            <Header as="h4">
                                <Icon name="soccer" />
                                Ponúkané aktivity poskytovateľom
                            </Header>
                        </Divider>
                        <ProviderDetailActivities provider={provider} />
                    </Grid>
                </Modal.Description>
            </Modal.Content>
            <Modal.Actions>
                <Button color="black" onClick={handleModalClose}>
                    Zatvoriť okno
                </Button>
            </Modal.Actions>
        </Modal>
    );
}

export default ProviderDetail;
