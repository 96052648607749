// react
import React, { useEffect, useState } from "react";
// components
import HeaderTitle from "../../components/others/HeaderTitle";
import ChooseYourPasswordForm from "./ChooseYourPasswordForm";
import { GAPageView, initGA } from "../../index";
import Navbar from "../../components/general/Navbar";

// component
function ConfirmResetPassword(props) {
    // state
    const [data] = useState({
        uid: props.match.params.uid,
        token: props.match.params.token,
    });

    // Google analytics
    useEffect(() => {
        initGA();
        GAPageView();
    }, []);

    // template
    return (
        <div className="cms">
            <Navbar linkBack="/" />
            <div className="content-container" style={{ width: "100%" }}>
                <HeaderTitle
                    title="Obnovenie hesla"
                    style={{ textAlign: "center" }}
                />
                <section>
                    <ChooseYourPasswordForm data={data} />
                </section>
            </div>
        </div>
    );
}

export default ConfirmResetPassword;
