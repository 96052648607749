// react
import React, { useEffect, useState } from "react";
// store
import { providerOptions } from "../../../../store/services/helpers/settings";
import { LOADING_TIME } from "../../../../store/config";
import { registrationService } from "../../../../store/services/ServiceRegistration";
// components
import RequestTable from "./RequestTable";
import ButtonGroupSearch from "../../../../components/buttons/ButtonGroupSearch";
import AccordionColumn from "../../../../components/others/AccordionColumn";
import { Form, Grid, Segment, Header } from "semantic-ui-react";

// component
function RequestSearch() {
    // state
    const [data, setData] = useState([]);
    const [providerType, setProviderType] = useState("");
    const [tableIsLoading, setTableIsLoading] = useState(false);

    // component functions
    const handleOnChangeProviderType = (e, data) => {
        setProviderType(data.value);
    };

    const resetForm = async () => {
        setTableIsLoading(true);
        setProviderType("");

        const result = await registrationService.getRegistrationRequests(
            ""
        );

        if (result.status === 200) {
            // setup data array for table
            let tableData = [];
            for (let i = 0; i < result.response.length; i++) {
                tableData.push({
                    id: result.response[i].id,
                    providerType: result.response[i].providerType,
                    firstName: result.response[i].firstname,
                    lastName: result.response[i].lastname,
                    companyName: result.response[i].company_name,
                    created: result.response[i].created,
                    phone: result.response[i].phone,
                    email: result.response[i].email,
                    nickName: result.response[i].nickname,
                    message: result.response[i].message,
                    country: result.response[i].country,
                    id_number: result.response[i].id_number,
                });
            }
            setData(tableData);
        }

        setTimeout(function () {
            setTableIsLoading(false);
        }, LOADING_TIME);
    };

    // fetch data
    useEffect(() => {
        const fetchData = async () => {
            setTableIsLoading(true);
            const result = await registrationService.getRegistrationRequests(
                ""
            );

            if (result.status === 200) {
                // setup data array for table
                let tableData = [];
                for (let i = 0; i < result.response.length; i++) {
                    tableData.push({
                        id: result.response[i].id,
                        providerType: result.response[i].providerType,
                        firstName: result.response[i].firstname,
                        lastName: result.response[i].lastname,
                        companyName: result.response[i].company_name,
                        created: result.response[i].created,
                        phone: result.response[i].phone,
                        email: result.response[i].email,
                        nickName: result.response[i].nickname,
                        message: result.response[i].message,
                        country: result.response[i].country,
                        id_number: result.response[i].id_number,
                    });
                }
                setData(tableData);
            }

            setTimeout(function () {
                setTableIsLoading(false);
            }, LOADING_TIME);
        };

        fetchData();
    }, []);

    // submit search
    const handleSubmit = async (e) => {
        e.preventDefault();
        setTableIsLoading(true);

        let params = "";
        if (providerType !== "") {
            params = "?providerType=" + providerType;
        }

        const result = await registrationService.getRegistrationRequests(
            params
        );

        if (result.status === 200) {
            // setup data array for table
            let tableData = [];
            for (let i = 0; i < result.response.length; i++) {
                tableData.push({
                    id: result.response[i].id,
                    providerType: result.response[i].providerType,
                    firstName: result.response[i].firstname,
                    lastName: result.response[i].lastname,
                    companyName: result.response[i].company_name,
                    created: result.response[i].created,
                    phone: result.response[i].phone,
                    email: result.response[i].email,
                    nickName: result.response[i].nickname,
                    message: result.response[i].message,
                    country: result.response[i].country,
                    id_number: result.response[i].id_number,
                });
            }
            setData(tableData);
        }

        setTimeout(function () {
            setTableIsLoading(false);
        }, LOADING_TIME);
    };

    // search form inputs
    const panels = [
        {
            key: "details",
            title: "Kritéria vyhľadávania",
            content: {
                content: (
                    <div>
                        <Form id="form-search-requests" onSubmit={handleSubmit}>
                            <Form.Group>
                                <Form.Select
                                    width={8}
                                    size="big"
                                    autoFocus
                                    fluid
                                    label="Typ poskytovateľa"
                                    placeholder="Typ poskytovateľa"
                                    options={providerOptions}
                                    value={providerType}
                                    onChange={handleOnChangeProviderType}
                                />
                            </Form.Group>
                        </Form>

                        <ButtonGroupSearch
                            form="form-search-requests"
                            resetDisabled={providerType === ""}
                            resetOnClick={resetForm}
                        />
                    </div>
                ),
            },
        },
    ];

    // template
    return (
        <Grid>
            <AccordionColumn panels={panels} />
            <Grid.Column mobile={16} tablet={16} computer={16}>
                <Segment>
                    <Header as="h5">Prehľad žiadostí</Header>
                    <RequestTable
                        data={data}
                        setData={setData}
                        isLoading={tableIsLoading}
                    />
                </Segment>
            </Grid.Column>
        </Grid>
    );
}

export default RequestSearch;
