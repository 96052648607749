import React, { useState } from 'react';
import { Fragment } from 'react';
// components
import { Card, Divider, Icon, Modal, Segment, Dimmer, Loader, Header, Label } from 'semantic-ui-react';
import ButtonCardLink from '../../../../components/buttons/ButtonCardLink';
import BankForm from './BankForm';

const ProviderBank = ({ bank, setBank, isLoadingData }) => {
    const [open, setOpen] = useState(false);

    return (
        <div>
            <Divider horizontal>Bankový účet</Divider>
            <Modal
                dimmer="inverted"
                centered={false}
                size="small"
                onClose={() => setOpen(false)}
                onOpen={() => setOpen(true)}
                open={open}
                trigger={
                    <div>
                        {!bank.id 
                            ?   <div onClick={() => setOpen(true)}>
                                    <Segment style={{ boxShadow: "none", border: "none" }}>
                                        <Dimmer active={isLoadingData} inverted>
                                            <Loader inverted></Loader>
                                        </Dimmer>   
                                        <ButtonCardLink
                                            to={{}}
                                            text="Pridať účet"
                                        />
                                    </Segment>
                                </div>
                            : 
                            <Fragment>
                            <Card fluid onClick={() => setOpen(true)}>
                                <Card.Content>
                                    <Header as='h3'>
                                        <Icon style={{
                                            paddingRight: "0.5rem"
                                        }} name='university' size="large"/>
                                        <Header.Content>
                                            {bank.name} {/* Jankov účet */}
                                            <Header.Subheader>
                                                {bank.iban} {/* SK00 1111 1111 1111 0000 0000 */}
                                                <br/>
                                                <Label style={{
                                                    marginLeft: 0,
                                                    marginTop: "0.5rem"
                                                }} size="small" color="pink">
                                                    {bank.bank_detail} {/* Tatra Banka */}
                                                </Label>
                                                <Label style={{
                                                    marginTop: "0.5rem"
                                                }} size="small" color="pink">
                                                    {bank.swift_code} {/* SK1236548 */}
                                                </Label>
                                            </Header.Subheader>
                                        </Header.Content>
                                    </Header>       
                                    
                                </Card.Content>
                            </Card>
                            <small style={{ 
                                color: "var(--variant1)", 
                                display: "block",   
                                textAlign: "center"
                            }}>
                                Kliknutím upravíš.
                            </small>
                            </Fragment>
                        }
                    </div>
                }
                >
                <Segment>
                    <Modal.Content style={{ padding: "2rem" }}>
                        <BankForm id={bank.id || null} setBank={setBank} onClose={() => setOpen(false)}/>
                    </Modal.Content>
                </Segment>
            </Modal>
                

        </div>
    );
};

export default ProviderBank;