// react
import React from "react";
import { Switch, Route } from "react-router-dom";
// store
import { routes } from "../../../../store/routes";
import { authService } from "../../../../store/services/ServiceAuth";
// components
import PaymentsList from "./PaymentsList";

// component
function Payments() {
    authService.useIsProvider();

    // template
    return (
        <Switch>
            <Route exact path={routes.PAYMENTS} component={PaymentsList} />
        </Switch>
    );
}

export default Payments;
