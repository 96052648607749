// react
import React from "react";
// components
import { Accordion, Grid } from "semantic-ui-react";

// component
function AccordionColumn({ panels }) {
    // template
    return (
        <Accordion
            as={Grid.Column}
            mobile={16}
            tablet={16}
            computer={16}
            styled
            style={{
                padding: "0.2rem",
                marginRight: "1rem",
                marginLeft: "1rem",
                marginTop: "1rem",
            }}
            panels={panels}
        />
    );
}

export default AccordionColumn;
