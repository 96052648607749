// react
import React from "react";
// components
import { Icon } from "semantic-ui-react";

// component
function ActivityFormLabel({ icon, text, required }) {
    // template
    return (
        <div style={{ marginBottom: "0.5rem" }}>
            <label>
                <Icon name={icon} />
                <strong>
                    {text}{" "}
                    {required ? <sup style={{ color: "red" }}>*</sup> : <></>}
                </strong>
            </label>
        </div>
    );
}

export default ActivityFormLabel;
