// react
import React from "react";
import { Link } from "react-router-dom";
// store
import { routes } from "../../../../store/routes";
// components
import { Card, Header, Icon } from "semantic-ui-react";

// component
function AddressItem({ data }) {
    // template
    return (
        <Card
            as={Link}
            to={routes.ADDRESSES_EDIT + data.id}
            style={{ marginTop: "1rem" }}
            fluid
            color="pink"
        >
            <Card.Content style={{ textAlign: "center" }}>
                <Icon
                    size="big"
                    color="pink"
                    circular
                    inverted
                    name="map marker alternate"
                />
                <Header as="h4" style={{ marginTop: "0.5rem" }}>
                    {data.name}
                </Header>
            </Card.Content>
        </Card>
    );
}

export default AddressItem;
