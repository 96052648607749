// react
import React from "react";
import { Link } from "react-router-dom";
// components
import { Button, Icon } from "semantic-ui-react";

// component
function ButtonBack({ link }) {
    // template
    return (
        <Button
            as={Link}
            to={link}
            type="button"
            color="pink"
            basic
            style={{
                marginBottom: "1rem",
                marginTop: "1rem",
            }}
        >
            <Icon name="left arrow" /> Naspäť
        </Button>
    );
}

export default ButtonBack;
