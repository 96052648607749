// react
import React from "react";
// components
import { Button, Header, Label } from "semantic-ui-react";
// others
import moment from "moment";

// component
function PlanningCalendarDayMobile({
    dayShort,
    dayDate,
    hasDayEvents,
    events,
    startDeleteDay,
}) {
    // template
    return (
        <Header>
            <Label
                size="large"
                color="pink"
                basic={moment().isSame(dayDate, "d")}
                icon="calendar"
                ribbon
                content={`${dayDate.format("DD.MM")} | ${dayShort}`}
                detail={
                    <Label.Detail>
                        <Button
                            icon="cancel"
                            size="mini"
                            disabled={hasDayEvents(events, dayDate)}
                            style={
                                moment(dayDate).isBefore(moment())
                                    ? { display: "none" }
                                    : { padding: "0.2rem" }
                            }
                            onClick={() => startDeleteDay(dayDate)}
                        />
                    </Label.Detail>
                }
            />
        </Header>
    );
}

export default PlanningCalendarDayMobile;
