import React, { Fragment, useEffect, useState } from "react";
import { useSelector } from "react-redux";
// store
import { PAGINATION_LIMIT } from "../../../../store/config";
import moment from "moment";
import { isBeforeCurrentTime } from "../../../../store/services/helpers/dates";
// components
import DimmerLoader from "../../../../components/others/DimmerLoader";
import SuperModal from "../../../../components/modals/SuperModal";
import VerificationForm from "../../provider/activities/VerificationForm";
import { Table, Pagination, Button, Icon/*, Form, Divider*/, Header } from "semantic-ui-react";

// component
function OrderedEventsTable({ orders, setOrders, isLoading }) {
    const user = useSelector((state) => state.user);
    // eslint-disable-next-line
    const [ready, setReady] = useState(false)
    // eslint-disable-next-line
    const [tableData, setTableData] = useState(
        orders.slice(0, PAGINATION_LIMIT)
    );

    // set data to table
    useEffect(() => {
        setTableData(orders.slice(0, PAGINATION_LIMIT));
        setReady(true)
    }, [orders]);

    const handleChangePage = (event, data) => {
        setReady(false)
        setTableData(
            orders.slice(
                data.activePage * PAGINATION_LIMIT - PAGINATION_LIMIT,
                data.activePage * PAGINATION_LIMIT
            )
        );
        setReady(true)
    };

    const manageVerification = (status, orderId) => {
        if( status === 200 ){
            setOrders(prevState => prevState.filter(order => {
                if( order.id === orderId ){
                    order.is_used = true
                }

                return order
            }))
        }
    }

    // template
    return (
        <Fragment>
            <DimmerLoader active={isLoading} />
            <Table striped unstackable style={{ overflow: "auto" }}>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>Názov aktivity</Table.HeaderCell>
                        {/* <Table.HeaderCell>Služba</Table.HeaderCell> */}
                        <Table.HeaderCell>Dátum termínu</Table.HeaderCell>
                        <Table.HeaderCell>Objednal</Table.HeaderCell>
                        <Table.HeaderCell>Objednané</Table.HeaderCell>
                        <Table.HeaderCell>{ user.is_town ? "Príspevok" : "Suma" }</Table.HeaderCell>
                        <Table.HeaderCell>Stav</Table.HeaderCell>
                        <Table.HeaderCell>Akcie</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>

                <Table.Body>
                    { tableData.length === 0 && 
                        <Table.Row> 
                            <Table.Cell colSpan={6} style={{ textAlign: "center", fontWeight: "bold" }}>
                                Neboli najdené žiadne záznamy
                            </Table.Cell>
                        </Table.Row>
                    }
                    {tableData.map((order) => (
                        <>
                        { order.show && 
                        <Table.Row>
                            <Table.Cell>
                                <Header as="h3" style={{ marginBottom: "0.2rem" }}>{ order.event.name }</Header>
                                <Icon name="map marker alternate"/> { order.event?.address }
                            </Table.Cell>
                            {/* <Table.Cell style={{ color: '#2185d0' }}>#APsolidarity</Table.Cell> */}
                            <Table.Cell>
                                { moment(order.event?.starts, "YYYY-MM-DD HH:mm").format("DD.MM.YYYY HH:mm") }
                                <br/>
                                { isBeforeCurrentTime(order.event?.starts) 
                                    ? <small style={{ color: "green" }}>Nadchádzajúci</small> 
                                    : <small style={{ color: "red" }}>Po termíne</small> 
                                }
                            </Table.Cell>
                            <Table.Cell>{ order.owner?.firstname } { order.owner?.lastname }</Table.Cell>
                            <Table.Cell>{ (moment(order.created)).format("DD.MM.YYYY") }</Table.Cell>
                            <Table.Cell>{ order.price } €</Table.Cell>
                            <Table.Cell>
                                { order.is_refunded && "Zrušené/vratené" }
                                { (order.is_used && !order.is_refunded) && "Využité" }
                                { (!order.is_used && !order.is_refunded) && "Nevyužité" }

                            </Table.Cell>
                            <Table.Cell>
                                { isBeforeCurrentTime(order.event?.starts) && 
                                    <Button.Group size="small" style={{ marginTop: "0.5rem" }}>
                                        { (!order.is_refunded && !order.is_used) && 
                                            <SuperModal
                                                trigger={
                                                    <Button className="verify-button"> Overiť </Button>
                                                }
                                                content={
                                                    <VerificationForm 
                                                        onVerification={(status) => manageVerification(status, order.id)}
                                                    />
                                                }
                                            />
                                        }
                                        {/* { !order.is_refunded &&
                                            <SuperModal
                                                trigger={
                                                    <Button disabled className="cancel-button" style={{ background: "var(--dark)", color: "var(--white)"}} title="Zrušiť termín"> 
                                                        <Icon name="calendar minus" style={{ marginRight: 0 }}/>
                                                    </Button>
                                                }
                                                content={
                                                    <Form>
                                                        <Form.TextArea autoFocus label="Zadajte dôvod pre zrušenie termínu"/>
                                                        <Divider/>
                                                        <Form.Field>
                                                            <Button style={{ background: "var(--primary)", color: "var(--white)" }}>Potvrdiť</Button>
                                                        </Form.Field>
                                                    </Form>
                                                }
                                            />
                                        } */}
                                    </Button.Group> 
                                }
                                
                            </Table.Cell>
                        </Table.Row>
                        }
                        </>
                    ))}
                </Table.Body>

                <Table.Footer>
                    <Table.Row>
                        <Table.HeaderCell colSpan="8">
                            <Pagination
                                defaultActivePage={1}
                                totalPages={Math.ceil(
                                    orders.length / PAGINATION_LIMIT
                                )}
                                onPageChange={handleChangePage}
                                boundaryRange={0}
                                siblingRange={0}
                            />
                        </Table.HeaderCell>
                    </Table.Row>
                </Table.Footer>
            </Table>
        </Fragment>
    );
}

export default OrderedEventsTable;
