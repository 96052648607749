import React, { useState } from 'react'
// store
import { isEmailError, isICOError } from '../../../../store/services/helpers/validation'
import { userService } from '../../../../store/services/ServiceUsers';
// components
import { Grid, Form, Button, Message, Icon, Divider } from "semantic-ui-react";

const options = [
    { key: '1', text: 'Živnostník', value: 1 },
    { key: '2', text: 'Právnická osoba', value: 2 },
]

const UsersForm = (props) => {
    const [form, setForm] = useState({
        firstname: '',
        lastname: '',
        nickname: '',
        email: '',
        id_number: '',
        provider_type: '',
        is_coach: false,
        is_town: false,
    })
    
    const [isProcessing, setIsProcessing] = useState(false)
    const [errorMessage, setErrorMessage] = useState("")

    const handleChange = (e, { name, value }) => {
        setErrorMessage("");
        setForm((prevState) => ({ ...prevState, [name]: value }))
    }
    const handleCouchTown = (e, { name }) => {
        if (name === "is_coach") {
            setForm((prevState) => ({ ...prevState, is_coach: true, is_town: false }))
        } else if (name === "is_town") {
            setForm((prevState) => ({ ...prevState, is_coach: false, is_town: true }))
        }

    }
    const cancelCouchTown = () => {
        setForm((prevState) => ({ ...prevState, is_coach: false, is_town: false }))
    }
    const handleSubmit = async (e) => {
        e.preventDefault();
        setErrorMessage("");
        if (form.nickname === "" && form.firstname === "" && form.lastname === "") {
            setErrorMessage("Zadajte meno a priezvisko alebo prezývku.")
            return
        }
        if ((form.nickname === "" && form.firstname === "") ||
        (form.nickname === "" && form.lastname === "")) {
            setErrorMessage("Ak zadávate meno, musíte zadať aj priezvisko a naopak.")
            return
        }
        
        if (form.provider_type === "") {
            setErrorMessage("Vyberte typ poskytovateľa.")
            return
        }
        if (form.id_number === "") {
            setErrorMessage("Zadajte IČO poskytovateľa.")
            return
        }
        if (isICOError(form.id_number)) {
            setErrorMessage("Hodnota IČO poskytovateľa nie je správna!")
            return
        }
        if (isEmailError(form.email)) {
            setErrorMessage("Neplatný email.")
            return
        }
        
        setIsProcessing(true)
        const result = await userService.createProvider(form);
        
        if( result.status === 200 ){
            props.setData(prev => ([...prev, result.response]));
            let total = parseInt(props.totalProviders) + 1
            props.setTotalProviders("("+total+")");
            props.setOpen(false);
        } else {
            if(result.response.id_number){
                setErrorMessage("Zadaná hodnota IČO už je registrovaná v systéme.")
            } else {
                setErrorMessage("Zadaná emailová adresa už je registrovaná v systéme.")
            }
        }
        setIsProcessing(false)
    }

    return (
        <Grid>
            <Grid.Row>
                <Grid.Column width={16}>
                    { errorMessage && 
                        <Message warning>
                            <Message.Header>
                                <Icon name="warning circle"/> 
                                {errorMessage}
                            </Message.Header>
                        </Message>
                    }
                </Grid.Column>
            </Grid.Row>
            <Grid.Row>
                <Grid.Column width={5}>
                    <Form.Input
                        fluid
                        label='Meno'
                        name="firstname"
                        value={form.firstname}
                        onChange={handleChange}
                        placeholder='Meno' />
                </Grid.Column>
                <Grid.Column width={5}>
                    <Form.Input
                        fluid
                        name="lastname"
                        value={form.lastname}
                        onChange={handleChange}
                        label='Priezvisko'
                        placeholder='Priezvisko'/>
                    </Grid.Column>
                <Grid.Column width={5}>
                    <Form.Input
                        fluid
                        name="nickname"
                        value={form.nickname}
                        onChange={handleChange}
                        label='Prezývka'
                        placeholder='Prezývka' />
                </Grid.Column>
            </Grid.Row>
            <Grid.Row>
                <Grid.Column width={5}>
                    <Form.Select
                        fluid
                        required
                        label='Typ *'
                        options={options}
                        placeholder='---'
                        name="provider_type"
                        onChange={handleChange}/>
                </Grid.Column>
                <Grid.Column width={5}>
                    <Form.Input
                        fluid
                        required
                        label='Ičo *'
                        onChange={handleChange}
                        name="id_number"
                        value={form.id_number}
                        placeholder='vložte ičo'
                        error={isICOError(form.id_number)}/>
                </Grid.Column>
                <Grid.Column width={5}>
                    <Form.Input
                        fluid
                        required
                        label='Email *'
                        onChange={handleChange}
                        name="email"
                        value={form.email}
                        placeholder='example@example.com'
                        error={isEmailError(form.email)}/>
                </Grid.Column>
            </Grid.Row>
            <Grid.Row>
                <Grid.Column width={8}>
                    <Grid centered={true}>
                        <Grid.Row style={{ marginTop: "2rem" }}>
                            <Grid.Column width={8}>
                                <Form.Radio
                                    label='Označiť ako Kouč'
                                    name='is_coach'
                                    checked={form.is_coach === true}
                                    onChange={handleCouchTown}
                                />
                            </Grid.Column>
                            <Grid.Column width={8}>
                                <Form.Radio
                                    label='Označiť ako Mesto'
                                    name='is_town'
                                    checked={form.is_town === true}
                                    onChange={handleCouchTown}
                                />
                            </Grid.Column>
                        </Grid.Row>
                        {(form.is_town || form.is_coach) && <Button onClick={cancelCouchTown} color="red" size="mini" compact content="Zrušiť výber" />}
                    </Grid>
                </Grid.Column>
            </Grid.Row>

            <Divider/>
            <Grid.Row>
                <Grid.Column textAlign="right">
                    <Button type="button" color='grey' onClick={() => props.setOpen(false)}>
                        Zrušiť
                    </Button>
                    <Button
                        content="Uložiť nového poskytovateľa"
                        labelPosition='right'
                        loading={isProcessing}
                        disabled={isProcessing}
                        onClick={(e) => handleSubmit(e)}
                        icon='checkmark'
                        color="pink"
                    />
                </Grid.Column>
            </Grid.Row>
        </Grid>
    )
}

export default UsersForm