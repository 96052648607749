// react
import React from "react";
// components
import { Header, Icon, Grid } from "semantic-ui-react";

// component
function ProviderTab({ company }) {
    // template
    return (
        <>
            <Grid columns="equal">
                <Grid.Column width={16}>
                    <Header as="h3">Poskytovateľ</Header>
                    <Header as="h5">
                        <Icon name="user" />
                        {company.nickname}
                    </Header>
                    <Header as="h5">
                        <Icon name="phone" />
                        {company.phone_number}
                    </Header>
                    <Header as="h5">
                        <Icon name="mail" />
                        {company.email}
                    </Header>
                </Grid.Column>
            </Grid>
        </>
    );
}

export default ProviderTab;
