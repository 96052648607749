// react
import React from "react";
import { Link } from "react-router-dom";
// styles
import { Button, Header, Icon, Modal } from "semantic-ui-react";

// component
function TutorialSuccessMessage({ successOpen, handleSuccessClose }) {
    // template
    return (
        <Modal
            open={successOpen}
            onClose={handleSuccessClose}
            closeOnDimmerClick={false}
            size="tiny"
        >
            <Header icon="checkmark" content="Tutoriál ukončený" />
            <Modal.Content>
                <p>
                    Úspešne si zvládol všetky základné kroky poskytovateľa
                    aktivít. V tvojom profile sme ti sprístupnili ďalšie
                    možnosti ako vylepšiť prezentáciu tvojich aktivít.
                </p>
            </Modal.Content>
            <Modal.Actions>
                <Link to="/dashboard/myactivities">
                    <Button color="pink" onClick={handleSuccessClose} inverted>
                        <Icon name="checkmark" /> Pokračovať
                    </Button>
                </Link>
            </Modal.Actions>
        </Modal>
    );
}

export default TutorialSuccessMessage;
