// react
import React from "react";
// components
import PlanningEditEventForm from "./PlanningEditEventForm";
import { Modal } from "semantic-ui-react";

// component
function PlanningEditEvent({
    modalEditEventOpen,
    handleModalEditEventClose,
    editEventInfo,
    changeModalMessageText,
    changeModalMessageUpdate,
}) {
    const newMessage = (message) => {
        changeModalMessageText(message);
        changeModalMessageUpdate(true);
        handleModalEditEventClose();
    };

    //template
    return (
        <Modal
            closeIcon
            open={modalEditEventOpen}
            onClose={handleModalEditEventClose}
            closeOnDimmerClick={false}
        >
            <Modal.Header>Upraviť plánovanú aktivitu</Modal.Header>
            <PlanningEditEventForm
                editEventInfo={editEventInfo}
                newMessage={newMessage}
            />
        </Modal>
    );
}

export default PlanningEditEvent;
