import React, { useState } from 'react';
import { DistanceMatrixService } from "@react-google-maps/api";
import { Icon, Header } from 'semantic-ui-react'

const InfoWindowView = ({ event, userPosition, mapLoaded }) => {
    // states related to positions
    const [usersPosition] = useState(userPosition);
    const [eventPosition] = useState({
        lat: parseFloat(event.address.latitude),
        lng: parseFloat(event.address.longitude),
    });

    // states related to calculations
    const [travelDistance, setTravelDistance] = useState("");
    const [walkDuration, setWalkDuration] = useState("");
    const [carDuration, setCarDuration] = useState("");

    const distanceCalculationCar = (response) => {
        if (response !== null) {
            if (response.rows[0].elements[0].distance.text !== travelDistance)
                setTravelDistance(response.rows[0].elements[0].distance.text);

            if (response.rows[0].elements[0].duration.text !== carDuration)
                setCarDuration(response.rows[0].elements[0].duration.text);
        }
    };

    const distanceCalculationWalk = (response) => {
        if (response !== null)
            if (response.rows[0].elements[0].duration.text !== walkDuration)
                setWalkDuration(response.rows[0].elements[0].duration.text);
    };

    return (
        <div>
            <Header as="h3" textAlign="center">{ event?.activity?.name }</Header>
            <small
                style={{
                    display: "block",
                    width: "100%",
                    fontSize: "0.7rem",
                    textAlign: "center",
                }}
            >
                {usersPosition.lat ? `${travelDistance}` : "- m"} od
                vás <span />
                &nbsp;
                <span className="dot"> · </span>
                &nbsp;
                <Icon name="male" />
                {usersPosition.lat ? `${walkDuration}` : "- min"}
                &nbsp;
                <span className="dot"> · </span>
                &nbsp;
                <Icon name="car" />
                {usersPosition.lat ? `${carDuration}` : "- min"}
            </small>

            {usersPosition?.lat && mapLoaded && (
                <>
                    <DistanceMatrixService
                        options={{
                            destinations: [eventPosition],
                            origins: [usersPosition],
                            travelMode: "DRIVING",
                        }}
                        callback={distanceCalculationCar}
                    />
                    <DistanceMatrixService
                        options={{
                            destinations: [eventPosition],
                            origins: [usersPosition],
                            travelMode: "WALKING",
                        }}
                        callback={distanceCalculationWalk}
                    />
                </>
            )}
        </div>
    );
};

export default InfoWindowView;