import React, { useState } from 'react';
import { Cookies, getCookieConsentValue } from "react-cookie-consent";
import { COOKIE_EXPIRATION } from "../store/config";
// components
import { Divider, Header, Checkbox, Button, Form } from 'semantic-ui-react';

const CookieSettings = ({ onClose }) => {
    const APAnalytics = getCookieConsentValue("APAnalytics")

    const [allowAnalytics, setAllowAnalytics] = useState(String(APAnalytics) === "true" ? true : false)

    return (
        <div style={{ fontFamily: "Metropolis Regular" }}>
            <Header as="h3" content={"Nastavenie cookies"}/>

            <p>
                Súbory cookie používame na analýzu údajov o našich návštevníkoch a na zlepšenie našich webových stránok. Používaním tohto webu s týmto používaním súhlasíte.
            </p>

            <Divider/>

            <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", marginBottom: "2rem" }}>
                <p style={{ margin: 0, fontWeight: "bold" }}>
                    Nevyhnutné súbory cookies
                </p>
                <Checkbox toggle disabled checked={true}/>
            </div>
            <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", marginBottom: "2rem" }}>
                <p style={{ margin: 0, fontWeight: "bold" }}>
                    Analytické/výkonnostné súbory cookies
                </p>
                <Checkbox toggle
                    checked={allowAnalytics}
                    onChange={() => setAllowAnalytics(!allowAnalytics)}
                />
            </div>

            <Form.Field style={{ textAlign: "right" }}>
                <Button
                    type="button"
                    content={"Potvrdiť"}
                    style={{ background: "var(--primary)", color: "var(--white)" }}
                    onClick={() => {
                        if (allowAnalytics) {
                            Cookies.set('APcookie', 'true', { expires: COOKIE_EXPIRATION })
                            Cookies.set('APAnalytics', 'true', { expires: COOKIE_EXPIRATION })
                            document.location.reload()
                        } else {
                            Cookies.set('APcookie', 'true', { expires: COOKIE_EXPIRATION })
                            if (String(APAnalytics) === "true") {
                                Cookies.remove("APAnalytics")
                            }
                            document.location.reload()
                        }
                    }}
                />
            </Form.Field>
        </div>
    );
};

export default CookieSettings;