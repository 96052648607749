// react
import React from "react";
import { Link } from "react-router-dom";
// store
import { routes } from "../../store/routes";
// components
import { Button, Header, Container, Icon } from "semantic-ui-react";

// component
function ConfirmSuccessMessage() {
    // template
    return (
        <Container text>
            <Header
                as="h1"
                style={{
                    marginTop: "0.5em",
                }}
                content="Obnova hesla úspešne dokončená"
            />
            <Icon name="checkmark" size="huge" color="pink" circular />
            <Header
                as="h2"
                content="Gratulujeme, obnovenie hesla prebehlo úspešne."
            />

            <Link to={routes.LOGIN}>
                <Button color="pink" size="big">
                    Prihlásiť sa
                </Button>
            </Link>
        </Container>
    );
}

export default ConfirmSuccessMessage;
