const dashboard = "/dashboard/"; // main prefix for dashboard pages

export const routes = {
    ALL: "*",

    // Public
    HOME: "/",
    SEARCH: "/search",
    CONTACT: "/contact",
    ABOUT_US: "/about-us",
    GENERAL_CONDITIONS: "/general-conditions",
    PRIVACY: "/privacy",
    LOGIN: "/login",
    REGISTER: "/register",
    BASKET: "/payment/overview/",
    PAYMENT_FINISH: "/payment/finish/",
    ACTIVATION: "/activate/:uid/:token",
    PASSWORD_RESET: "/password-reset",
    ACTIVITY_DETAIL: "/activity/:id/detail", // :id
    REGISTER_CLIENT: "/register/client",
    REGISTER_PROVIDER: "/register/provider",
    PASSWORD_CONFIRM: "/password/reset/confirm/:uid/:token",
    ACTIVIY_EVENT_DETAIL: "/event/:event_id", // :id

    AP_SOLIDARITY: "/apsolidarity",
    AP_FUTURE: "/apfuture",
    AP_BUSINESS: "/apimage",
    AP_EVENT: "/apevent",
    AP_ACTION: "/apwalkin",
    AP_OTHERS: "/ine",

    // Tutorial
    TUTORIAL: "/tutorial",

    // Dashboard
    DASHBOARD: dashboard,
    STATS_PAYMENTS: dashboard + "stats/payments",
    STATS_COACHES: dashboard + "stats/coaches",
    STATS_TOWNS: dashboard + "stats/towns",
    STATS_PROVIDERS: dashboard + "stats/providers",
    STATS_ACTIVITIES: dashboard + "stats/activities",
    PROFILE: dashboard + "profile",
    MY_ORDERS: dashboard + "my-orders",
    MY_EVENTS: dashboard + "my-events",
    USER_MANAGEMENT: dashboard + "users",
    USER_MANAGEMENT_ADD: dashboard + "users/add",
    USER_ACTIVITY: dashboard + "users_activity",
    BILLING: dashboard + "billing",
    BUNDLES: dashboard + "bundles",
    BUNDLES_ADD: dashboard + "bundles/add",
    BUNDLES_EDIT: dashboard + "bundles/edit/", // :id
    PLANNING: dashboard + "planning",
    REQUESTS: dashboard + "requests",
    PAYMENTS: dashboard + "payments",
    ADDRESSES: dashboard + "addresses",
    ADDRESSES_ADD: dashboard + "addresses/add",
    ADDRESSES_EDIT: dashboard + "addresses/edit/", // :id
    CATEGORIES: dashboard + "categories",
    CATEGORIES_ADD: dashboard + "categories/add",
    CATEGORIES_EDIT: dashboard + "categories/edit/", // :id
    ACTIVITIES: dashboard + "activities",
    ACTIVITIES_ADD: dashboard + "activities/add",
    ACTIVITIES_EDIT: dashboard + "activities/edit/", // :id
    MY_ACTIVITIES: dashboard + "myactivities",
    MY_ACTIVITIES_ADD: dashboard + "myactivities/add",
    MY_ACTIVITIES_EDIT: dashboard + "myactivities/edit/", // :id
    COACH_NETWORK: dashboard + "coach_network",
    COACH_REGISTRATION: dashboard + "coach_network/register",
    FUTURE_EVENTS: dashboard + "future_events",
    INVOICES: dashboard + "invoices",
    ORDERED_EVENTS: dashboard + "orders",
    PROFITS: dashboard + "profits",
    REWARDS: dashboard + "rewards",
};
